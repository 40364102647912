
import React, { useEffect, useState } from 'react';
import Configuration from '../../../Configuration';
// import '../../../styles/Components/Rating/RatingPreview.scss';
import { RatingRecord } from '../../../types/Offer';
import ReactStars from "react-rating-stars-component";

const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

interface RatingPreviewProps {
    onClick?: () => void;
    rating: RatingRecord;
    half?: boolean;
    notOwn?: boolean;
}

const RatingCollector: React.FC<RatingPreviewProps> = ({ onClick, rating, half }) => {

    const [stars, setStars] = useState <number>(0); 

    useEffect(() => {
        setStars(0)
        setTimeout(() => {
            setStars(rating.stars)
        }, 200)
    }, [rating?.stars])

    if (stars === 0)
        return null;

    return (
        <div 
            className="rating-preview"
            onClick={() => {
                // onClick();
            }}
        >
            <div className='stars' data-stars={stars || rating.stars}>
                <ReactStars 
                    count={5}
                    size={18}
                    edit={false}
                    value={stars || rating.stars}
                    activeColor="#ffd700"
                    half={half || false}
                />
            </div>
        </div>
    );
    
};

export default RatingCollector;
