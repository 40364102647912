import CircularProgress from '@material-ui/core/CircularProgress';
import React, { createRef, useEffect, useRef, useState } from 'react';
import UserAPI from '../apis/User';
// import '../styles/OnboardingPreferences.scss';
import { User, UserDTO } from '../types/User';
import PushNotificationManagement from './PushNotificationManagement';
import VerifyEmail from './VerifyEmail';
import { DestroyStorage } from '../stores/Index';
import Configuration from '../Configuration';
import { IonAlert, IonIcon, IonInput, IonLoading, IonModal, IonTextarea, isPlatform } from '@ionic/react';
import { Auth } from '../services/AuthService';
import { getTotalEventsCount, hasUndeletedEvents } from '../stores/Event';
import { Task } from './Components/ProfileProgress';
import { Slide, Swiper } from 'react-dynamic-swiper';
// import 'react-dynamic-swiper/lib/styles.css';
import ProfileSkeletonImg from '../images/profile-skeleton.png';
import AvailabilityCalendarImg from '../images/availability-calendar.png';
import Avatar1 from '../images/avatars/1.jpg';
import Avatar2 from '../images/avatars/2.jpg';
import Avatar3 from '../images/avatars/3.jpg';
import Avatar4 from '../images/avatars/4.jpg';
import Avatar5 from '../images/avatars/5.jpg';
import Avatar6 from '../images/avatars/6.jpg';
import Avatar11 from '../images/avatars/11.jpg';
import Avatar12 from '../images/avatars/12.jpg';
import Avatar13 from '../images/avatars/13.jpg';
import Avatar14 from '../images/avatars/14.jpg';
import Avatar15 from '../images/avatars/15.jpg';
import Avatar16 from '../images/avatars/16.jpg';
import SMSLogo from '../icons/comment-sms-solid.svg';
import KeepProfileUpdatedImg from '../images/keep-profile-updated.png';
import QualificationSuggestionsImg from '../images/qualification-suggestions.png';
import { caretBack, caretDownCircle, caretForward, checkmark, checkmarkSharp, closeSharp, create, createOutline, createSharp, helpCircle, keypadOutline, locate, logoWhatsapp, mailOutline, send, sendOutline, trashSharp } from 'ionicons/icons';
import Utilities from '../Utilities';
import InternalTracker from '../InternalTracker';
import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import { Range, getTrackBackground } from 'react-range';
import DynamicMap from './DynamicMap';
import Contacts from './Contacts';
import Calendar from './Calendar';
import { Contact } from '../types/Contact';
import PhoneInput from 'react-phone-input-2';
import OtpInput from 'react-otp-input';
import SkillManagerEnhanced from './SkillManagerEnhanced';
import SectorsAPI from '../apis/Sectors';
import Confetti from 'react-confetti'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import LongArrow from '../icons/LongArrow';
import { Geolocation } from '@capacitor/geolocation';
import GoogleMapsAPI from '../apis/GoogleMaps';
const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

let logoutTapsTimeout: any = null

interface LoaderProps {

}
const SKIP_MESSAGES = {
    publicprofile: {
        subheader: "",
        bodyFirst: "Expand your network by showing your skills to other local organizations",
        bodySecond: "Grow your network by showcasing your skills to nearby organizations."
    },
    contacts: {
        subheader: "Adding contacts is required",
        bodyFirst: "You must add or select contacts to use Updatedge.",
        bodySecond: "To use Updatedge you need to add or select contacts."
    },
    agencies: {
        subheader: "",
        bodyFirst: "Add agencies to share your availability with them. Agencies can confirm they represent you, & verify your profile too.",
        bodySecond: "Adding agencies you share your availability with & who represent you can authenticate your profile"
    },
    image: {
        subheader: "Your image is required",
        bodyFirst: "Contacts must be able to recognize you",
        bodySecond: "Contacts must be able to easily identify you"
    },
    headline: {
        subheader: "Your headline is required",
        bodyFirst: "People need to quickly see why to connect with you",
        bodySecond: "People should immediately grasp why they should connect with you"
    },
    skills: {
        subheader: "Your skills are required",
        bodyFirst: "Without these contacts will not know why to offer you work",
        bodySecond: "Without this, contacts won't understand why they should offer you work"
    },
    addevents: {
        subheader: "Events are required",
        bodyFirst: "Your availability or unavailability is a core function of Updatedge",
        bodySecond: "Your availability or unavailability is a fundamental feature of Updatedge"
    },
    smsnotifications: {
        subheader: "Your mobile number is required",
        bodyFirst: "To help verify your account. To get offers when no Internet. It's not used by any 3rd party",
        bodySecond: "To enhance account verification. Receive offers offline. Nobody else has access to your number, unless you give specific permission"
    },
    postalcode: {
        subheader: "Your location is required",
        bodyFirst: "Add you location for local opportunities",
        bodySecond: "Needed for nearby opportunities"
    },
    pushnotifications: {
        subheader: "You should enable notifications",
        bodyFirst: "Without you will not be alerted to offers, confirmed work, or chat",
        bodySecond: "Without notifications, you won't receive alerts for offers, confirmed work, or messages"
    },
    qualifications: {
        subheader: "Your qualifications are required",
        bodyFirst: "Add your qualifications / certifications for better work, suitable to your skills. Teachers should add “PGCE” or equivalent",
        bodySecond: "Include your qualifications and certifications to align with your skills for more suitable job opportunities."
    },
    
}

const MIN = -50;
const MAX = 350

let globalSteps = null
let circleRadius = null

const QUALIFICATION_ABRV_DEGREE_MAPPING = {
    "HND": "Higher National Diploma (HND)",
    "BA": "Bachelor of Arts (BA)",
    "BSc": "Bachelor of Science (BSc)",
    "Bcom": "Bachelor of Commerce (Bcom)",
    "BMus": "Bachelor of Music (BMus)",
    "LLB": "Law (LLB)",
    "BEng": "Engineering (BEng)",
    "MA": "Master of Arts (MA)",
    "MSc": "Master of Science (MSc)",
    "PGCE": "Postgraduate Certificate (PGCE)",
    "PhD": "PhD",
    "Custom Qualification": "Custom Qualification",
}

const SUGGESTIONS = {
    DEGREES: [
        "Higher National Diploma (HND)",
        "Bachelor of Arts (BA)",
        "Bachelor of Science (BSc)",
        "Bachelor of Commerce (Bcom)",
        "Bachelor of Music (BMus)",
        "Law (LLB)",
        "Engineering (BEng)",
        "Master of Arts (MA)",
        "Master of Science (MSc)",
        "Postgraduate Certificate (PGCE)",
        "PhD",
        "History"
    ],
    GRADES: [
        "Pass",
        "Fail",
        "Distinction",
        "Merit",
        "1st",
        "2:1",
        "2:2",
        "3rd"
    ],
    SCHOOLS: [
        'Abertay University',
        'Aberystwyth University',
        'Academy of Contemporary Music',
        'Accrington and Rossendale College',
        'Amsterdam Fashion Academy',
        'Anglia Ruskin University',
        'Anglo European College of Chiropractic',
        'Arden University',
        'Arts University Bournemouth',
        'ARU London',
        'Askham Bryan College',
        'Aston University',
        'Bangor University',
        'Barking and Dagenham College',
        'Bath Spa University',
        'Berkshire College of Agriculture',
        'Bicton College',
        'BIMM',
        'Birkbeck, University of London',
        'Birmingham City University',
        'Bishop Burton College',
        'Bishop Grosseteste University',
        'Blackburn College',
        'Blackpool and the Fylde College',
        'Bournemouth University',
        'BPP University',
        'Bradford College',
        'Bridgwater and Taunton College',
        'Bristol, University West of England, (UWE)',
        'British College of Osteopathic Medicine',
        'Brunel University London',
        'Buckinghamshire New University',
        'Bury College',
        'Camberwell College of Arts, University of the Arts London',
        'Cambridge School of Visual and Performing Arts',
        'Canterbury Christ Church University',
        'Cardiff Metropolitan University',
        'Cardiff University',
        'Central Saint Martins, University of the Arts London',
        'Chelsea College of Arts, University of the Arts London',
        'Norwich, City College',
        'Bristol, City College',
        'Liverpool College, City of',
        'City, University of London',
        'Cleveland College of Art and Design',
        'Cliff College',
        'Coleg Llandrillo',
        'Menai, Coleg',
        'Sir Gar, Coleg',
        'College of Agriculture, Food and Rural Enterprise',
        'West Anglia, College of',
        'Cornwall College',
        'Courtauld Institute of Art, University of London',
        'Coventry University',
        'De Montfort University',
        'Duchy College',
        'Durham University',
        'East Riding College',
        'Easton and Otley College',
        'Edge Hill University',
        'Edinburgh Napier University',
        'ESCP Europe Business School',
        'European School of Osteopathy',
        'Falmouth University',
        'Futureworks Manchester Media School',
        'Glasgow Caledonian University',
        'Glasgow School of Art',
        'Gloucestershire College',
        'Glyndwr University, Wrexham',
        'Goldsmiths, University of London',
        'London, GSM',
        'Guildford College',
        'Halesowen College',
        'Harper Adams University',
        'Havering College of Further and Higher Education',
        'Hereford College of Arts',
        'Heriot-Watt University',
        'Holy Cross College',
        'Hugh Baird College',
        'Hull College',
        'Hult International Business School',
        'Imperial College London',
        'Institute of Contemporary Music Performance',
        'Istituto Marangoni London',
        'Keele University',
        'Kensington College of Business',
        'King\'s College London, University of London',
        'Kingston College',
        'Kingston University',
        'Lakes College West Cumbria',
        'Lancaster University',
        'Leeds Beckett University',
        'Leeds City College',
        'Leeds College of Art',
        'Leeds College of Music',
        'Leeds Trinity University',
        'Lincoln College',
        'Liverpool Hope University',
        'Liverpool Institute for Performing Arts',
        'Liverpool John Moores University',
        'London College of Communication, University of the Arts London',
        'London College of Fashion, University of the Arts London',
        'London College, UCK',
        'London Metropolitan University',
        'London School of Business and Management',
        'London School of Economics and Political Science, University of London',
        'London South Bank University',
        'Loughborough College',
        'Loughborough University',
        'Manchester College',
        'Manchester Metropolitan University',
        'Middlesex University',
        'Milton Keynes College',
        'Mont Rose College of Management and Sciences',
        'Moulton College',
        'Myerscough College',
        'Nazarene Theological College',
        'Durham, New College',
        'New College of the Humanities',
        'Newcastle College',
        'Newcastle University',
        'Newham College London',
        'Newman University',
        'Norland College Limited',
        'North Lindsey College',
        'Northbrook College Sussex',
        'Northumbria University',
        'Norwich University of the Arts',
        'Nottingham Trent University',
        'NPTC Group',
        'Oxford Brookes University',
        'Pearson College London (including Escape Studios)',
        'Peter Symonds College',
        'Plumpton College',
        'Plymouth College of Art',
        'Saint Mark and Saint John, University of',
        'Plymouth University',
        'Point Blank Ltd',
        'Queen Margaret University, Edinburgh',
        'Queen Mary University of London',
        'Queen\'s University Belfast',
        'Ravensbourne',
        'Regent\'s University London',
        'Richmond, The American International University in London',
        'Robert Gordon University',
        'Rose Bruford College',
        'Rotherham College of Arts and Technology',
        'Royal Academy of Dance',
        'Royal Academy of Music, University of London',
        'Royal Agricultural University',
        'Royal Central School of Speech and Drama',
        'Royal College of Music',
        'Royal Conservatoire of Scotland',
        'Royal Holloway, University of London',
        'Royal Northern College of Music',
        'Royal Veterinary College, University of London',
        'Royal Welsh College of Music and Drama',
        'Ruskin College',
        'Sandwell College of Further and Higher Education',
        'Selby College',
        'Sheffield College',
        'Sheffield Hallam University',
        'SOAS, University of London',
        'Solihull College',
        'South Essex College',
        'South Thames College',
        'Southampton Solent University',
        'Sparsholt College, Hampshire',
        'Spurgeon\'s College',
        'SRUC Scotland’s Rural College',
        'Saint George\'s, University of London',
        'Twickenham, Saint Mary\'s University',
        'Staffordshire University',
        'Stockport College',
        'Stranmillis University College',
        'Sussex Coast College Hastings',
        'Swansea University',
        'Teesside University',
        'The London Institute of Banking & Finance (formerly ifs University College)',
        'University of Law',
        'Manchester, The University of',
        'Trinity Laban Conservatoire of Music and Dance',
        'Truro and Penwith College',
        'UCL (University College London)',
        'Ulster, University of',
        'University Campus Barnsley',
        'Oldham, University Campus',
        'University Centre Colchester at Colchester Institute',
        'Croydon, University Centre',
        'Farnborough, University Centre',
        'Grimsby, University Centre',
        'University Centre Hartpury',
        'Peterborough, University Centre',
        'University Centre Reaseheath',
        'St Helens, University Centre',
        'Birmingham, University College',
        'University College of Estate Management',
        'University for the Creative Arts',
        'Aberdeen, University of',
        'Bath, University of',
        'Bedfordshire, University of',
        'Birmingham, University of',
        'Bolton, University of',
        'Bradford, University of',
        'Brighton, University of',
        'Bristol, University of',
        'Buckingham, University of',
        'Cambridge, University of',
        'Central Lancashire, University of',
        'Chester, University of',
        'Chichester, University of',
        'Cumbria, University of',
        'Derby, University of',
        'Dundee, University of',
        'East Anglia, University of',
        'East London, University of',
        'Edinburgh, University of',
        'Essex, University of',
        'Exeter, University of',
        'Glasgow, University of',
        'Gloucestershire, University of',
        'Greenwich, University of',
        'Hertfordshire, University of',
        'Huddersfield, University of',
        'Hull, University of',
        'Kent, University of',
        'Leeds, University of',
        'Leicester, University of',
        'Lincoln, University of',
        'Liverpool, University of',
        'University of London Institute in Paris',
        'Northampton, University of',
        'Nottingham, University of',
        'Oxford, University of',
        'Portsmouth, University of',
        'Reading, University of',
        'Roehampton, University of',
        'Salford, University of',
        'Sheffield, University of',
        'South Wales, University of',
        'Southampton, University of',
        'Saint Andrews, University of',
        'Stirling, University of',
        'Strathclyde, University of',
        'University of Suffolk',
        'Sunderland, University of',
        'Surrey, University of',
        'Sussex, University of',
        'Highlands and Islands, University of',
        'West of Scotland, University of the',
        'University of Wales Trinity Saint David',
        'Warwick, University of',
        'West London, University of',
        'Westminster, University of',
        'Winchester, University of',
        'Wolverhampton, University of',
        'Worcester, University of',
        'York, University of',
        'Wakefield College',
        'Warwickshire College',
        'Wimbledon College of Arts, University of the Arts London',
        'Wirral Metropolitan College',
        'Writtle University College',
        'York College',
        'York Saint John University'
    ],
    FIELDS: [
        "Accounting",
        "Aerospace engineering",
        "African studies",
        "Agricultural sciences",
        "Agriculture",
        "American studies",
        "Anatomy, physiology & pathology",
        "Ancient language studies",
        "Animal science",
        "Anthropology",
        "Archaeology",
        "Architecture",
        "Architecture, Building and Planning",
        "Astronomy",
        "Aural; oral sciences",
        "Biological Sciences",
        "Biology",
        "Biotechnology",
        "Botany",
        "Building",
        "Business and Administrative Studies",
        "Business studies",
        "Celtic studies",
        "Ceramics; glass",
        "Chemical, process; energy engineering",
        "Chemistry",
        "Chinese studies",
        "Cinematics; photography",
        "Civil engineering",
        "Classical Greek studies",
        "Clinical dentistry",
        "Comparative literary studies",
        "Complementary medicines, therapies; well-being",
        "Computer generated visual; audio effects",
        "Computer science",
        "Computer Sciences",
        "Crafts",
        "Creative Arts and Design",
        "Dance",
        "Design studies",
        "Development studies",
        "Drama",
        "Eastern, Asiatic, African, American and Australasian Languages, Literature and Related Subjects",
        "Economics",
        "Education",
        "Electronic; electrical engineering",
        "Engineering",
        "English studies",
        "European Languages, Literature and Related Subjects",
        "European Studies",
        "Finance",
        "Fine art",
        "Food; beverage studies",
        "Forensic; archaeological sciences",
        "Forestry; arboriculture",
        "French studies",
        "Games",
        "General engineering",
        "Genetics",
        "Geology",
        "German studies",
        "Health informatics",
        "Heritage studies",
        "Historical and Philosophical Studies",
        "History by area",
        "History by period",
        "History by topic",
        "Hospitality, leisure, sport, tourism; transport",
        "Human; social geography",
        "Human resource management",
        "Imaginative writing",
        "Information services",
        "Information systems",
        "Italian studies",
        "Japanese studies",
        "Journalism",
        "Landscape; garden design",
        "Latin studies",
        "Law",
        "Law by area",
        "Law by topic",
        "Linguistics",
        "Linguistics, Classics and Related Subjects",
        "Management studies",
        "Maritime technology",
        "Marketing",
        "Mass Communication and Documentation",
        "Materials science",
        "Materials technology not otherwise specified",
        "Mathematical Sciences",
        "Mathematics",
        "Mechanical engineering",
        "Media studies",
        "Medical technology",
        "Medicine and Dentistry",
        "Metallurgy",
        "Microbiology",
        "Minerals technology",
        "Modern Middle Eastern studies",
        "Molecular biology, biophysics; biochemistry (C700)",
        "Music",
        "Naval architecture",
        "Nursing",
        "Nutrition",
        "Office skills",
        "Operational research",
        "Ophthalmics",
        "Other Asian studies",
        "Pharmacology, toxicology; pharmacy",
        "Philosophy",
        "Physical geographical sciences",
        "Physical Sciences",
        "Physics",
        "Planning (urban, rural; regional)",
        "Politics",
        "Polymers; textiles",
        "Portuguese studies",
        "Pre-clinical dentistry",
        "Production; manufacturing engineering",
        "Psychology",
        "Publicity studies",
        "Publishing",
        "Research; study skills in education",
        "Russian; East European studies",
        "Scandinavian studies",
        "Science of aquatic; terrestrial environments",
        "Social policy",
        "Social Studies",
        "Social work",
        "Sociology",
        "Software engineering",
        "South Asian studies",
        "Spanish studies",
        "Sport; exercise science",
        "Statistics",
        "Subjects Allied to Medicine",
        "Technologies",
        "Theology; religious studies",
        "Training teachers",
        "Veterinary Sciences, Agriculture and Related Subjects",
        "Zoology"
    ]
}


const OnboardingPreferences: React.FC<LoaderProps> = ({ }) => {

    const [step, setStep] = useState <number> (1) // Step number
    const [email, setEmail] = useState <string> (null) // Email
    const [forcedSetup, setForcedSetup] = useState <boolean> (false)
    const [allSteps, setAllSteps] = useState <number> (0);
    const [completedSteps, setCompletedSteps] = useState <number> (0);
    const [currentStep, setCurrentStep] = useState <number> (0);
    const [steps, setSteps] = useState <Task[]> ([]);
    const [user, setUser] = useState <User> (null);
    const [currentPostalCode, setCurrentPostalCode] = useState <string> (null);
    const swiperRef = useRef(null);
    const progressBar = useRef(null);
    const contentRef = useRef(null);
    const [loadingMsg, setLoadingMsg] = useState <string> (null);
    const [sectors, setSectors] = useState <{ id: number, value: string, img: string, color: string }[]> ([]);
    const [sectorSelector, setSectorSelector] = useState <boolean> (false);
    const [keyboardOpen, setKeyboardOpen] = useState <boolean> (false) // Whether the keyboard is showing
    const [keyboardHeight, setKeyboardHeight] = useState <number> (0) // Keyboard height
    const [notchTop, setNotchTop] = useState <number | null> (null) // Notch top position
    const [notchBottom, setNotchBottom] = useState <number | null> (null) // Notch bottom position
    const [mapCenter, setMapCenter] = useState <{ lat: number, lng: number }>({ lat: 51.500958, lng: -0.121719 }) // Map center
    const [mapRadius, setMapRadius] = useState <number> (5000) // Map radius
    const [contactSearchModal, setContactSearchModal] = useState <"contact" | "agency" | null> (null) // Contact search modal type
    const [contactsList, setContactsList] = useState <Contact[]> ([]) // Contacts list without agencies
    const [agenciesList, setAgenciesList] = useState <Contact[]> ([]) // Agencies list without sharing relationship contacts
    const [editedQualificationI, setEditedQualificationI] = useState <number | null> (null) // Edited qualification index
    const [focusedField, setFocusedField] = useState <string> (null); // Focused field when editing qualification
    const [qualificationSuggestionQuery, setQualificationSuggestionQuery] = useState <string> (""); // Qualification suggestion query
    const [currentSMSSetupStep, setCurrentSMSSetupStep] = useState <number> (1); // Current SMS setup step
    const [otpCode, setOtpCode] = useState <string> (""); // OTP code
    const [showProgressBar, setShowProgressBar] = useState <boolean> (true); // Show progress bar
    const [hideSkillsHeader, setHideSkillsHeader] = useState <boolean> (false); // Hide skills header
    const [showEnhancedSkills, setShowEnhancedSkills] = useState <boolean> (true);
    const [justSavedPostcode, setJustSavedPostcode] = useState <boolean> (false);
    const [confirmSkip, setConfirmSkip] = useState <string | null> (null);
    const [alreadySkipped, setAlreadySkipped] = useState <boolean> (false);
    const [publicProfileMessage, setPublicProfileMessage] = useState <boolean> (false);
    const [confetti, setConfetti] = useState <boolean> (false);
    const [showSliderHelpGuide, setShowSliderHelpGuide] = useState <boolean> (false);
    const [skillSuggestionQuery, setSkillSuggestionQuery] = useState <string> ("");
    const [originalOrder, setOriginalOrder] = useState <string[]> ([]);
    const [logoutTaps, setLogoutTaps] = useState <number> (0);
    const [showSkillsConfirmButton, setShowSkillsConfirmButton] = useState <boolean> (true);
    const [successMessage, setSuccessMessage] = useState <string> (null);
    const [deniedLocation, setDeniedLocation] = useState <boolean> (false);
    const [skillsModalVisible, setSkillsModalVisible] = useState <boolean> (false);
    const [resizedPreviewImage, setResizedPreviewImage] = useState <File> (null);
    const [resizedPreviewImageUrl, setResizedPreviewImageUrl] = useState <string> (null);
    const [os, setOs] = useState <string> (null);

    useEffect(() => {
        if (successMessage) {
            setConfetti(true);
            setTimeout(() => {
                setSuccessMessage(null)
            }, 1500)
        }
    }, [successMessage])

    useEffect(() => {

        const redirectTo = localStorage.getItem("redirectOnLogin")
        if (redirectTo) {
          localStorage.removeItem("redirectOnLogin")
          window.location.href = redirectTo;
        } else {
            loadRequiredStep();
        }

        if (!isPlatform("mobileweb") && ( isPlatform("android") || isPlatform("ios") )) {
            Keyboard.addListener('keyboardWillShow', (info) => {
                setTimeout(() => {
                    setKeyboardOpen(true)
                })
                console.log("keyboard height: " + info.keyboardHeight)
                setKeyboardHeight(info.keyboardHeight);
            });
            
            Keyboard.addListener('keyboardWillHide', () => {
                setTimeout(() => {
                    setKeyboardOpen(false)
                })
            });
        }

        setTimeout(() => {
            let notchSize:any = getComputedStyle(window.document.documentElement).getPropertyValue("--ion-safe-area-bottom");
            let notchSizeTop:any = getComputedStyle(window.document.documentElement).getPropertyValue("--ion-safe-area-top");
            notchSize = parseInt(notchSize.replace("px", ""));
            notchSizeTop = parseInt(notchSizeTop.replace("px", ""));

            if (notchSize || notchSizeTop) {
              (window as any).notchTop = notchSizeTop || 0;
              (window as any).notchBottom = notchSize || 0;
              setNotchTop(notchSizeTop);
              setNotchBottom(notchSize);
            } else {
                setNotchBottom(0);
                setNotchTop(0);
            }
            setOs((window as any).os)
        }, 300)
    }, [])

    useEffect(() => {
        const step = globalSteps ? globalSteps[currentStep] : null;
        if (!step) {
            return;
        }
        
        if (currentStep || currentStep === 0) {
            const unsavedChanges = JSON.parse(localStorage.getItem("unsavedLocalUserChanges") || "{}");
            if (globalSteps[currentStep]) {
                // restoring values before section was skipped
                if (globalSteps[currentStep].id === "headline" && !user.headline && unsavedChanges.headline) {
                    setUser({ ...user, headline: unsavedChanges.headline });
                } else if (globalSteps[currentStep].id === "skills" && (!user.workerAttributes || user.workerAttributes.length === 0) && unsavedChanges.workerAttributes && unsavedChanges.workerAttributes.length) {
                    setUser({ ...user, workerAttributes: unsavedChanges.workerAttributes });
                    setHideSkillsHeader(true);
                } else if (globalSteps[currentStep].id === "qualifications" && (!user.qualifications || user.qualifications.length === 0) && unsavedChanges.qualifications && unsavedChanges.qualifications.length) {
                    setUser({ ...user, qualifications: unsavedChanges.qualifications });
                }
            }
            swipeTo(currentStep);
        }
    }, [currentStep])

    const swipeTo = (i: number) => {
        if (swiperRef.current && swiperRef.current.swiper()) {
            if (swiperRef.current.swiper().activeIndex === i) { return; }
            swiperRef.current.swiper().slideTo(i, 300);
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 500)
        } else {
            setTimeout(() => {
                swipeTo(i);
            }, 100);
        }
    }

    const autoFocusSchoolField = () => {
        setTimeout(() => {
            const element = document.querySelector(".school-qualification-input") as HTMLIonInputElement;
            if (element) {
                element.setFocus();
            }
        }, 300)
    }

    const updateQualification = async function(user: User, i: number, field: string, value: string) {
        if (field === "endMonth") {
            // if (value && value.startsWith("0") && value.length > 1) {
            //     value = value.substr(1)
            // }
            if (!value) {
                value = ""
            }
            switch(value.toLocaleLowerCase())  {
                case "jan": value = "1"; break;
                case "feb": value = "2"; break;
                case "mar": value = "3"; break;
                case "apr": value = "4"; break;
                case "may": value = "5"; break;
                case "jun": value = "6"; break;
                case "jul": value = "7"; break;
                case "aug": value = "8"; break;
                case "sep": value = "9"; break;
                case "oct": value = "10"; break;
                case "nov": value = "11"; break;
                case "dec": value = "12"; break;
            }
            if (value.toLocaleLowerCase() === "jan") {
                value = "1"
            }
        }
        let newQualifications = user.qualifications || [];
        newQualifications[i][field] = value
        setUser({ ...user, qualifications: newQualifications });
    }

    const saveLocalChanges = function(field, value) {
        let unsavedLocalUserChanges = JSON.parse(localStorage.getItem("unsavedLocalUserChanges") || "{}");
        unsavedLocalUserChanges[field] = value;
        localStorage.setItem("unsavedLocalUserChanges", JSON.stringify(unsavedLocalUserChanges));
    }

    const logOut = async function() {
        let hello = localStorage.getItem("hello");
        // @ts-ignore
        let idToken = hello ? JSON.parse(hello).b2cSignInAndUpPolicy.id_token : null;
        let redirectUri = CONFIG.PORTAL_URL + "/external/logged-out";
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("user");
        localStorage.removeItem("hello");
        localStorage.removeItem("firstSyncDone");
        localStorage.removeItem("existingNotifications");
        localStorage.removeItem("notificationToken");
        localStorage.removeItem("lastSync");
        await DestroyStorage();

        if (!isPlatform("mobileweb") && ( isPlatform("android") || isPlatform("ios") )) {
            Auth.Instance.addActionListener((action) => {
                if (action && action.action === "Sign Out Success") {
                    // alert("Successfully Signed Out")
                    setTimeout(() => {
                        window.location.href = "/onboarding"
                    }, 1500)
                }
            });
            Auth.Instance.signOut();
        } else {
            window.open(`https://${CONFIG.AUTH_TENANT_NAME}.b2clogin.com/${CONFIG.AUTH_TENANT_NAME}.onmicrosoft.com/oauth2/v2.0/logout?p=${CONFIG.AUTH_POLICY_NAME}&id_token_hint=${idToken}&post_logout_redirect_uri=${redirectUri}`)
            setTimeout(function() {
                window.location.href = "/onboarding"
            }, 2000);
        }
    }

    const reloadConacts = async function() {
        setLoadingMsg("Reloading Contacts...");
        let contacts = await Utilities.getContacts();
        setLoadingMsg(null);
        loadRequiredStep();
    }

    const sendOtpCode = async function(phoneNumber) {
        setOtpCode("");
        setLoadingMsg("Sending Verification SMS...");
        InternalTracker.trackEvent("Phone Number Updated");
        UserAPI.sendVerificationSMS(phoneNumber).then(res => {
            setLoadingMsg(null);
            reloadLocalData();
            setCurrentSMSSetupStep(2);
            setTimeout(() => {
                const input = document.querySelector(".otp-wrapper>div>input:first-child") as HTMLInputElement
                if (input) {
                    input.focus();
                }
            }, 1000)
        }).catch(e => {
            setLoadingMsg(null);
            (window as any).toast("Failed to send verification SMS", "error")
        })
    }

    const selectOrTakePhoto = async function() {
        if (isPlatform("android")) {
            const image = await Camera.getPhoto({
                quality: 90,
                allowEditing: false,
                resultType: CameraResultType.Base64,
                source: CameraSource.Prompt,
            });

            const paddedBase64 = "data:image/jpeg;base64," + image.base64String;

            const resized: File | void = await Utilities.resizeAndRotateImage(paddedBase64).catch(e => {
                (window as any).toast("Invalid image", "error")
                setLoadingMsg(null);
            });
        
            if (resized) {
                setLoadingMsg(null)
                setResizedPreviewImage(resized);
                setResizedPreviewImageUrl(URL.createObjectURL(resized));
            }

        } else {
            const element = document.getElementById("image-upload-input");
            if (element) {
                element.click();
            }
        }
    }

    const verifyOtpCode = async function(phoneNumber, code) {
        setLoadingMsg("Verifying Code...");
        UserAPI.verifyOtp(phoneNumber, code).then(res => {
            setLoadingMsg(null);
            // @ts-ignore
            if (!res.success) {
                // @ts-ignore
                if (res.message.includes("Otp code expired")) {
                    (window as any).toast("Expired Code, Sending a New One...", "error");
                    sendOtpCode(phoneNumber)
                } else {
                    // @ts-ignore
                    (window as any).toast(res.message || "Failed to verify", "error");
                }
                setOtpCode("");
            } else {
                InternalTracker.trackEvent("Phone Number Verified");
                reloadLocalData(undefined, undefined, "Phone number verified");
            }
        }).catch(e => {
            setLoadingMsg(null);
            (window as any).toast("Failed to verify", "error")
        })
    }

    const loadRequiredStep = async function(stepOverride = null) {
        // Hide tabs
        let elements = document.getElementsByTagName("ion-tab-bar");
        if (elements && elements[0]) { elements[0].style.display = "none" }

        let userStr: string = localStorage.getItem("user");
        let user: User = (userStr) ? JSON.parse(userStr) : null;
        let pushNotificationToken: string = localStorage.getItem("notificationToken");

        const env = localStorage.getItem("env") || "prod";
        setSectors([
          { value: "Education", id: 227, img: "education", color: "#70BF96" },
          { value: "Social Care", id: env === "prod" ? 932 : 980, img: "socialcare", color: "#79A2EF" },
          { value: "Hospitality", id:  env === "prod" ? 931 : 970, img: "hospitality", color: "#AA77F0" },
          { value: "Others", id: 0, img: "-", color: "#6A6A6A" }
        ])

        if (!user || !localStorage.getItem("firstSyncDone")) {
            
            setStep(1);

            try {
                let user = await UserAPI.getNew().catch(e => {
                    logOut();
                });
                let contacts = await Utilities.getContacts(); //  ContactAPI.getContacts();
               
                if ((window as any).Sync && (window as any).Sync.sync) {
                    (window as any).Sync.sync( async function() {
                        // @ts-ignore
                        localStorage.setItem("user", JSON.stringify(user.results));
                        localStorage.setItem("firstSyncDone", "true");
                        const eventCounts = await getTotalEventsCount();
                        localStorage.setItem("lastLocalEventsCount", eventCounts.toString());
                        // window.location.href = "/calendar";
                        loadRequiredStep();
                        return;
                    }, function() {
                        throw Error();
                    });
                }

            } catch (e) {
                (window as any).toast("Failed to load user profile, make sure you are connected to the internet.", "error")
                setTimeout(() => {
                    window.location.href = "/"
                }, 2000)
            }

            return;

        }

        if (!user.canSync) {
            setStep(2);
            setEmail(user.email)
            return;
        }

        if (!user.industries) {
            user.industries = [];
        }

        let haveEvent = await hasUndeletedEvents();
        let hirersCount = parseInt(localStorage.getItem("hirersCount") || "0") || 0;
        let agenciesCount = parseInt(localStorage.getItem("agenciesCount") || "0") || 0;
        let softSkipped = localStorage.getItem("softskippedSections") ? JSON.parse(localStorage.getItem("softskippedSections")) : [];
        let softSkippedAllTime = localStorage.getItem("softskippedSectionsAllTime") ? JSON.parse(localStorage.getItem("softskippedSectionsAllTime")) : [];
        let introSkipped = localStorage.getItem("forcedSetupIntroSkipped") ? true : false;

        let tasks: Task[] = [
            {
                id: "intro",
                title: "",
                description: "",
                completed: introSkipped
            },
            {
                id: "image",
                title: "Add your Profile Photo",
                description: "Your profile is missing a photo like the ones below. You must add an image to help your contacts.",
                completed: user.userSetProfileImage
            },
            {
                id: "skills",
                title: "Add your Skills",
                description: "Showcase your skills",
                descriptionSub: "Add skills for preferred jobs",
                completed: (user && user.workerAttributes && user.workerAttributes.length > 0)
            },
            {
                id: "headline",
                title: "Write your Headline",
                description: "Explain what you do, with key skills in one sentence.",
                completed: user.headline !== undefined && user.headline !== null && user.headline !== "",
            },
            {
                id: "postalcode",
                title: "Location Preferences",
                description: "Where is your home base, and how far are you willing to travel?",
                completed: user.latitude && user.longitude && user.maxDistanceSet
            },
            {
                id: "contacts",
                title: "Add your Contacts",
                description: "Add contacts to share your availability with",
                completed: (user.settings && user.settings.find(s => s.settingId === 43)) ? true : false // hirersCount !== 0
            },
            {
                id: "agencies",
                title: "Add Representing Agencies",
                description: "This will make it easier for agencies to send direct assignments, and for contacts to offer work to you ",
                completed: (user.settings && user.settings.find(s => s.settingId === 44)) ? true : false  // agenciesCount !== 0
            },
            {
                id: "qualifications",
                title: "Add your Qualifications",
                description: "Please add and Showcase all your qualifications.",
                completed: (user.settings && user.settings.find(s => s.settingId === 47) || (user.qualifications && user.qualifications.length !== 0)) ? true : false
            },
            {
                id: "smsnotifications",
                title: "Enter your Phone Number",
                description: "To receive and apply to offers without any internet connection",
                completed: (user.phoneNumber && user.phoneNumberVerified) || localStorage.getItem("forcedSetupSMSNotificationsSkipped") ? true : false,
            },
            ((!isPlatform("mobileweb") && (isPlatform("android") || isPlatform("ios")))) ? {
                id: "pushnotifications",
                title: "Enable Push Notifications",
                description: "Get alerts for offers, confirmations, and chats. We strongly suggest you enable notifications.",
                completed: (localStorage.getItem("notificationToken") || localStorage.getItem("actionedNotifications")) ? true : false
            } : null,
            {
                id: "addevents",
                title: "Add your First Events",
                description: "Add events so your contacts know when you are unavailable, or available.",
                completed: haveEvent
            },
            localStorage.getItem("FeatureFiles") ? {
                id: "files",
                title: "Show What I Do",
                description: "Add examples of your work files to showcase your expertise",
                descriptionCompleted: "Add examples of your work files to showcase your expertise",
                completed: user.files && user.files.length !== 0,
            } : null,
            {
                id: "publicprofile",
                title: "Interested in more work?",
                description: "",
                completed: (user.settings && user.settings.find(s => s.settingId === 49)) ? true : false
            },
            {
                id: "completed",
                title: "You're set!",
                description: "",
                completed: (user.settings && user.settings.find(s => s.settingId === 48)) ? true : false
            }
        ]

        console.log(tasks, 'tasks <<<')

        const ORIGINAL_ORDER = [
            "image",
            "skills",
            "headline",
            "postalcode",
            "contacts",
            "agencies",
            "qualifications",
            "smsnotifications",
            "pushnotifications",
            "addevents",
            "files",
            "publicprofile",
            "completed"
        ].filter(taskId => tasks.map(task => task ? task.id : "").includes(taskId));

        setOriginalOrder(ORIGINAL_ORDER);

        tasks = tasks.filter(task => task).map(task => {
            task.softSkipped = softSkipped.includes(task.id);
            return task;
        })

        softSkipped = softSkipped.filter(item => !tasks.find(step => step.id === item).completed);
        softSkippedAllTime = softSkippedAllTime.filter(item => !tasks.find(step => step.id === item).completed);
        localStorage.setItem("softskippedSections", JSON.stringify(softSkipped));
        localStorage.setItem("softskippedSectionsAllTime", JSON.stringify(softSkippedAllTime));

        // .sort((a, b) => {
        //     // always put "completed" at the end
        //     if (a.id === "completed") return 1;
        //     if (b.id === "completed") return -1;
        //     // sort by soft skipped
        //     if (a.softSkipped && !b.softSkipped) return 1;
        //     if (!a.softSkipped && b.softSkipped) return -1;
        //     // sort by completed
        //     if (a.completed && !b.completed) return -1;
        //     if (!a.completed && b.completed) return 1;
        //     return 0;
        // })

        if (tasks.find(item => item.completed) && stepOverride !== "intro") {
            tasks[0].completed = true;
        }

        if (tasks.filter(task => task.completed || task.softSkipped).length === tasks.length && tasks.find(task => task.softSkipped)) {
            localStorage.removeItem("softskippedSections");
            loadRequiredStep();
            return;
        }

        const lastCompletedSteps = localStorage.getItem("lastCompletedSteps") ? parseInt(localStorage.getItem("lastCompletedSteps")) : 0;
        // if (stepOverride !== "intro" && lastCompletedSteps > 0 && localStorage.getItem("lastCompletedSteps") && lastCompletedSteps < tasks.filter(task => task.completed).length) {
        //     setConfetti(true);
        // }
        localStorage.setItem("lastCompletedSteps", tasks.filter(task => task.completed).length.toString());

        let completedStepsCount = tasks.filter(item => item.completed).length;
        let completedOrSkippedSteps = tasks.filter(item => item.completed || item.softSkipped).length;
        const REQUIRED_TO_COMPLETE_ALL_AFTER = new Date("2024-06-20T00:00:00.000Z");
        const userSignedUp = new Date(user.createdAt);

        if (!localStorage.getItem("topSkills")) {
            getTopSkills();
        }

        if (userSignedUp > REQUIRED_TO_COMPLETE_ALL_AFTER /* && completedStepsCount < tasks.length */ && (!user.settings || (user.settings && !user.settings.find(s => s.settingId === 48)))) {
            globalSteps = tasks;
            window.dispatchEvent(new Event('resize'));
            localStorage.setItem("forceSetup", "true");
            user.qualifications = user.qualifications || [];
            user.qualifications = user.qualifications.map(item => {
                if (item.endDate) {
                    item.endYear = item.endDate.split("-")[0]
                    item.endMonth = item.endDate.split("-")[1]
                }
                return item;
            })
            setCurrentSMSSetupStep(user.phoneNumberVerified ? 4 : (user.phoneNumber ? 2 : 1));
            setUser(user);
            setCurrentPostalCode(user.postalCode);
            setHideSkillsHeader(user.workerAttributes && user.workerAttributes.length > 0);
            if (localStorage.getItem("contactsList")) {
                setContactsList(JSON.parse(localStorage.getItem("contactsList")));
            }
            if (localStorage.getItem("agenciesList")) {
                setAgenciesList(JSON.parse(localStorage.getItem("agenciesList")));
            }

            if (!user.maxDistance && !user.maxDistanceSet) {
                user.maxDistance = 30;
                setShowSliderHelpGuide(true);
                setUser(user);
            }
            
            if (user.latitude && user.longitude) {
                setMapCenter({
                    lat: user.latitude,
                    lng: user.longitude
                })
                // max distance is miles, convert to meters
                setMapRadius(user.maxDistance * 1609.34)
            }

            const firstUncompletedStep = tasks.find(item => !item.completed);

            setForcedSetup(true);
            setAllSteps(tasks.length);
            setSteps(tasks);
            setStep(10);
            setCompletedSteps(completedSteps+1);
            let step = null;

            if (!stepOverride) { // loading next incomplete step
                const nextIncompleteStep = tasks.findIndex(item => !item.completed && !item.softSkipped);
                if (nextIncompleteStep !== -1) {
                    if (tasks[nextIncompleteStep].id === "completed") {
                        const allIncompleted = tasks.filter(item => !item.completed && item.id !== "completed").length;
                        if (allIncompleted !== 0) {
                            localStorage.setItem("softskippedSections", JSON.stringify([]));
                            loadRequiredStep();
                            return;
                        }
                    }
                }
                setCurrentStep(nextIncompleteStep);
                step = tasks[nextIncompleteStep];
            } else { // loading override back/forth step
                setCurrentStep(tasks.findIndex(item => item.id === stepOverride));
                step = tasks.find(item => item.id === stepOverride);
            }

            if (step.id === "postalcode" || step.id === "publicprofile") {
                (window as any).allowFitBounds = true;
                setTimeout(() => {
                    (window as any).allowFitBounds = false;
                }, 1000);
            }

            if (step.id === "postalcode" && !user.latitude) {
                fetchCurrentLocation(true)
            }

            return;
        }

        if (!pushNotificationToken) {
            setStep(4);
            return;
        }

        localStorage.setItem("passedOnboarding", "true")

        // setTimeout(() => {
            // (window as any).toast("You are all set up!", "success") // if not here, then white screen until next refresh
            window.location.href = "/";
        // }, 1000);
    }

    const fetchCurrentLocation = async function(auto: boolean = false) {
        setLoadingMsg("Fetching your location...");
        const coordinates = await Geolocation.getCurrentPosition().catch(e => { console.log(e) });
        // console.log("COORDS", coordinates)
        (window as any).allowFitBounds = true;
        if (coordinates && coordinates.coords) {
            setMapCenter({
                lat: coordinates.coords.latitude,
                lng: coordinates.coords.longitude
            })
            setDeniedLocation(false);
        } else {
            setDeniedLocation(true);
            setPostcodeAsCenter(auto);
        }
        setLoadingMsg(null);
        setTimeout(() => {
            (window as any).allowFitBounds = false;
        }, 300)
    }

    const setPostcodeAsCenter = async function(auto: boolean = false) {
        if (auto) {
            if ((window as any).autoPromptedPostcode) {
                return;
            } else {
                (window as any).autoPromptedPostcode = true;
            }
        }
        await new Promise(resolve => setTimeout(resolve, 500));
        const postcode = window.prompt("Enter your postcode");
        if (postcode) {
            setLoadingMsg("Fetching location...");
            const coordinates = await GoogleMapsAPI.getCoordinatesByPostcode(postcode).catch(e => { console.log(e) });
            (window as any).allowFitBounds = true;
            if (coordinates && coordinates.lat && coordinates.lng) {
                setMapCenter({
                    lat: coordinates.lat,
                    lng: coordinates.lng
                })
                setCurrentPostalCode(postcode);
            } else {
                (window as any).toast("Failed to fetch location, please try again", "error")
            }
            setTimeout(() => {
                (window as any).allowFitBounds = false;
            }, 300)
            setLoadingMsg(null);
        }
    }

    const getTopSkills = async function() {
        setShowEnhancedSkills(false)
        const response = await SectorsAPI.searchSkills("", 0, 0, 0, 0);
        if (response) {
            localStorage.setItem("topSkills", JSON.stringify(response));
            setShowEnhancedSkills(true)
        }
    }

    const reloadLocalData = function(onFinish?, newUserUpsert?: any, successMessage?: string) {

        setTimeout(() => {

            // @ts-ignore
            if (newUserUpsert && newUserUpsert.results) {
                let user = newUserUpsert.results;
                let oldUser = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {};
                localStorage.setItem("user", JSON.stringify({...oldUser, ...user}))
                loadRequiredStep();
                setLoadingMsg(null);
                if (successMessage) {
                    setTimeout(() => {
                        setSuccessMessage(successMessage);
                    }, 250)
                }
            } else {
                setLoadingMsg("Updating your progress...");
                UserAPI
                .getNew()
                .then(data => {
                    // @ts-ignore
                    let user = data.results;
                    if (user) {
                        let oldUser = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {};
                        localStorage.setItem("user", JSON.stringify({...oldUser, ...user}))
                    }
                    setLoadingMsg(null);
                    loadRequiredStep();
                    if (successMessage) {
                        setTimeout(() => {
                            setSuccessMessage(successMessage);
                        }, 250)
                    }
                }).catch(e => {
                    (window as any).toast("Failed to fetch your profile " + JSON.stringify(e), "error")
                })
            }

        }, 1);

    }

    if (step === 1)
        return (
            <div className="full-screen-loader" style={{
                background: "white",
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <div style={{
                    textAlign: "center"
                }}>
                    <CircularProgress />
                    <h1 style={{
                        color: '#333',
                        margin: '24px 0 0 0',
                        fontSize: '1.6em'
                    }}>Fetching your details, this will only take a few seconds...</h1>
                    <button onClick={ async () => {
                        logOut();
                    }}>Log Out</button>
                </div>
            </div>
        );

    if (step === 2) {
        return (
            <div className="preferences">
                <VerifyEmail email={email} onClose={() => {
                    loadRequiredStep();
                }} />
            </div>
        )
    }

    if (step === 4) {
        return (
            <div className="preferences">
                <PushNotificationManagement
                    onClose={() => {
                        loadRequiredStep();
                    }}
                />
            </div>
        )
    }

    const totalCompletedStepsCount = steps ? steps.filter(item => item.completed).length : 0;
    if (step === 10) {
        const currentStepObj = steps[currentStep];
        const progressHeader = (showProgressBar && !keyboardOpen && (!currentStepObj || (currentStepObj && currentStepObj.id !== "completed1"))) ? 
            (<div 
                className='progress-header' 
                data-section={currentStepObj ? currentStepObj.id : ""}
                style={{ 
                    top: (currentStepObj && currentStepObj.id === "addevents" && notchTop) ? (notchTop || 0) : notchTop ? (notchTop + 20 + "px") : "15px",
                    visibility: currentStepObj && currentStepObj.id === "intro" ? "hidden" : "visible",
                    display: undefined, // currentStepObj && currentStepObj.id === "skill" && keyboardOpen ? "none" : "block"
                    zIndex: skillsModalVisible ? 1 : 2
                }}
            >
                <button 
                    className='back-btn'
                    onClick={() => {
                        const firstRequiredStepId = "image";
                        const currentStepId = currentStepObj.id;
                        const previousStepId = originalOrder[originalOrder.indexOf(currentStepId) - 1];

                        if (firstRequiredStepId === currentStepId) {
                            localStorage.removeItem("forcedSetupIntroSkipped");
                            loadRequiredStep("intro");
                        } else {
                            if (previousStepId) {
                                console.log("Force back to " + previousStepId);
                                loadRequiredStep(previousStepId);
                            } else {
                                console.log("Already at end");
                            }
                        }
                    }}
                    style={{
                        color: (currentStepObj && (currentStepObj.id === "postalcode")) ? "#3573E6" : "white"
                    }}
                >
                    BACK
                </button>
                <div 
                    className='progress-bar progress-bar-new'
                    ref={progressBar}
                >
                    { (allSteps) &&
                        <div 
                            className='current'
                            data-completed={totalCompletedStepsCount}
                            data-all={allSteps}
                            style={{
                                width: (((totalCompletedStepsCount + 1) / allSteps) * window.innerWidth) + "px"
                            }}
                        >
                        </div>
                    }
                </div>
                <div 
                    className='progress-bar'
                    ref={progressBar}
                    onClick={() => {
                        if (logoutTaps > 4) {
                            if (window.confirm("Are you sure you want to log out?")) {
                                Utilities.logOut();
                            }
                        } else {
                            clearTimeout(logoutTapsTimeout);
                            logoutTapsTimeout = setTimeout(() => {
                                console.log("reset to 0")
                                setLogoutTaps(0);
                            }, 1000)
                            console.log("setting it to " + (logoutTaps + 1))
                            setLogoutTaps(logoutTaps + 1);
                        }
                    }}
                >
                    { (currentStepObj && currentStepObj.title) &&
                        <span>{currentStepObj.title}</span>
                    }
                </div>
                <button 
                    className='skip-btn'
                    onClick={() => {
                        if (currentStepObj && currentStepObj.id === "completed") {
                            setLoadingMsg("Finishing up...");
                            UserAPI.updateSetting("48", "true").then(() => {
                                reloadLocalData();
                                InternalTracker.trackEvent("Onboarding Completed");
                                setLoadingMsg(null);
                            }).catch(e => {
                                console.error(e)
                                setLoadingMsg(null);
                                (window as any).toast("Failed to complete your onboarding, please try again later", "error")
                            })
                        } else if (currentStepObj && currentStepObj.completed) {
                            const currentStepId = currentStepObj.id;
                            const nextStepId = originalOrder[originalOrder.indexOf(currentStepId) + 1];
                            if (nextStepId) {
                                console.log("Force skip to " + nextStepId);
                                loadRequiredStep(nextStepId);
                            } else {
                                console.log("Already at end");
                            }
                        } else {
                            let softSkipped = localStorage.getItem("softskippedSections") ? JSON.parse(localStorage.getItem("softskippedSections")) : [];
                            let softSkippedAllTime = localStorage.getItem("softskippedSectionsAllTime") ? JSON.parse(localStorage.getItem("softskippedSectionsAllTime")) : [];

                            const nextIncompleteStep = steps.findIndex(item => !item.completed && !item.softSkipped);
                            const currentStep = steps.findIndex(item => item.id === currentStepObj.id);
                            
                            const incompleteStepsCount = steps.filter(item => !item.completed && item.id !== "completed").length;
                            if (incompleteStepsCount < 2) {
                                alert("This is the last step, you can't skip it.");
                                return;
                            }
                            
                            if (nextIncompleteStep !== -1 && steps[nextIncompleteStep].id === "completed") {
                                const allIncompleted = steps.filter(item => !item.completed && item.id !== "completed").length;
                                if (allIncompleted < 2) {
                                    alert("This is the last step, you can't skip it.")
                                    return;
                                }
                            }

                            setConfirmSkip(currentStepObj.id);
                            const alreadySkipped = softSkippedAllTime.includes(currentStepObj.id);
                            if (alreadySkipped) {
                                localStorage.setItem("alreadySkippedEverything", "true");
                                localStorage.removeItem("softskippedSections");
                            }
                            setAlreadySkipped(alreadySkipped || localStorage.getItem("alreadySkippedEverything"));
                        }
                    }}
                    style={{
                        color: (currentStepObj && (currentStepObj.id === "postalcode")) ? "#3573E6" : "white"
                    }}
                >
                    SKIP
                </button>
            </div>
            ) 
        : null;

        if (!user) {
            return null;
        }

        const editedQualification = editedQualificationI !== null && user.qualifications && user.qualifications[editedQualificationI] ? user.qualifications[editedQualificationI] : null;
        const BOTTOM_OPTIONS_STYLE = keyboardOpen ? { bottom: -2 } : notchBottom ? { bottom: 0 /* (notchBottom + 15) + "px" */ } : { };

        return (
            <div 
                className="swiper-wrapper-outer"
                data-keyboard-open={keyboardOpen}
                data-os={os}
                data-added-one={hideSkillsHeader ? "true" : "false"}
                data-section={currentStepObj ? currentStepObj.id : ""}
                style={{ 
                    paddingTop: keyboardOpen && (!currentStepObj || currentStepObj.id !== "skills") ? "0px" : notchTop ? (notchTop + "px") : '0px',
                    paddingBottom: (keyboardOpen || (currentStepObj && ((currentStepObj.id === "skills" && hideSkillsHeader) || currentStepObj.id === "addevents") )) ? undefined : notchBottom ? (notchBottom + "px") : "0px",
                    height: keyboardOpen ? "100%" : (notchTop || notchBottom) ? ("calc(100% - " + ((notchTop || 0) + (notchBottom || 0)) + "px)") : "100%"
                }}
            >
                {progressHeader}
                { (steps && steps.length > 0) &&
                    <Swiper
                        swiperOptions={{
                            slidesPerView: 'auto',
                            direction: 'horizontal',
                            noSwipingClass: 'no-swipe',
                            noSwiping: true
                        }}
                        ref={swiperRef}
                        onTransitionEnd={(swiper) => {
                            console.log('swiped to: ' + swiperRef.current.swiper().activeIndex);
                        }}
                        style={{ }}
                    >
                        {steps.map((step, slideI) => {
                            return (
                                <Slide className="no-swipe" data-i={slideI} key={"slide-" + slideI}>
                                    <div className='slide-wrapper'>
                                        <div 
                                            className='slide-content'
                                            style={{ }}
                                        >
                                            { (successMessage) &&
                                                <div className='success-message'>
                                                    <i className="fas fa-thumbs-up"></i>
                                                    <p>{successMessage}</p>
                                                </div>
                                            }
                                            { (step.id === "intro") ?
                                                <section data-section="intro">
                                                    <header>
                                                        <div className='image-wrapper'>
                                                            <img src={ProfileSkeletonImg} />
                                                        </div>
                                                        {/* <h1>Craft your Profile</h1> */}
                                                        <h1>Just a few minutes to complete</h1>
                                                        <p>Craft your profile thoughtfully</p>
                                                    </header>
                                                    <div className='center'>
                                                        <div className='advantages'>
                                                            { [
                                                                "Express your unique identity",
                                                                "Highlight your skills & qualifications",
                                                                "Streamline your availability sharing",
                                                                "Connect with contacts through chat",
                                                                "Rate and be rated by others",
                                                                "Increase your visibility to new contacts seeking talents like yours ",
                                                                "Maximize your earning potential today!"
                                                            ].map((item, i) => {
                                                                return (
                                                                    <div>
                                                                        <div className='circle'>
                                                                            <IonIcon icon={checkmarkSharp} />
                                                                        </div>
                                                                        <p>{item}</p>
                                                                    </div>
                                                                )
                                                            }) }
                                                        </div>
                                                    </div>
                                                    <div className='bottom-options' style={BOTTOM_OPTIONS_STYLE}>
                                                        <button className='main' onClick={() => {
                                                            localStorage.setItem("forcedSetupIntroSkipped", "true");
                                                            loadRequiredStep();
                                                        }}>Get Started</button>
                                                    </div>
                                                </section>
                                            : (step.id === "image") ? 
                                                <section data-section="image">
                                                    <header>
                                                        <p>{resizedPreviewImageUrl ? "Please confirm or re-take your photo" : step.description}</p>
                                                    </header>
                                                    <div 
                                                        className='center'
                                                        style={ resizedPreviewImageUrl ? { marginTop: 0 } : { } }
                                                    >
                                                        <div 
                                                            data-preview-ready={resizedPreviewImage !== null}
                                                            className='availability-calendar' onClick={() => {
                                                                selectOrTakePhoto();
                                                            }}
                                                        >
                                                            { (!resizedPreviewImageUrl) &&
                                                                <div className='arrow-wrapper'>
                                                                    <LongArrow />
                                                                    <span>Your profile image here</span>
                                                                </div>
                                                            }
                                                            <img src={AvailabilityCalendarImg} />
                                                            <div 
                                                                className='contacts' onClick={() => {
                                                                    selectOrTakePhoto();
                                                                }}
                                                            >
                                                                <div>
                                                                    <img src={ resizedPreviewImageUrl ? resizedPreviewImageUrl : (UserAPI.getProfilePicture(user ? user.id : "undefined", user.userSetProfileImage ? 2 : 1))} />
                                                                    <p>{user && user.firstName ? (user.firstName + " " + user.lastName) : "Jon Doe" }</p>
                                                                </div>
                                                                <div style={resizedPreviewImageUrl ? { opacity: 0.5 } : { } }>
                                                                    <img src={UserAPI.getProfilePicture(localStorage.getItem("env") === "dev" ? "2583d191-183c-422e-ab0d-a4e949233a11" : "3d549426-ccf8-4c3b-98b7-86b59659178f")} />
                                                                    <p>Tim Martinez</p>
                                                                </div>
                                                                <div style={resizedPreviewImageUrl ? { opacity: 0.5 } : { } }>
                                                                    <img  src={UserAPI.getProfilePicture(localStorage.getItem("env") === "dev" ? "92bb2147-5bb3-409b-a396-85294dd3886a" : "a6471ce8-f9be-43e0-bc9b-7d29f61451e9")} />
                                                                    <p>Katrina Wakefield</p>
                                                                </div>
                                                                <div style={resizedPreviewImageUrl ? { opacity: 0.5 } : { } }>
                                                                    <img  src={UserAPI.getProfilePicture(localStorage.getItem("env") === "dev" ? "deed3f40-7989-4173-acfe-c34fd32439ff" : "37edb48d-f275-49d3-b2bf-0e9595110a80")} />
                                                                    <p>Paul Livemore</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    { (currentStepObj && currentStepObj.id === "image") &&
                                                        <input id="image-upload-input" style={{ display: 'none' }} type='file' onChange={ async (e) => {

                                                            const files = Array.from(e.target.files);
                                                            if (files[0]) {
                                                                setLoadingMsg("Uploading Image");

                                                                const resized: File | void = await Utilities.resizeAndRotateImage(files[0] as Blob, 512, true).catch(e => {
                                                                    (window as any).toast("Invalid image", "error")
                                                                    setLoadingMsg(null);
                                                                });
    
                                                                if (resized) {
                                                                    setLoadingMsg(null)
                                                                    setResizedPreviewImage(resized);
                                                                    setResizedPreviewImageUrl(URL.createObjectURL(resized));
                                                                }
                                                            }

                                                        }} />
                                                    }
                                                    <div className='bottom-options' style={{
                                                        ...BOTTOM_OPTIONS_STYLE,
                                                        display: resizedPreviewImage ? 'flex' : undefined
                                                    }}>
                                                        <button 
                                                            className='main' 
                                                            onClick={ async () => {
                                                                selectOrTakePhoto();
                                                            }}
                                                            style={{
                                                                marginRight: resizedPreviewImageUrl ? 5 : undefined
                                                            }}
                                                        >
                                                            { resizedPreviewImage ? "Re-take" : "Select or Take Photo" }
                                                        </button>
                                                        { (resizedPreviewImageUrl) &&
                                                            <button 
                                                                className='main' 
                                                                style={{
                                                                    marginLeft: 5
                                                                }}
                                                                onClick={() => {
                                                                    setLoadingMsg("Uploading Image");
                                                                    UserAPI
                                                                        .uploadProfilePicture(resizedPreviewImage, user.id)
                                                                        .then(data => {
                                                                            setResizedPreviewImage(null);
                                                                            setResizedPreviewImageUrl(null);
                                                                            reloadLocalData(undefined, undefined, "Profile image updated");
                                                                            InternalTracker.trackEvent("Profile Image Updated");
                                                                        }).catch(e => {
                                                                            console.error(e)
                                                                            setLoadingMsg(null);
                                                                            (window as any).toast("Failed to upload profile image, make sure it is less than 10MB and that you have internet connection.", "error")
                                                                        })
                                                                }}
                                                            >
                                                                Confirm
                                                            </button>
                                                        }
                                                    </div>
                                                </section>
                                            : (step.id === "headline") ?
                                                <section data-section="headline">
                                                    { (keyboardOpen) &&
                                                        <div 
                                                            className="keyboard-hide"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                            }}
                                                        >
                                                            <IonIcon icon={caretDownCircle} />
                                                        </div>
                                                    }
                                                    <header>
                                                        <p>{step.description}</p>
                                                    </header>
                                                    <div className='center'>
                                                        { (!keyboardOpen) &&
                                                            <React.Fragment>
                                                                <div className="from-me loud has-img">
                                                                    <p>Primary schools teacher with 20 years experience from KS1 and KS2. Preference to work in Years 2 to 6. Happy to provide PPA cover</p>
                                                                    <img src={Avatar1} />
                                                                </div>
                                                                <div className="clear"></div>
                                                                <div className="from-them has-img">
                                                                    <p>Experienced Secondary KS3 and KS4 Teacher of English Language and Literature</p>
                                                                    <img src={Avatar2} />
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                        <div className="from-me loud has-img text-area">
                                                            { (currentStepObj && currentStepObj.id === "headline") &&
                                                                <IonTextarea
                                                                    rows={(isPlatform("android") || isPlatform("ios")) ? (keyboardOpen ? 5 : 2) : 5}
                                                                    placeholder="Describe yourself to Contacts" 
                                                                    value={user ? (user.headline || "") : ""}
                                                                    onIonInput={(e) => { 
                                                                        const newValue = (e.target as HTMLIonTextareaElement).value;
                                                                        const newHeadline = newValue.length < 250 ? newValue : newValue.substr(0, 250);
                                                                        setUser({...user, headline: newHeadline })
                                                                        if (newHeadline.trim()) {
                                                                            saveLocalChanges("headline", newHeadline)
                                                                        }
                                                                    }}
                                                                    onFocus={() => {
                                                                        // setTyping(true);
                                                                    }}
                                                                    onBlur={() => {
                                                                        // setTyping(false);
                                                                    }}
                                                                    id='headline-input'
                                                                />
                                                            }
                                                            <img src={(UserAPI.getProfilePicture(user ? user.id : "undefined", user.userSetProfileImage ? 3 : 4))} />
                                                            { (user.headline && user.headline.length > 160) &&
                                                                <div className={'chars-left' + (user.headline.length > 220 ? " danger" : "")}>{250 - user.headline.length} characters left</div>
                                                            }
                                                        </div>
                                                        <div className="clear"></div>
                                                    </div>
                                                    <div className='bottom-options' style={BOTTOM_OPTIONS_STYLE}>
                                                        <button 
                                                            className='main' 
                                                            onClick={() => {
                                                                if (!user.headline || user.headline.length === 0) {
                                                                    (window as any).toast("Please enter your headline", "error")
                                                                    return;
                                                                }

                                                                setLoadingMsg("Updating your headline");
                                                                UserAPI.update({
                                                                    headline: user ? Utilities.capitalizeEachSentence(user.headline) : ""
                                                                }, true).then((data) => {
                                                                    reloadLocalData(undefined, data, "Headline Saved");
                                                                    InternalTracker.trackEvent("Headline Updated");
                                                                }).catch(e => {
                                                                    console.error(e)
                                                                    setLoadingMsg(null);
                                                                    (window as any).toast("Failed to update your headline, please try again later", "error")
                                                                });
                                                            }}
                                                        >
                                                            {user && user.headline && user.headline.length === 0 ? "Please Enter your Headline" : "Save"}
                                                        </button>
                                                    </div>
                                                </section>
                                            : (step.id === "postalcode") ?
                                                <section data-section="postalcode">
                                                    <header>
                                                        <p>{step.description}</p>
                                                    </header>
                                                    <div className='center'>
                                                        { (currentStepObj && currentStepObj.id === "postalcode") &&
                                                            <DynamicMap 
                                                                center={mapCenter} 
                                                                radius={mapRadius}
                                                                onPostcodeChange={(postcode) => {
                                                                    if (postcode) {
                                                                        setCurrentPostalCode(postcode);
                                                                    }
                                                                }}
                                                                onCoordinatesChange={(lat, lng) => {
                                                                    setMapCenter({ lat, lng });
                                                                }}
                                                            />
                                                        }
                                                    </div>
                                                    <div className='bottom-options' style={BOTTOM_OPTIONS_STYLE}>
                                                        <div className='map-options'>
                                                            <button onClick={() => {
                                                                setPostcodeAsCenter();
                                                            }}>Search by Postcode</button>
                                                            <button>
                                                                <IonIcon 
                                                                    icon={locate}
                                                                    onClick={() => {
                                                                        fetchCurrentLocation();
                                                                    }}
                                                                />
                                                            </button>
                                                        </div>
                                                        <React.Fragment>
                                                            <h3 style={{
                                                                padding: "0 15px"
                                                            }}>How far are you willing to travel for work?</h3>
                                                            <label>Adjust the below slider by dragging it left or right</label>
                                                            <div
                                                                style={{
                                                                    margin: '15px 15px',
                                                                }}
                                                                className="slider-wrapper"
                                                            >
                                                                { (currentStepObj && currentStepObj.id === "postalcode") &&
                                                                <Range
                                                                    values={[user.maxDistance]}
                                                                    step={5}
                                                                    min={MIN}
                                                                    max={MAX}
                                                                    onFinalChange={values => {
                                                                        setUser({...user, maxDistance: values[0]});
                                                                        setMapRadius(values[0] * 1609.34);
                                                                        setShowSliderHelpGuide(false);
                                                                    }}
                                                                    onChange={values => {
                                                                        (window as any).allowFitBounds = true;
                                                                        if (values[0] <= 0 || values[0] > 300) {
                                                                            return;
                                                                        }
                                                                        setUser({...user, maxDistance: values[0]});
                                                                        setMapRadius(values[0] * 1609.34);
                                                                        setShowSliderHelpGuide(false);
                                                                        setTimeout(() => {
                                                                            (window as any).allowFitBounds = false;
                                                                        }, 300)
                                                                    }}
                                                                    renderTrack={({ props, children }) => (
                                                                        <div
                                                                        onMouseDown={props.onMouseDown}
                                                                        onTouchStart={props.onTouchStart}
                                                                        style={{
                                                                            height: '36px',
                                                                            display: 'flex',
                                                                            width: '100%',
                                                                            borderRadius: 52,
                                                                            cursor: 'pointer',
                                                                        }}
                                                                        >
                                                                        <div
                                                                            ref={props.ref}
                                                                            style={{
                                                                            height: '0px',
                                                                            width: '100%',
                                                                            border: '4px solid #333',
                                                                            borderRadius: '52px',
                                                                            background: getTrackBackground({
                                                                                values: [user.maxDistance],
                                                                                colors: ["white", "white"],
                                                                                min: MIN,
                                                                                max: MAX
                                                                            }),
                                                                            alignSelf: 'center'
                                                                            }}
                                                                        >
                                                                            {children}
                                                                        </div>
                                                                        </div>
                                                                    )}
                                                                    renderThumb={({ props, index, isDragged }) => (
                                                                        <React.Fragment>
                                                                            { (showSliderHelpGuide) &&
                                                                                <React.Fragment>
                                                                                    <div className='prompt-arrow'>
                                                                                        <IonIcon icon={caretBack} />
                                                                                    </div>
                                                                                    <div className='prompt-arrow'>
                                                                                        <IonIcon icon={caretForward} />
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            }
                                                                            <div
                                                                                {...props}
                                                                                style={{
                                                                                    height: '30px',
                                                                                    width: '60px',
                                                                                    borderRadius: '52px',
                                                                                    backgroundColor: '#333',
                                                                                    color: 'white',
                                                                                    display: 'flex',
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center',
                                                                                    boxShadow: '0px 2px 6px #AAA'
                                                                                }}
                                                                            >   
                                                                                {user.maxDistance}mi
                                                                            </div>
                                                                        </React.Fragment>
                                                                    )}
                                                                />
                                                            }
                                                            </div>
                                                            <button className='main' onClick={() => {
                                                                setLoadingMsg("Setting your travel distance");
                                                                UserAPI.update({
                                                                    maxDistance: user.maxDistance,
                                                                    latitude: mapCenter.lat,
                                                                    longitude: mapCenter.lng,
                                                                }, true).then((data) => {
                                                                    reloadLocalData(undefined, data, "Travel Distance Updated");
                                                                    InternalTracker.trackEvent("Max Distance Updated");
                                                                }).catch(e => {
                                                                    console.error(e)
                                                                    setLoadingMsg(null);
                                                                    (window as any).toast("Failed to update your travel distance, please try again later", "error")
                                                                });
                                                            }}>Set Distance ({user.maxDistance}mi/{Math.round(user.maxDistance*1.609)}km)</button>
                                                        </React.Fragment>
                                                    </div>
                                                </section>
                                            : (step.id === "contacts" || step.id === "agencies") ?
                                                <section data-section="contacts">
                                                    <header>
                                                        <p>{step.description}</p>
                                                    </header>
                                                    <div className='center'>
                                                        <div>
                                                            { (agenciesList.length !== 0 || contactsList.length !== 0) &&
                                                                <div className='contacts'>
                                                                    <h4>
                                                                        Contacts you share your availability with
                                                                        { (step.id === "agencies") &&
                                                                            <label>Contacts Confirmed</label>
                                                                        }
                                                                        { (step.id === "contacts" && contactsList.length === 0) &&
                                                                            <label className='red'>No Contacts Selected Yet</label>
                                                                        }
                                                                    </h4>
                                                                    <div className="list" style={ step.id === "agencies" ? { opacity: 0.4 } : { } }>
                                                                        { contactsList.map((contact, i) => {
                                                                            return (
                                                                                <div>
                                                                                    <img className="large" src={contact.userId ? UserAPI.getProfilePicture(contact.userId) : contact.organisationId ? UserAPI.getOrgPicture(contact.organisationId) : "https://test-images.updatedge.com/images/profile/undefined.png"} />
                                                                                    { (contact.userId && contact.organisationId) &&
                                                                                        <img className='small' src={UserAPI.getOrgPicture(contact.organisationId)} />
                                                                                    }
                                                                                    <p>{contact.firstName} {contact.lastName}</p>
                                                                                    { (contact.organisationName && contact.firstName !== contact.organisationName) &&
                                                                                        <label>{contact.organisationName}</label>
                                                                                    }
                                                                                    { (contact.addedByEmail || contact.addedByDiscover) && 
                                                                                        <span>Invited You</span>
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        }) }
                                                                    </div>
                                                                </div>
                                                        }
                                                        { (agenciesList.length !== 0 || contactsList.length !== 0) &&
                                                                <div className='contacts'>
                                                                    <h4>
                                                                        Agencies you are represented by
                                                                        { (step.id === "contacts") &&
                                                                            <label>You can add further agencies at the next step</label>
                                                                        }
                                                                    </h4>
                                                                    <div className="list" style={ step.id === "contacts" ? { opacity: 0.4 } : { } }>
                                                                        { agenciesList.map((contact, i) => {
                                                                            return (
                                                                                <div>
                                                                                    <img className="large" src={contact.userId ? UserAPI.getProfilePicture(contact.userId) : contact.organisationId ? UserAPI.getOrgPicture(contact.organisationId) : "https://test-images.updatedge.com/images/profile/undefined.png"} />
                                                                                    { (contact.userId && contact.organisationId) &&
                                                                                        <img className='small' src={UserAPI.getOrgPicture(contact.organisationId)} />
                                                                                    }
                                                                                    { (contact.firstName || contact.lastName) &&
                                                                                        <p>{contact.firstName} {contact.lastName}</p>
                                                                                    }
                                                                                    { (contact.organisationName && contact.firstName !== contact.organisationName) &&
                                                                                        <label>{contact.organisationName}</label>
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        }) }
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='bottom-options' style={BOTTOM_OPTIONS_STYLE}>
                                                        { (notchBottom && !keyboardOpen) ?
                                                            <div className='notch-filler'></div>
                                                        : null }
                                                        <button className='main' onClick={() => {
                                                            setContactSearchModal("contact");
                                                        }}>{step.id === "contacts" ? (contactsList.length === 0 ? "Add First Contact" : "Add Another Contact") : (agenciesList.length === 0 ? "Add First Agency" : "Add Another Agency") }</button>
                                                        { ((step.id === "contacts" && contactsList.length !== 0) || step.id === "agencies") &&
                                                            <button onClick={() => {
                                                                setLoadingMsg("Saving your " + (step.id === "contacts" ? "contacts" : "agencies") + "...");
                                                                UserAPI.updateSetting(step.id === "contacts" ? "43" : "44", "true").then(() => {
                                                                    reloadLocalData(undefined, undefined, (step.id === "contacts" ? "Contacts" : "Agencies") + " Saved");
                                                                    InternalTracker.trackEvent((step.id === "contacts" ? "Contacts" : "Agencies") + " Updated");
                                                                    setLoadingMsg(null);
                                                                }).catch(e => {
                                                                    console.error(e)
                                                                    setLoadingMsg(null);
                                                                    (window as any).toast("Failed to update your " + (step.id === "contacts" ? "contacts" : "agencies") + ", please try again later", "error")
                                                                })
                                                            }}>
                                                                { step.id === "contacts" ? "I have added all my " + (step.id) : agenciesList.length === 0 ? "I don't have any agencies" : ("I have added all my " + step.id) }
                                                            </button>
                                                        }
                                                    </div>
                                                    { (currentStepObj && (currentStepObj.id === "contacts" || currentStepObj.id === "agencies")) &&
                                                        <IonModal
                                                            isOpen={contactSearchModal !== null}
                                                            canDismiss={contactSearchModal === null}
                                                            presentingElement={(contentRef && contentRef.current) ? contentRef.current : null}
                                                            onDidDismiss={() => { 
                                                                setContactSearchModal(null);
                                                            }}
                                                            data-modal="contacts-page"
                                                            mode="ios"
                                                        >
                                                            <Contacts 
                                                                modal={contactSearchModal}
                                                                closeModal={() => {
                                                                    setContactSearchModal(null);
                                                                    reloadConacts();
                                                                }}
                                                            />
                                                        </IonModal>
                                                    }
                                                </section>
                                            : (step.id === "qualifications") ?
                                                <section data-section="qualifications">
                                                    <header>
                                                        <p>{step.description}</p>
                                                    </header>
                                                    <div className='center'>
                                                        { (user.qualifications && user.qualifications.length !== 0) ?   
                                                            <div className='qualifications'>
                                                                <div className='list'>
                                                                    { user.qualifications.map((qualification, i) => {
                                                                        let PFormatted = "";
                                                                        let H3Formatted = "Empty Education";
                                                                        if (qualification.field) {
                                                                            H3Formatted = qualification.field;
                                                                            if (qualification.school) {
                                                                                H3Formatted += " (" + qualification.school + ")"
                                                                            }
                                                                        }
                                                                        if (qualification.degree || qualification.grade || qualification.endDate || qualification.description) {
                                                                            if (qualification.degree) PFormatted += " · " + qualification.degree;
                                                                            if (qualification.grade) PFormatted += " · " + qualification.grade;
                                                                            if (qualification.endDate && qualification.endDate.split("-").length === 2) {
                                                                                const month = parseInt(qualification.endDate.split("-")[1]);
                                                                                const shortMonth = month === 1 ? "Jan" : month === 2 ? "Feb" : month === 3 ? "Mar" : month === 4 ? "Apr" : month === 5 ? "May" : month === 6 ? "Jun" : month === 7 ? "Jul" : month === 8 ? "Aug" : month === 9 ? "Sep" : month === 10 ? "Oct" : month === 11 ? "Nov" : "Dec"
                                                                                PFormatted += " · " + shortMonth + " " + qualification.endDate.split("-")[0];
                                                                            }
                                                                            if (qualification.description) PFormatted += " · " + qualification.description;
                                                                            PFormatted = PFormatted.substr(2, PFormatted.length-1)
                                                                        }
                                                                        return (
                                                                            <div 
                                                                                className="qualification-item"
                                                                            >
                                                                                <span>{(i+1)}</span>
                                                                                <div className="details-preview">
                                                                                    { H3Formatted && <h3>{H3Formatted}</h3> }
                                                                                    { PFormatted && <p>{PFormatted}</p> }
                                                                                </div>
                                                                                <span className='options'>
                                                                                    <IonIcon 
                                                                                        onClick={() => {
                                                                                            setEditedQualificationI(i);
                                                                                        }} 
                                                                                        icon={createSharp}
                                                                                    />
                                                                                    <IonIcon 
                                                                                        onClick={() => {
                                                                                            let newQualifications = user.qualifications || [];
                                                                                            newQualifications.splice(i, 1);
                                                                                            setUser({...user, qualifications: newQualifications});
                                                                                            saveLocalChanges("qualifications", newQualifications);
                                                                                        }}
                                                                                        icon={trashSharp}
                                                                                    />
                                                                                </span>
                                                                            </div>
                                                                        )
                                                                    }) }
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className='qualification-suggestions'>
                                                                { Object.keys(QUALIFICATION_ABRV_DEGREE_MAPPING).map((degree, i) => {
                                                                    return (
                                                                        <div 
                                                                            onClick={() => {
                                                                                let newQualifications = user.qualifications || [];
                                                                                newQualifications.push({
                                                                                    id: 0,
                                                                                    school: "",
                                                                                    field: "",
                                                                                    degree: degree === "Custom Qualification" ? "" : QUALIFICATION_ABRV_DEGREE_MAPPING[degree],
                                                                                    startDate: "",
                                                                                    endDate: "",
                                                                                    startYear: "",
                                                                                    startMonth: "",
                                                                                    endYear: "",
                                                                                    endMonth: "",
                                                                                    startDateTimestamp: 0,
                                                                                    endDateTimestamp: 0,
                                                                                    grade: "",
                                                                                    description: ""
                                                                                });
                                                                                setUser({...user, qualifications: newQualifications});
                                                                                setEditedQualificationI(newQualifications.length - 1);
                                                                                autoFocusSchoolField();
                                                                            }}
                                                                        >
                                                                            {degree}
                                                                        </div>
                                                                    )
                                                                }) }
                                                            </div>
                                                        }
                                                        <IonModal
                                                            isOpen={editedQualificationI !== null}
                                                            canDismiss={editedQualificationI === null}
                                                            presentingElement={(contentRef && contentRef.current) ? contentRef.current : null}
                                                            onDidDismiss={() => { 
                                                                let item = user.qualifications[editedQualificationI];
                                                                if (item && (!parseInt(item.endYear) || !parseInt(item.endMonth) || parseInt(item.endMonth) < 1 || parseInt(item.endMonth) > 12 || !item.school || !item.field)) {
                                                                    let newQualifications = user.qualifications || [];
                                                                    newQualifications.splice(editedQualificationI, 1);
                                                                    setUser({...user, qualifications: newQualifications});
                                                                    saveLocalChanges("qualifications", newQualifications);
                                                                }
                                                                
                                                                setEditedQualificationI(null);
                                                            }}
                                                            data-modal="q-page"
                                                            mode="ios"
                                                        >
                                                            { (editedQualification !== null) &&
                                                                <div className="qualification">
                                                                    <h2>
                                                                        Edit Qualification
                                                                        <IonIcon 
                                                                            onClick={() => {
                                                                                let item = user.qualifications[editedQualificationI];
                                                                                if (item && (!parseInt(item.endYear) || !parseInt(item.endMonth) || parseInt(item.endMonth) < 1 || parseInt(item.endMonth) > 12 || !item.school || !item.field)) {
                                                                                    let newQualifications = user.qualifications || [];
                                                                                    newQualifications.splice(editedQualificationI, 1);
                                                                                    setUser({...user, qualifications: newQualifications});
                                                                                    saveLocalChanges("qualifications", newQualifications);
                                                                                }
                                                                                
                                                                                setEditedQualificationI(null);
                                                                            }} 
                                                                            icon={closeSharp}
                                                                        />
                                                                    </h2>
                                                                    { (keyboardOpen) &&
                                                                        <div className="keyboard-hide">
                                                                            <IonIcon icon={caretDownCircle} />
                                                                        </div>
                                                                    }
                                                                    <div className="details">
                                                                        {[
                                                                            { field: "school", suggestions: "SCHOOLS", label: "School / University *", mandatory: true },
                                                                            { field: "field", suggestions: "FIELDS", label: "Field / Subject *", mandatory: true },
                                                                            { field: "grade", suggestions: "GRADES", label: "Grade" },
                                                                            { field: "degree", suggestions: "DEGREES", label: "Degree" },
                                                                            { field: "date", label: "Award Date *" },
                                                                            { field: "description", label: "Description" },
                                                                        ].map(qualificationField => { 
                                                                            return (
                                                                                <div className="field">
                                                                                    <div className="field-head">
                                                                                        { (qualificationField.field === "school" || qualificationField.field === "date" || qualificationField.field === "grade" || qualificationField.field === "field" || qualificationField.field === "degree" || qualificationField.field === "description") &&
                                                                                            <h3>{qualificationField.label}</h3>
                                                                                        }
                                                                                        { (qualificationField.field === "school" || qualificationField.field === "grade" || qualificationField.field === "field" || qualificationField.field === "degree" || qualificationField.field === "description") &&
                                                                                            <IonInput
                                                                                                placeholder={qualificationField.label} 
                                                                                                id={editedQualificationI + "-" + qualificationField.field}
                                                                                                className={qualificationField.field === "school" ? "school-qualification-input" : ""}
                                                                                                value={editedQualification[qualificationField.field]} 
                                                                                                onIonInput={(e) => {
                                                                                                    const newValue: string = qualificationField.field === "school" ? Utilities.capitalizeEachFirstLetter((e.target as HTMLIonInputElement).value.toString()) : Utilities.capitalizeFirstLetter((e.target as HTMLIonInputElement).value.toString())
                                                                                                    updateQualification(user, editedQualificationI, qualificationField.field, newValue);
                                                                                                    setQualificationSuggestionQuery(newValue);
                                                                                                }}
                                                                                                tabIndex={6}
                                                                                                onFocus={() => {
                                                                                                    setTimeout(() => {
                                                                                                        setFocusedField(qualificationField.field);
                                                                                                    }, 450)
                                                                                                }}
                                                                                                onBlur={() => {  }}
                                                                                                style={{
                                                                                                    background: ((qualificationField.field === "school" || qualificationField.field === "field") && !editedQualification[qualificationField.field]) ? "#FFC4C4" : undefined
                                                                                                }}
                                                                                            />
                                                                                        }
                                                                                        { (qualificationField.field === "date") &&
                                                                                            <div className='ion-input-group'>
                                                                                                <IonInput type='text' style={{ width: 'calc(50% - 0px)', background: !editedQualification.endMonth ? "#FFC4C4" : undefined }} onIonInput={(e) => { updateQualification(user, editedQualificationI, "endMonth", (e.target as HTMLIonInputElement).value.toString()); }}  placeholder="MM *" value={editedQualification.endMonth} />
                                                                                                <IonInput type='text' style={{ width: 'calc(50% - 0px)', margin: '0 0 0 8px', background: !editedQualification.endYear ? "#FFC4C4" : undefined }} onIonInput={(e) => { updateQualification(user, editedQualificationI, "endYear", (e.target as HTMLIonInputElement).value.toString()); }} placeholder="YYYY *" value={editedQualification.endYear} />
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                    { (qualificationField.suggestions && focusedField === qualificationField.field && SUGGESTIONS[qualificationField.suggestions].find(item => item.indexOf(qualificationSuggestionQuery) !== -1)) &&
                                                                                        <div
                                                                                            className="section-content-2"
                                                                                            style={{
                                                                                                padding: "0 0 0 0",
                                                                                                marginBottom: -4
                                                                                            }}
                                                                                        >
                                                                                            <div className="suggestions">
                                                                                                { SUGGESTIONS[qualificationField.suggestions].map(item => {
                                                                                                    if (item.indexOf(qualificationSuggestionQuery) === -1) return null;
                                                                                                    return (
                                                                                                        <div key={item} onClick={() => {
                                                                                                            updateQualification(user, editedQualificationI, qualificationField.field, item);
                                                                                                            setTimeout(() => {
                                                                                                                setFocusedField(null);
                                                                                                                setQualificationSuggestionQuery("");
                                                                                                            }, 200)
                                                                                                        }}>
                                                                                                            {item}
                                                                                                        </div>
                                                                                                    )
                                                                                                }) }
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                    <button className='main' style={{ marginTop: 10 }} onClick={() => {
                                                                        InternalTracker.trackEvent("Qualification Added");
                                                                        let newQualifications = user.qualifications || [];
                                                                        let item = newQualifications[editedQualificationI];
                                                                        let error = null;

                                                                        if ( !parseInt(item.endYear) || !parseInt(item.endMonth) || parseInt(item.endMonth) < 1 || parseInt(item.endMonth) > 12) {
                                                                            error = "Invalid Date";
                                                                        }
                                
                                                                        if (!item.school || !item.field) {
                                                                            error = "School / University, and Field / Subject is required.";
                                                                        }
                                                                    
                                                                        if (error) {
                                                                            (window as any).toast(error, "error")
                                                                            return;
                                                                        }

                                                                        if ((item.startYear && item.startMonth) || (item.endYear && item.endMonth)) {
                                                                            if (item.endYear && item.endMonth) {
                                                                                if (!parseInt(item.endYear) || !parseInt(item.endMonth) || parseInt(item.endMonth) < 1 || parseInt(item.endMonth) > 12) {
                                                                                    
                                                                                } else {
                                                                                    let end = new Date();
                                                                                    end.setFullYear(parseInt(item.endYear));
                                                                                    end.setMonth(parseInt(item.endMonth+1), 15);
                                                                                    if (end) {
                                                                                        item.endDate = item.endYear + "-" + item.endMonth;
                                                                                        item.endDateTimestamp = end.getTime();
                                                                                    }
                                                                                }
                                                                            }

                                                                            newQualifications[editedQualificationI] = item;

                                                                            setUser({
                                                                                ...user,
                                                                                qualifications: newQualifications
                                                                            });
                                                                            saveLocalChanges("qualifications", newQualifications);
                                                                        }

                                                                        setEditedQualificationI(null)
                                                                    }}>Save</button>
                                                                </div>
                                                            }
                                                        </IonModal>
                                                    </div>
                                                    <div className='bottom-options' style={BOTTOM_OPTIONS_STYLE}>
                                                        <button className='main' onClick={() => {
                                                            let newQualifications = user.qualifications || [];
                                                            newQualifications.push({
                                                                id: 0,
                                                                school: "",
                                                                field: "",
                                                                degree: "",
                                                                startDate: "",
                                                                endDate: "",
                                                                startYear: "",
                                                                startMonth: "",
                                                                endYear: "",
                                                                endMonth: "",
                                                                startDateTimestamp: 0,
                                                                endDateTimestamp: 0,
                                                                grade: "",
                                                                description: ""
                                                            });
                                                            setUser({ ...user, qualifications: newQualifications });
                                                            setEditedQualificationI(newQualifications.length-1)
                                                            autoFocusSchoolField();
                                                        }}>Add New Qualification</button>
                                                        <button onClick={() => {

                                                            if (user.qualifications.length === 0) {
                                                                setLoadingMsg("Saving your qualifications...");
                                                                UserAPI.updateSetting("47", "true").then(() => {
                                                                    reloadLocalData(undefined, undefined, "Qualifications Saved");
                                                                    InternalTracker.trackEvent("Qualifications Updated");
                                                                    setLoadingMsg(null);
                                                                }).catch(e => {
                                                                    console.error(e)
                                                                    setLoadingMsg(null);
                                                                    (window as any).toast("Failed to update your qualifications, please try again later", "error")
                                                                })
                                                            } else {

                                                                let preparedData = user.qualifications.map(item => {
                                                                    if (item.endYear && item.endMonth) {
                                                                        let end = new Date();
                                                                        end.setFullYear(parseInt(item.endYear));
                                                                        end.setMonth(parseInt(item.endMonth)+1, 15);
                                                                        item.endDate = item.endYear + "-" + item.endMonth;
                                                                        item.endDateTimestamp = end.getTime();
                                                                    }
                                                                    return item;
                                                                })
                            
                                                                setLoadingMsg("Saving Qualifications");
                            
                                                                UserAPI.update({
                                                                    qualifications: preparedData
                                                                } as UserDTO, true).then(data => {
                                                                    reloadLocalData(undefined, data, "Qualifications Saved");
                                                                    InternalTracker.trackEvent("Qualifications Updated");
                                                                    setLoadingMsg(null);
                                                                }).catch(e => {
                                                                    console.error(e)
                                                                    setLoadingMsg(null);
                                                                    (window as any).toast("Failed to update your qualifications, please try again later", "error")
                                                                })
                                                            }
                                                        }}>
                                                            { user.qualifications.length ? "I have added all my Qualifications" : "I don't have any Qualifications" }
                                                        </button>
                                                    </div>
                                                </section>
                                            : (step.id === "smsnotifications") ?
                                                <section data-section="smsnotifications">
                                                    { (keyboardOpen) &&
                                                        <div className="keyboard-hide">
                                                            <IonIcon icon={caretDownCircle} />
                                                        </div>
                                                    }
                                                    <header>
                                                        <p>{step.description}</p>
                                                    </header>
                                                    <div className='center'>
                                                        { (currentSMSSetupStep === 2 && !keyboardOpen) &&
                                                            <div className='sms-verification-info'>
                                                                <img src={SMSLogo} />
                                                                <p>We have sent a code to <span>+{user.phoneNumber}</span></p>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className='bottom-options' style={BOTTOM_OPTIONS_STYLE}>
                                                        { (notchBottom && !keyboardOpen) ?
                                                            <div className='notch-filler'></div>
                                                        : null }
                                                        { (currentSMSSetupStep === 1) ?
                                                            <React.Fragment>
                                                                <h4>Enter your Phone Number</h4>
                                                                { (currentStepObj && currentStepObj.id === "smsnotifications") &&
                                                                    <PhoneInput
                                                                        preferredCountries={["US", "UK"]}
                                                                        enableLongNumbers={true}
                                                                        value={user ? (user.phoneNumber === undefined ? "+44" : !user.phoneNumber ? "" : user.phoneNumber) : "+44"}
                                                                        onChange={(phone) => {
                                                                            setUser({...user, phoneNumber: phone })
                                                                        }}
                                                                    />
                                                                }
                                                                <button className="main" onClick={() => {
                                                                    if (user.phoneNumber.length < 7) {
                                                                        (window as any).toast("Please enter a valid phone number", "error")
                                                                        return;
                                                                    }
                                                                    sendOtpCode(user.phoneNumber);
                                                                    InternalTracker.trackEvent("SMS Notifications Setup Started");
                                                                }}>Verify Number</button>
                                                            </React.Fragment>
                                                            : (currentSMSSetupStep === 2) ?
                                                            <React.Fragment>
                                                                <h4>Verify your Phone Number</h4>
                                                                { (currentStepObj && currentStepObj.id === "smsnotifications") &&
                                                                    <div className='otp-wrapper'>
                                                                        <OtpInput
                                                                            shouldAutoFocus={true}
                                                                            value={otpCode}
                                                                            onChange={(code) => {
                                                                                setOtpCode(code);
                                                                                if (code.length === 6) {
                                                                                    verifyOtpCode(user.phoneNumber, code);
                                                                                }
                                                                            }}
                                                                            numInputs={6}
                                                                            renderSeparator={<span></span>}
                                                                            inputType='number'
                                                                            renderInput={(props) => <input {...props} />}
                                                                        />
                                                                    </div>
                                                                }
                                                                <button onClick={() => {
                                                                    setCurrentSMSSetupStep(3);
                                                                }}>Didn't Receive / Wrong Number</button>
                                                            </React.Fragment>
                                                            : (currentSMSSetupStep === 3) ?
                                                            <React.Fragment>
                                                                <div className='alternative-options'>
                                                                    <button onClick={() => {
                                                                        setCurrentSMSSetupStep(1);
                                                                    }}>
                                                                        <IonIcon icon={createOutline} />
                                                                        <span>Change Number</span>
                                                                    </button>
                                                                    <button onClick={() => {
                                                                        sendOtpCode(user.phoneNumber);
                                                                    }}>
                                                                        <IonIcon icon={sendOutline} />
                                                                        <span>Resend Code</span>
                                                                    </button>
                                                                    {/* <button onClick={() => {
                                                                        localStorage.setItem("forcedSetupSMSNotificationsSkipped", "true");
                                                                        loadRequiredStep();
                                                                        window.open("https://wa.me/36705359591?text=Please Verify my Account: " + user.email + ". [Make sure you send it from this number: " + user.phoneNumber + "]")
                                                                    }}>
                                                                        <IonIcon icon={logoWhatsapp} />
                                                                        <span>Verify with WhatsApp</span>
                                                                    </button> */}
                                                                    <button onClick={() => {
                                                                        localStorage.setItem("forcedSetupSMSNotificationsSkipped", "true");
                                                                        loadRequiredStep();
                                                                        window.open("mailto:support@updatdge.com?subject=Verify my Account&body=Please verify my account: " + user.email + " [" + user.phoneNumber + "]")
                                                                    }}>
                                                                        <IonIcon icon={mailOutline} />
                                                                        <span>Contact us by Email</span>
                                                                    </button>
                                                                    <button onClick={() => {
                                                                        setCurrentSMSSetupStep(2);
                                                                    }}>
                                                                        <IonIcon icon={keypadOutline} />
                                                                        <span>I received the Number</span>
                                                                    </button>
                                                                </div>
                                                            </React.Fragment>
                                                            : null
                                                        }
                                                    </div>
                                                </section>
                                            : (step.id === "pushnotifications") ?
                                                <section data-section="pushnotifications">
                                                    <header>
                                                        <p>{step.description}</p>
                                                    </header>
                                                    <div className='center'>
                                                        { (currentStepObj && currentStepObj.id === "pushnotifications") &&
                                                            <PushNotificationManagement 
                                                                modal={true}
                                                                onClose={() => {
                                                                    loadRequiredStep();
                                                                }}
                                                            />
                                                        }
                                                    </div>
                                                    <div className='bottom-options' style={BOTTOM_OPTIONS_STYLE}>
                                                        { (localStorage.getItem("notificationToken")) ?
                                                            <React.Fragment>
                                                                <label style={{ marginBottom: 20, display: 'block', fontSize: 18 }}>Notification settings can be adjusted from your OS settings</label>
                                                                <button className='main' onClick={() => {
                                                                    loadRequiredStep();
                                                                }}>Next Step</button>
                                                            </React.Fragment>
                                                            :
                                                            <React.Fragment>
                                                                <button className='main' onClick={() => {
                                                                    const btn = document.querySelector("#enable-notification-btn") as HTMLButtonElement
                                                                    if (btn) {
                                                                        btn.click();
                                                                        localStorage.setItem("actionedNotifications", "true");
                                                                    }
                                                                }}>Enable Notifications</button>
                                                                <button onClick={() => {
                                                                    localStorage.setItem("actionedNotifications", "true");
                                                                    localStorage.setItem("notificationToken", "skipped");
                                                                    loadRequiredStep();
                                                                }}>Disable Notifications</button>
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                                </section>
                                            : (step.id === "addevents") ?
                                                <section data-section="addevents">
                                                    <header>
                                                        <p>{step.description}</p>
                                                    </header>
                                                    <div className='center'>
                                                        { (currentStepObj && currentStepObj.id === "addevents") &&
                                                            <Calendar 
                                                                paddingTop={notchTop ? (0) : 55}
                                                                paddingBottom={notchBottom ? notchBottom : 0}
                                                                modal={true}
                                                                onEventsAdded={() => {
                                                                    reloadLocalData(undefined, undefined, "Availability Updated");
                                                                }}
                                                                onModalVisibilityChange={(visible) => {
                                                                    setShowProgressBar(!visible);
                                                                }}
                                                            />
                                                        }
                                                    </div>
                                                </section>
                                            : (step.id === "skills") ?
                                                <section data-section="skills" data-added-one={hideSkillsHeader ? "true" : "false"}>
                                                    { (keyboardOpen) &&
                                                        <div 
                                                            className="keyboard-hide"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                            }}
                                                        >
                                                            <IonIcon icon={caretDownCircle} />
                                                        </div>
                                                    }
                                                    { (!keyboardOpen && user.workerAttributes.length < 2) ?
                                                        <header>
                                                            <p>{hideSkillsHeader ? "Add more skills as required" : step.description}</p>
                                                        </header>
                                                    : null }
                                                    { (showEnhancedSkills && currentStepObj && currentStepObj.id === "skills") &&
                                                        <SkillManagerEnhanced 
                                                            onboarding={true}
                                                            BOTTOM_OPTIONS_STYLE={BOTTOM_OPTIONS_STYLE}
                                                            onAttributesChange={(attributes) => {
                                                                setHideSkillsHeader(attributes.length !== 0);
                                                                setUser({...user, workerAttributes: attributes});
                                                                saveLocalChanges("workerAttributes", attributes);
                                                            }}
                                                            keyboardOpen={keyboardOpen}
                                                            showNotchFiller={notchBottom && !keyboardOpen}
                                                            onSearchQueryChange={(query) => {
                                                                setSkillSuggestionQuery(query);
                                                            }}
                                                            onModalVisibilityChange={(visible) => {
                                                                setSkillsModalVisible(visible);
                                                            }}
                                                            onSectorSelectorModelVisibilityChange={(visible) => {
                                                                setShowSkillsConfirmButton(!visible);
                                                            }}
                                                        />
                                                    }
                                                    
                                                    { (hideSkillsHeader) &&
                                                        <div className='bottom-options' style={BOTTOM_OPTIONS_STYLE}>
                                                            { (notchBottom && !keyboardOpen) ?
                                                                <div className='notch-filler' style={{ backgroundColor: "#3573E6" }}></div>
                                                            : null }
                                                            { (showSkillsConfirmButton && !keyboardOpen) ?
                                                                <button 
                                                                    style={{
                                                                        opacity: skillSuggestionQuery.length === 0 ? 1 : 0.6
                                                                    }}
                                                                    className='main' 
                                                                    onClick={() => {
                                                                        if (skillSuggestionQuery.length !== 0) {
                                                                            alert("Click on skill (" + skillSuggestionQuery + ") to save.");
                                                                            return;
                                                                        }
                                                                        setLoadingMsg("Updating your skills");
                                                                        UserAPI.update({
                                                                            workerAttributes: user.workerAttributes
                                                                        }, true).then((updatedUser) => {
                                                                            reloadLocalData(undefined, updatedUser, "Skills Updated");
                                                                            InternalTracker.trackEvent("Skills Updated");
                                                                        }).catch(e => {
                                                                            console.error(e)
                                                                            setLoadingMsg(null);
                                                                            (window as any).toast("Failed to update your skills, please try again later", "error");
                                                                        })
                                                                    }}>
                                                                    {skillSuggestionQuery.length !== 0 ? ("Skill \"" + skillSuggestionQuery + "\" Unsaved") : ("Continue with " + user.workerAttributes.length + " skill" + (user.workerAttributes.length > 1 ? "s" : ""))}
                                                                </button>
                                                            : null }
                                                        </div>
                                                    }
                                                </section>
                                            : (step.id === "completed") ? 
                                                <section data-section="completed">
                                                    <header>
                                                        <header>
                                                            <p>{step.description}</p>
                                                        </header>
                                                    </header>
                                                    <div className='center'>
                                                        <div className='profile'>
                                                            <img src={UserAPI.getProfilePicture(user ? user.id : "undefined", user.userSetProfileImage ? 6 : 7)} />
                                                            <h3>{user.firstName + " " + user.lastName}</h3>
                                                            <p>{user.headline}</p>
                                                            <div className='skills'>
                                                                { user.workerAttributes.map(skill => {
                                                                    return (
                                                                        <span className='skill'>
                                                                            {skill.skillName}
                                                                        </span>
                                                                    )
                                                                }) }
                                                                { user.qualifications.map(qualification => {
                                                                    if (qualification.degree && qualification.school) {
                                                                        return (
                                                                            <span className='skill'>
                                                                                {qualification.degree} @ {qualification.school}
                                                                            </span>
                                                                        )
                                                                    }
                                                                    return null;
                                                                }) }
                                                            </div>
                                                        </div>
                                                        <div className='tutorial-wrapper'>
                                                            <div className='tutorial'>
                                                                <img src={KeepProfileUpdatedImg} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='bottom-options' style={BOTTOM_OPTIONS_STYLE}>
                                                        <button className='main' onClick={ async () => {
                                                            setLoadingMsg("Finishing up...");

                                                            if (!localStorage.getItem("adminVerificationRequestSent")) {
                                                                localStorage.setItem("adminVerificationRequestSent", "true");
                                                                await UserAPI.sendVerificationRequest(user.id);
                                                            }

                                                            UserAPI.updateSetting("48", "true").then(() => {
                                                                reloadLocalData();
                                                                InternalTracker.trackEvent("Onboarding Completed");
                                                                setLoadingMsg(null);
                                                            }).catch(e => {
                                                                console.error(e)
                                                                setLoadingMsg(null);
                                                                (window as any).toast("Failed to complete your onboarding, please try again later", "error")
                                                            })
                                                        }}>Next</button>
                                                    </div>
                                                </section>
                                            : (step.id === "publicprofile") ?
                                                <section data-section="publicprofile">
                                                    <header>
                                                    </header>
                                                    <div className='center'>
                                                        <div className='profile-wrapper'>
                                                            <div className='inner'>
                                                                <img className='main' src={UserAPI.getProfilePicture(user.id, user.userSetProfileImage ? 8 : 9)} />
                                                                <label className='best-match'>Best Match</label>
                                                                { (user.postalCode) &&
                                                                    <label className='postcode'>{user.postalCode.substring(0, 3)}</label>
                                                                }
                                                                { (user.workerAttributes && user.workerAttributes.length !== 0) &&
                                                                    <label className='skill'>{user.workerAttributes[0].skillName}</label>
                                                                }
                                                                <img src={Avatar1} className='avatar' data-avatar="1" />
                                                                <img src={Avatar2} className='avatar' data-avatar="2" />
                                                                <img src={Avatar3} className='avatar' data-avatar="3" />
                                                                <img src={Avatar4} className='avatar' data-avatar="4" />
                                                                <img src={Avatar5} className='avatar' data-avatar="5" />
                                                                <img src={Avatar6} className='avatar' data-avatar="6" />
                                                                <img src={Avatar11} className='avatar' data-avatar="7" />
                                                                <img src={Avatar12} className='avatar' data-avatar="8" />
                                                                <img src={Avatar13} className='avatar' data-avatar="9" />
                                                                <img src={Avatar14} className='avatar' data-avatar="10" />
                                                                <img src={Avatar15} className='avatar' data-avatar="11" />
                                                                <img src={Avatar16} className='avatar' data-avatar="12" />
                                                            </div>
                                                        </div>
                                                        { (currentStepObj && currentStepObj.id === "publicprofile") &&
                                                            <DynamicMap center={mapCenter} radius={mapRadius} />
                                                        }
                                                    </div>
                                                    <div className='bottom-options' style={BOTTOM_OPTIONS_STYLE}>
                                                        <button onClick={() => {
                                                            setLoadingMsg("Saving your preferences");
                                                            UserAPI.updateSetting("49", "false").then(() => {
                                                                reloadLocalData(undefined, undefined, "Profile Set to Private");
                                                                InternalTracker.trackEvent("Set profile to private");
                                                                setLoadingMsg(null);
                                                            }).catch(e => {
                                                                console.error(e)
                                                                setLoadingMsg(null);
                                                                (window as any).toast("Failed to update your preferences, please try again later", "error")
                                                            })
                                                        }}>
                                                            Only your contacts see your details
                                                        </button>
                                                        <div className='flex'>
                                                            <button className='main' onClick={() => {
                                                                setLoadingMsg("Saving your preferences");
                                                                UserAPI.updateSetting("49", "true").then(() => {
                                                                    reloadLocalData(undefined, undefined, "Profile Set to Public");
                                                                    InternalTracker.trackEvent("Set profile to public");
                                                                    setLoadingMsg(null);
                                                                }).catch(e => {
                                                                    console.error(e)
                                                                    setLoadingMsg(null);
                                                                    (window as any).toast("Failed to update your preferences, please try again later", "error")
                                                                })
                                                            }}>
                                                                Show my skills to more local employers
                                                            </button>
                                                            <button onClick={() => {
                                                                setPublicProfileMessage(true)
                                                            }}>
                                                                <IonIcon icon={helpCircle} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </section>
                                            :
                                                <section>
                                                    {slideI} content
                                                </section>
                                            }
                                        </div>
                                    </div>
                                </Slide>
                            )
                        })}
                    </Swiper>
                }
                <IonLoading
                    isOpen={loadingMsg !== null}
                    onDidDismiss={() => setLoadingMsg(null)}
                    message={loadingMsg}
                    duration={3000000}
                />
                <IonAlert
                    isOpen={confirmSkip !== null}
                    onDidDismiss={() => { setConfirmSkip(null) }}
                    header={"Are you sure?"} 
                    subHeader={(SKIP_MESSAGES[confirmSkip] && SKIP_MESSAGES[confirmSkip].subheader) ? SKIP_MESSAGES[confirmSkip].subheader : null}
                    message={SKIP_MESSAGES[confirmSkip] ? (alreadySkipped ? SKIP_MESSAGES[confirmSkip].bodySecond : SKIP_MESSAGES[confirmSkip].bodyFirst) : null} 
                    buttons={[
                        {
                            text: 'Skip',
                            handler: () => {
                                const unsavedChanges = JSON.parse(localStorage.getItem("unsavedLocalUserChanges") || "{}");
                                // reseting fields that hasn't been saved, so can go back after all skips
                                const newUser = { ...user };
                                if (confirmSkip === "headline" && user.headline) {
                                    // setUser({ ...user, headline: "" });
                                    newUser.headline = "";
                                } else if (confirmSkip === "skills" && user.workerAttributes && user.workerAttributes.length !== 0) {
                                    // setUser({ ...user, workerAttributes: [] });
                                    newUser.workerAttributes = [];
                                } else if (confirmSkip === "qualifications" && user.qualifications && user.qualifications.length !== 0) {
                                    // setUser({ ...user, qualifications: [] });
                                    newUser.qualifications = [];
                                }
                                localStorage.setItem("user", JSON.stringify(newUser));
                                setUser(newUser);
                                const softSkippedAllTime = JSON.parse(localStorage.getItem("softskippedSections") || "[]");
                                localStorage.setItem("softskippedSections", JSON.stringify([...softSkippedAllTime, confirmSkip]));
                                localStorage.setItem("softskippedSectionsAllTime", JSON.stringify([...softSkippedAllTime, confirmSkip]));
                                loadRequiredStep();
                            }
                        },
                        {
                            text: 'Close',
                            handler: () => { 
                                setConfirmSkip(null);
                            }
                        }
                    ]}
                />
                <IonAlert
                    isOpen={publicProfileMessage}
                    onDidDismiss={() => { setPublicProfileMessage(false) }}
                    header={"Showcase your skills to more local employers for more opportunities."} 
                    buttons={[
                        {
                            text: 'Close',
                            handler: () => { 
                                setPublicProfileMessage(false);
                            }
                        }
                    ]}
                />
                { confetti &&
                    <Confetti
                        recycle={false}
                        numberOfPieces={100}
                        onConfettiComplete={() => {
                            setConfetti(false)
                        }}
                        tweenDuration={1}
                        confettiSource={{
                            x: window.innerWidth / 2 - 60, y: window.innerHeight / 2,
                            w: 200, h: 200
                        }}
                    />
                }
            </div>
        )
    }

    return null;
    
};

export default OnboardingPreferences;
