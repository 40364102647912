import { Plugins } from '@capacitor/core';
import { IonActionSheet, IonContent, IonFooter, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar, isPlatform, useIonViewDidLeave, useIonViewWillEnter } from '@ionic/react';
import { AuthActionBuilder, AuthObserver } from 'ionic-appauth';
import { openOutline } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import UserAPI from '../apis/User';
import AuthOwn from '../Auth';
import Configuration from '../Configuration';
import AppleStoreImg from '../images/apple-store.png';
import GooglePlayImg from '../images/google-play.png';
import OnboardingHero from '../images/onboarding-splash.png';
import OnboardingHeroFull from '../images/onboarding-splash-full.png';
import { Auth } from '../services/AuthService';
import Utilities from '../Utilities';
import { Device } from '@capacitor/device';
import Logo from '../images/icon.svg'
import { Slide, Swiper } from 'react-dynamic-swiper';
import IntroImg1 from '../images/intro-1.png';
import IntroImg2 from '../images/intro-2.png';
import IntroImg3 from '../images/intro-3.png';

interface LandingPageProps extends RouteComponentProps {}

const Onboarding : React.FC<LandingPageProps> = (props: LandingPageProps) => {

    const [envSelectorCount, setEnvSelectorCount] = useState <number> (0) // Number of times the image is clicked
    const [envSelector, setEnvSelector] = useState <boolean> (false) // Whether the env selector is open
    const [action, setAction] = useState(AuthActionBuilder.Default);
    const [loginPending, setLoginPending] = useState <boolean> (false)
    const [isNativeApp, setIsNativeApp] = useState <boolean> (false) //Whether the app is running as an installed app
    const swiperRef = useRef(null);
    
    let observer: AuthObserver;
    const mobileAuth = (!isPlatform("mobileweb") && ( isPlatform("android") || isPlatform("ios") ));

    useIonViewWillEnter(() => {
        Auth.Instance.loadTokenFromStorage();
        observer = Auth.Instance.addActionListener((action) => {
            setAction(action);
            if (action && action.action === "Sign Out Success") {
                setTimeout(() => {
                    handleSignIn();
                }, 250)
            } else if (action && action.tokenResponse && action.tokenResponse.issuedAt + action.tokenResponse.expiresIn < (new Date().getTime()/1000)) {
                Auth.Instance.signOut();
            } else if (action && action.tokenResponse && action.tokenResponse.accessToken) {
                setLoginPending(false);
                localStorage.setItem("access_token", action.tokenResponse.accessToken);
                localStorage.setItem("refresh_token", action.tokenResponse.refreshToken);
                window.location.href = "/";
            }
        });
        if (window.location.href.indexOf("/auto") !== -1) {
            setTimeout(function() {
                handleSignIn()
            }, 250)
        }
        // setIsNativeApp(!isPlatform("mobileweb") && !isPlatform("desktop"));
        setIsNativeApp(isPlatform("android") || isPlatform("ios"));
    });

    useIonViewDidLeave(() => {
        Auth.Instance.removeActionObserver(observer);
    });
 
    const handleSignIn = () => {
        if (mobileAuth) {
            Auth.Instance.signIn();
            setLoginPending(true);
            setTimeout(() => {
                setLoginPending(false);
            }, 5000)
        } else {
            signInDesktop();
        }
    }

    useEffect(() => {
        // Hide tabs
        let elements = document.getElementsByTagName("ion-tab-bar");
        if (elements && elements[0]) {
            elements[0].style.display = "none"
        }
    }, []);

    const setEnv = function(env: string): void {
        localStorage.setItem("env", env);
        window.location.href = "/";
    }

    const signInDesktop = async function(): Promise<void> {

        const info = await Device.getInfo();
        const CONFIG = Configuration[localStorage.getItem("env")];

        AuthOwn.registerProfiles(
            CONFIG["AUTH_APP_ID"],
            CONFIG["AUTH_MOBILE_SCOPE"],
            CONFIG["AUTH_POLICY_NAME"],
            CONFIG["AUTH_TENANT_NAME"],
            (info.platform === "web") ? (`${window.location.origin}/redirect`) : (CONFIG["BUNDLE_ID"] + "://login")
        );
        
        console.log(CONFIG["AUTH_APP_ID"],
        CONFIG["AUTH_MOBILE_SCOPE"],
        CONFIG["AUTH_POLICY_NAME"],
        CONFIG["AUTH_TENANT_NAME"],
        (info.platform === "web") ? (`${window.location.origin}/redirect`) : (CONFIG["BUNDLE_ID"] + "://login"))

        AuthOwn.login();  

    }

    return (
        <IonPage data-page="onboarding">
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Welcome to Updatedge</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent style={{ '--background': '#17333F' }}>
            { (localStorage.getItem("env") !== "prod") &&
                <div className="testing-warning" onClick={() => {
                    setEnv("prod")
                }}>
                    This version of the app is only for testing purposes, it is completely separate from the live environment. Tap to switch to live.
                </div>
            }
            <div 
                className="swiper-wrapper-outer"
            >
                <Swiper
                    swiperOptions={{
                        slidesPerView: 'auto',
                        direction: 'horizontal',
                        noSwipingClass: 'no-swipe',
                        noSwiping: true
                    }}
                    ref={swiperRef}
                    onTransitionEnd={(swiper) => {
                        console.log('swiped to: ' + swiperRef.current.swiper().activeIndex);
                    }}
                    style={{ }}
                >
                    <Slide className="" data-i="1">
                        <div className='slide-wrapper'>
                            <div 
                                className='slide-content'
                                style={{ }}
                            >
                                <section>
                                    <div className='showcase'>
                                        <div className='preview-img'>
                                            <div className='notch'></div>
                                            <img src={IntroImg1} />
                                        </div>
                                        <h2>Easily Update your Availability</h2>
                                    </div>
                                    <button onClick={() => {
                                        swiperRef.current.swiper().slideTo(1, 300);
                                        setTimeout(() => {
                                            window.dispatchEvent(new Event('resize'));
                                        }, 500)
                                    }}>Next</button>
                                </section>
                            </div>
                        </div>  
                    </Slide>
                    <Slide className="" data-i="2">
                        <div className='slide-wrapper'>
                            <div 
                                className='slide-content'
                                style={{ }}
                            >
                                 <section>
                                    <div className='showcase'>
                                        <div className='preview-img'>
                                            <div className='notch'></div>
                                            <img style={{ marginTop: -32 }} src={IntroImg2} />
                                        </div>
                                        <h2>Share with All your Contacts</h2>
                                    </div>
                                    <button onClick={() => {
                                        swiperRef.current.swiper().slideTo(2, 300);
                                        setTimeout(() => {
                                            window.dispatchEvent(new Event('resize'));
                                        }, 500)
                                    }}>Next</button>
                                </section>
                            </div>
                        </div>  
                    </Slide>
                    <Slide className="" data-i="3">
                        <div className='slide-wrapper'>
                            <div 
                                className='slide-content'
                                style={{ }}
                            >
                                <section>
                                    <div className='showcase'>
                                        <div className='preview-img'>
                                            <div className='notch'></div>
                                            <img style={{ marginTop: -32 }} src={IntroImg3} />
                                        </div>
                                        <h2>Apply, Discuss or Decline</h2>
                                    </div>
                                    <button onClick={() => {
                                        handleSignIn()
                                    }}>Log In / Sign In</button>
                                </section>
                            </div>
                        </div>  
                    </Slide>
                </Swiper>
            </div>
                {/* <main>
                    <div className="hero" data-native-app={isNativeApp}>
                        
                        { (localStorage.getItem("redirectOnLogin") === "/help/close-account") &&
                            <div className="testing-danger" onClick={() => {
                                setEnv("prod")
                            }}>
                                Please log in to request your data and account to be deleted.
                            </div>
                        }
                        { (localStorage.getItem("redirectOnLogin") !== "/help/close-account") &&
                            <img 
                                alt="availability share calendar" 
                                src={ document.body.clientWidth > 720 ? OnboardingHeroFull : OnboardingHero} 
                                onClick={() => {
                                    if (envSelectorCount < 5) {
                                        setEnvSelectorCount(envSelectorCount + 1)
                                    } else {
                                        setEnvSelector(true)
                                    }
                                } } 
                            />
                        }
                        <div className='bottom'>
                            <h1 onClick={() => {
                                window.open("https://updatedge.com")
                            }}>
                                <img alt="Updatedge Logo" className="logo" src={Logo} />
                                <span>Updatedge</span>
                            </h1>
                            { (localStorage.getItem("redirectOnLogin") !== "/help/close-account") &&
                                <h2>Effortlessly update your availability with all your contacts, chat with them, receive offers, & get more work. See your hours worked and pay.</h2>
                            }
                            { (localStorage.getItem("redirectOnLogin") === "/help/close-account") ?
                                <button 
                                    data-loading={loginPending} 
                                    id="login-btn" 
                                    onClick={() => { handleSignIn() }}
                                    style={{
                                        backgroundColor: '#FB5B5A',
                                        color: 'white'
                                    }}
                                >
                                    LOGIN & DELETE MY ACCOUNT
                                </button>
                                :
                                <button 
                                    data-loading={loginPending} 
                                    id="login-btn" 
                                    onClick={() => { handleSignIn() }}
                                >
                                    { (loginPending) ? 
                                        <span><div className="lds-ring" style={{ position: 'relative', top: -6 }}><div></div><div></div><div></div><div></div></div></span>
                                        : "Log In / Sign Up"
                                    }
                                </button>
                            }
                            { (!isNativeApp && localStorage.getItem("redirectOnLogin") !== "/help/close-account") &&
                                <div className="downloads">
                                    <div className="or">
                                        <hr />
                                        <label>or</label>
                                    </div>
                                    <img onClick={() => { window.open("https://apps.apple.com/gb/app/id1559217533") }} src={AppleStoreImg} />
                                    <img onClick={() => { window.open("https://play.google.com/store/apps/details?id=com.updatedge") }} src={GooglePlayImg} />
                                </div>
                            }
                        </div>
                    </div>
                </main> */}
                <IonActionSheet
                    isOpen={envSelector}
                        onDidDismiss={() => setEnvSelector(false)}
                        buttons={[
                            {
                                text: 'Disable Internal Tracking',
                                handler: () => { localStorage.setItem("disableTracking", "true") }
                            },
                            {
                                text: 'Local',
                                handler: () => { setEnv("local") }
                            },
                            {
                                text: 'Dev',
                                handler: () => { setEnv("dev") }
                            },
                            {
                                text: 'Prod',
                                handler: () => { setEnv("prod") }
                            }, {
                                text: 'Cancel',
                                role: 'cancel',
                                handler: () => { }
                            }
                        ]}
                    >
                </IonActionSheet>
            </IonContent>
            <IonFooter className="ion-no-border">
                <IonToolbar>
                    <IonTitle></IonTitle>
                </IonToolbar>
            </IonFooter>
        </IonPage>
    ); 
};

export default Onboarding;
