import { IonActionSheet, IonAlert, IonAvatar, IonBackButton, IonButton, IonButtons, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonItem, IonLabel, IonLoading, IonPage, IonTitle, IonToast, IonToggle, IonToolbar } from '@ionic/react';
import { calendar, chatbubbleEllipses, checkmark, closeOutline, ellipsisHorizontal, information, informationCircle } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import ContactAPI from '../apis/ContactAPI';
import OfferAPI from '../apis/Offer';
import Configuration from '../Configuration';
import { saveEvent, getEventsByOfferId } from '../stores/Event';
// import '../styles/Offer.scss';
import { Contact, ContactType } from '../types/Contact';
import { CollidingEvent, Event } from '../types/Event';
import { RatingRecord, DirectOffer, Offer, RatingDirection } from '../types/Offer';
import { FileType, User } from '../types/User';
import Utilities from '../Utilities';
import ContactListItem from './Components/Contacts/ContactListItem';
import LocationPreview from './Components/LocationPreview';
import InternalTracker from '../InternalTracker';
import NotificationAPI from '../apis/Notification';
import DocumentViewer from './Components/Files/DocumentViewer';
import FileListItem from './Components/Files/FileListItem';
import UserApi from '../apis/User';
import OfferCountdown from './Components/OfferCountdown';
import ReportModal, { ReportTypeId } from './Components/ReportModal';

const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

interface OfferEditModalProps {
    presentingElement?: any;
    match: object;
}

let alreadyAddedThisRound = [];

enum OfferState {
    New = 1,
    Withdrawn = 2,
    Applied = 3,
    Confirmed = 4,
    Updated = 5,
    UpdatedAccepted = 6,
    UpdatedRejected = 7,
    Rejected = 8,
    Unsuccessful = 9,
    Direct = 10,
}

const OfferEditModal: React.FC<OfferEditModalProps> = ({ match }) => {

    const contentRef = useRef(null);
    const [directEngagement, setDirectEngagement] = useState <boolean> (false) // Whether direct engagement is allowed
    const [agencyEngagement, setAgencyEngagement] = useState <boolean> (false) // Whether agency engagement is allowed
    const [engagementMoreInfoHiddenAgency, setEngagementMoreInfoHiddenAgency] = useState <boolean> (false) // Whether the long description is hidden for engagement methods
    const [engagementMoreInfoHiddenDirect, setEngagementMoreInfoHiddenDirect] = useState <boolean> (false) // Whether the long description is hidden for engagement methods
    const [loading, setLoading] = useState <string> (null) // Whether something is loading
    const [contacts, setContacts] = useState <ContactType[]> (null) // List of contacts
    const [loadedOffer, setLoadedOffer] = useState <Offer> (null) // List of contacts
    const [offer, setOffer] = useState <Offer> (null) // Offer
    const [canChooseDirect, setCanChooseDirect] = useState <boolean> (null) // Whether the user can choose direct engagement
    const [canChooseDirectAlert, setCanChooseDirectAlert] = useState <boolean> (null) // Whether to show not possible direct alert
    const [successToast, setSuccessToast] = useState <string> (null) // Success toast message
    const [errorToast, setErrorToast] = useState <string> (null) // Error toast message
    const [addedToSchedule, setAddedToSchedule] = useState <boolean> (false) // Whether the user has added the event to the calendar yet
    const [collidingEventsError, setCollidingEventsError] = useState <string> (null); // Colliding event error
    const [collidingEvents, setCollidingEvents] = useState <CollidingEvent[]> (null); // Colliding events
    const [isDirectOffer, setIsDirectOffer] = useState <boolean> (false); // Whether the offer is direct or not
    const [compiledEventChanges, setCompiledEventChanges] = useState <Event[]> (null); // Offers with status changes
    const [offerState, setOfferState] = useState <OfferState> (null); //state of the offer
    const [firstEventStarted, setFirstEventStarted] = useState <boolean> (false); // Whether the first event started or not
    const [directEngagementObligationsAlert, setDirectEngagementObligationsAlert] = useState <boolean> (false);
    const [openedFileType, setOpenedFileType] = useState <FileType> (null);
    const [openedFileUri, setOpenedFileUri] = useState <string> (null);
    const [openedFileName, setOpenedFileName] = useState <string> (null);
    const [openedFileId, setOpenedFileId] = useState <number> (null);
    const [organizationUrlFailed, setOrganizationUrlFailed] = useState <boolean> (false);
    const [moreOptions, setMoreOptions] = useState <boolean> (false);
    const [reportConfirmOfferId, setReportConfirmOfferId] = useState <string> (null);
    const history = useHistory();

    useEffect( () => { 
        // reload() 
        history.listen(e => {
            reloadContacts();
        })
    }, [])

    // @ts-ignore
    useEffect(() => { reload(); }, [match.params.id, match.params.hash])

    const reviewCollidingEvents = function(collisions: CollidingEvent[], offerEvents: Event[]) : void {
        InternalTracker.trackEvent("Colliding Events Review Started");

        localStorage.removeItem("draftEvent");
        localStorage.setItem("collidingEvents", JSON.stringify(collisions));
        localStorage.setItem("offerEvents", JSON.stringify(offerEvents));
        localStorage.setItem("collidingEventsOfferId", offer.id);
        localStorage.setItem("newOfferEventsCount", offer.events.length.toString());
        localStorage.setItem("disablePushReRegisterOnInit", "true");
        if (!window.location.href.includes("testing=true")) { 
            window.location.href = "/calendar"
        }
    }

    const reload = async function() {

        // @ts-ignore
        const id: string = match.params.id;

        InternalTracker.trackEvent("Offer Opened", {
            id: id,
        })

        let user: User = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "") : null;
        let directEngagementPrev = user ? user.allowDirectEngagement : false;
        let agencyEngagementPrev = user ? user.allowAgencyEngagement : false;

        if (directEngagementPrev || agencyEngagementPrev) {
            setEngagementMoreInfoHiddenAgency(true);
            setEngagementMoreInfoHiddenDirect(true);
            if (directEngagementPrev)
                setDirectEngagement(true);
            if (agencyEngagementPrev) {
                setAgencyEngagement(true);
                reloadContacts()
            }
        }

        if (window.location.href.indexOf("testing=true") !== -1) {
            const urlParams = new URLSearchParams(window.location.search);
            const datesStr = urlParams.get('dates');
            const dates = datesStr.split(",").map(str => str.split("|"));
            console.log(dates, '@@@@@@@@@');
            // @ts-ignore
            setOffer({
                "id": "test",
                "title": "test",
                "events": dates.map(datePair => {
                    return {
                        start: new Date(datePair[0]).toISOString(),
                        end: new Date(datePair[1]).toISOString(),
                    }
                })
            })
            setOfferState(OfferState.Confirmed);
        } else if (window.location.href.indexOf("directoffer") !== -1) {

            NotificationAPI.getDirect().then(data => {

                // @ts-ignore
                let directOffer: DirectOffer = data.find(item => item.id === id);

                if (directOffer) {

                    directOffer.creator = {
                        firstName: directOffer.metadata.createdByFullName,
                        lastName: "",
                        organisation: {
                            id: directOffer.metadata.createdByOrganisationId,
                            verified: false, 
                            name: directOffer.metadata.createdByOrganisationName,
                            isAgency: directOffer.metadata.createdByOrganisationIsAgency
                        },
                        userId: directOffer.metadata.createdById,
                        contactId: directOffer.metadata.createdById,
                    }
                    directOffer.events = directOffer.pendingEvents
                    directOffer.title = directOffer.pendingEvents[0].title;

                    setIsDirectOffer(true);
                    setCompiledEventChanges(null);
                    setOfferState(OfferState.Direct);
                    setOffer(directOffer);

                } else {

                    (window as any).toast("Coudn't find direct offer", "error")
                    history.goBack();

                }

            }).catch(e => {
                console.error("Fauled to get offer", e)
                history.goBack();
            })

        } else {

            OfferAPI.get(id).then(async (data) => {

                // @ts-ignore
                const offer = data as Offer
                setOffer(offer);

                let savedEvents = await getEventsByOfferId(id);
                
                setAddedToSchedule(savedEvents.length !== 0);

                const oState = Utilities.getOfferState(offer);
                if (oState) {
                    setOfferState(oState);
                } else {
                    (window as any).toast("Unsupported offer type", "error")
                }

                let firstEvent: Date = null;
                for (let i = 0; i < offer.events.length; i++) {
                    const event = offer.events[i];
                    if (!firstEvent || firstEvent > new Date(event.start)) {
                        firstEvent = new Date(event.start);
                    }
                }

                setFirstEventStarted(firstEvent < Utilities.dateSub(new Date(), "minute", 30));

                if (offer.parentOfferId) {
                    // Going over all new
                    let offerEventsCopy = JSON.parse(JSON.stringify(offer.events));
                    let offerEventsOldCopy = JSON.parse(JSON.stringify(offer.parentOfferEvents));
                    for (let i = 0; i < offerEventsCopy.length; i++) {
                        let newOfferI = offerEventsCopy[i];
                        console.log(newOfferI.start + " " + newOfferI.end);
                        let oldOfferPair = offerEventsOldCopy.find(parentOfferI => parentOfferI.start === newOfferI.start && parentOfferI.end === newOfferI.end);
                        if (oldOfferPair) {
                            // Unchanged
                            console.log("  Unchanged");
                            offerEventsCopy[i].updateState = "Unchanged";
                            offerEventsOldCopy = offerEventsOldCopy.filter(parentOfferI => !(parentOfferI.start === newOfferI.start && parentOfferI.end === newOfferI.end));
                        } else {
                            // Changed
                            console.log("  Changed, deleting from old")
                            offerEventsCopy[i].updateState = "New";
                        }
                    }
                    // All left is deleted
                    for (let i = 0; i < offerEventsOldCopy.length; i++) {
                        let oldOfferI = offerEventsOldCopy[i];
                        offerEventsOldCopy[i].updateState = "Deleted";
                        console.log(oldOfferI.start + " " + oldOfferI.end);
                        console.log("  Deleted");
                    }
                    let statefulOffers: Event[] = offerEventsCopy.concat(offerEventsOldCopy);
                    setCompiledEventChanges(statefulOffers.sort((a, b) => {
                        let dateA = new Date(a.start);
                        let dateB = new Date(b.start);
                        return dateA < dateB ? -1 : (dateA > dateB) ? 1 : 0;
                    }))
                } else {
                    setCompiledEventChanges(null);
                }
    
                if (offer && offer.confirmation === "Confirmed" && alreadyAddedThisRound.indexOf(offer.id) === -1 && savedEvents && savedEvents.length === 0 && window.location.href.indexOf("autoadd") !== -1) {
                    alreadyAddedThisRound.push(offer.id)
                    addToCalendar(offer);
                }
    
                if (offer && offer?.creator?.organisation?.isAgency) {
                    setDirectEngagement(false);
                    setAgencyEngagement(false);
                    setCanChooseDirect(false);
                } else {
                    setCanChooseDirect(true)
                }

                setIsDirectOffer(false);
    
            }).catch(e => {
                console.error("__Failed to get offer", e)
                if (e.response) {
                    if (e.response.status === 410) {
                        (window as any).toast("This offer has already expired", "error")
                    } else {
                        (window as any).toast("We failed to fetch this offer, please try later", "error")
                    }
                }
                history.goBack();
            })

        }

    }

    const reloadContacts = async function() {
        
        setLoading("Loading offer");
  
        ContactAPI.getContacts().then(data => {
            setLoading(null);
            // @ts-ignore
            console.log(data.data.agencies, "@@@@@@@@");
            // @ts-ignore
            setContacts(data.data.agencies.filter(item => item.hasAgreementWith).map(item => {
                if (!item.contacts || item.contacts.length === 0) item.contacts = [{ firstName: "No contact at this agency" }];
                return item;
            }));
        }).catch(e => {
            console.log(e);
            (window as any).toast("Failed to load offer", "error")
            setLoading(null)
        })
  
    }

    const onClose = async function(success?: string, error?: string) {

        if (success) {
            setSuccessToast(success);
            setTimeout(function() { history.goBack(); }, 1000);
        } else if (error) {
            setErrorToast(error)
            setTimeout(function() { history.goBack(); }, 1000);
        }

    }

    const accept = async function() {

        setLoading(offer && offer.parentOfferId ? 'Accepting' : 'Applying');

        InternalTracker.trackEvent("Offer Applied", {
            id: offer.id,
        });

        OfferAPI.accept(offer.id, directEngagement, agencyEngagement).then(async data => {

            // Deleting old events
            if (offer.parentOfferEvents && offer.parentOfferEvents.length !== 0) {
                let oldEvents = await getEventsByOfferId(offer.parentOfferId);
                if (oldEvents.length !== 0) {
                    for (let i = 0; i < oldEvents.length; i++) {
                        const element: Event = oldEvents[i];
                        element.deleted = true;
                        await saveEvent(element);
                    }
                }
            }

            if (!offer?.creator?.organisation?.isAgency) {
                Utilities.upsertLocalUser({
                    allowDirectEngagement: directEngagement,
                    allowAgencyEngagement: agencyEngagement
                })
            }

            setLoading(null);

            if (offer.parentOfferEvents && offer.parentOfferEvents.length !== 0) {
                reload();
            } else {
                onClose("Offer Applied", null);
            }

        }).catch(e => {
            console.log(e)
            setLoading(null)
            onClose(null, "Failed to apply to offer")
        })

    }

    const openChat = async function(offer: Offer) {
        InternalTracker.trackEvent("Offer Chat Inited", {
            id: offer.id,
        })
        localStorage.setItem("newMessageContent", "Hi, I have a question about " + offer.title + " (" + Utilities.formatOfferDate(offer.events ? offer.events : offer.pendingEvents) + "), ")
        localStorage.setItem("disablePushReRegisterOnInit", "true");
        history.push("/messages/" + offer.creator.userId + "/auto");
    }

    const reject = async function() {

        InternalTracker.trackEvent("Offer Rejected", {
            id: offer.id,
        });

        setLoading("Rejecting Offer");

        Utilities.rejectOffer(offer.id).then(async data => {

            setLoading(null)

            // Deleting old events
            if (offer.parentOfferEvents && offer.parentOfferEvents.length !== 0) {
                let oldEvents = await getEventsByOfferId(offer.parentOfferId);
                if (oldEvents.length !== 0) {
                    for (let i = 0; i < oldEvents.length; i++) {
                        const element: Event = oldEvents[i];
                        element.deleted = true;
                        await saveEvent(element);
                    }
                }
            }
        
            onClose("Offer rejected", null);

            setTimeout(() => {
                localStorage.setItem("disablePushReRegisterOnInit", "true");
                window.location.href = "/calendar";
            }, 1000);

        }).catch(e => {
            setLoading(null)
            onClose(null, "Failed to reject offer")
        })

    }

    const removeAgency = async function(orgTempId, name) {
        if (window.confirm("Are you sure you want to remove " + name + "?")) {

            InternalTracker.trackEvent("Contact Deleted", {
                type: "agency",
                id: orgTempId
            });

            setLoading("Removing Agency")

            ContactAPI.removeTempOrg(orgTempId).then(() => {
                setSuccessToast("Agency remove");
                reloadContacts();
                setLoading(null)
            }).catch(e => {
                setErrorToast("Failed to remove agency");
                reloadContacts();
                setLoading(null)
            })
        }
    }

    const removeFromCalendar = async function() {

        InternalTracker.trackEvent("Offer Events Removed From Calendar", {
            id: offer.id,
        })

        let savedEvents = await getEventsByOfferId(offer.id);

        if (savedEvents.length !== 0) {
            for (let i = 0; i < savedEvents.length; i++) {
                const element: Event = savedEvents[i];
                element.deleted = true;
                let res = await saveEvent(element);
                console.log(res);
            }
        }

        window.location.href = "/calendar";

    }

    const addToCalendar = async function(offer: Offer, isDirectOffer?: boolean, failedBackendAutoInsert?: boolean) {

        if (!offer) {
            return;
        }

        setLoading("Adding to your schedule");

        const GROUP_ID: string = offer.id; // Utilities.uuidv4();

        const DEFAULT_EVENT:Event = {
            "deleted": false,
            "updatedAt": "",
            "createdAt": "",
            "version": "",
            "id": "",
            "orderingUpdatedDate": "",
            "groupId": /* offer.events.length === 1 ? "" :  */ GROUP_ID,
            "pinned": false,
            "notes": offer.details,
            "repeatType": 0,
            "isSynced": false,
            "repeatForever": false,
            "repeatUntil": "",
            "end": "",
            "start": "",
            "eventType": 1,
            "title": "",
            "userId": "",
            "offerEventId": null,
            "creator": offer.creator,
            "googlePlacesId": offer.locationPlaceId,
            "organisationUrl": offer.organisationUrl
        }

        let collisions: CollidingEvent[] = [];

        let eventsToAdd = offer.events;

        for (let i = 0; i < eventsToAdd.length; i++) {
            const element = eventsToAdd[i];
            let event: Event = Object.assign(DEFAULT_EVENT, {})
            event.title = offer.title;
            event.notes = offer.details;
            event.start = element.start;
            event.end = element.end;
            event.id = Utilities.uuidv4();
            event.groupId = GROUP_ID;
            let thisCollisions = await Utilities.isEventColliding(new Date(event.start), new Date(event.end), "0", event.eventType);
            if (typeof thisCollisions !== "string" && thisCollisions[0]) {
                for (let j = 0; j < thisCollisions.length; j++) {
                    const tc = thisCollisions[j];
                    const tci = collisions.findIndex(c => c.id === tc.id);
                    event.title = "Incoming Offer Event"
                    if (tci !== -1) {
                        collisions[tci].collidingWithOfferEvents.push(JSON.parse(JSON.stringify(event)));
                    } else {
                        tc.collidingWithOfferEvents = [JSON.parse(JSON.stringify(event))];
                        collisions.push(tc);
                    }
                }
            }
        }

        if (collisions.length !== 0) {

            if (failedBackendAutoInsert) {
                reviewCollidingEvents(collisions, eventsToAdd);
                return;
            }

            setLoading(null);
            setCollidingEvents(collisions);

            if (collisions.length === 1) {
                setCollidingEventsError("This event is clashing with an existing event (" + Utilities.formatDate(new Date(collisions[0].date), "d mms YYYY") + ")")
            } else {
                let datesStr: string = ""
                for (let i = 0; i < collisions.length; i++) {
                    datesStr += Utilities.formatDate(new Date(collisions[i].date), "d mms YYYY")
                    if (i + 1 !== collisions.length) datesStr += ", ";
                }
                setCollidingEventsError("This event is clashing with " + collisions.length + " existing events (" + datesStr + ")")
            }

        } else {

            let firstDate: Date = null;
            let ids: string[] = [];

            for (let i = 0; i < eventsToAdd.length; i++) {
                const element = eventsToAdd[i];
                let event: Event = Object.assign(DEFAULT_EVENT, {})
                event.title = offer.title;
                event.notes = offer.details;
                event.start = element.start;
                event.end = element.end;
                if (!firstDate || new Date(event.start) < firstDate) {
                    firstDate = new Date(event.start);
                }
                event.id = Utilities.uuidv4();
                ids.push(event.id);
                event.groupId = GROUP_ID;
                event.offerEventId = element.offerEventId;
                if (isDirectOffer) {
                    event.pendingEventId = element.id;
                }
                if (eventsToAdd.length > 1) {
                    event.multipleGrouped = true;
                }
                await saveEvent(event);
            }

            // await OfferAPI.addToSchedule(offer.id);
            setLoading(null);
            (window as any).Sync.sync();
            setSuccessToast("Added to schedule");

            InternalTracker.trackEvent("Offer Events Added to Calendar", {
                id: offer.id,
            });

            setAddedToSchedule(true);
            localStorage.setItem("scrollToDate", firstDate.toISOString());
            localStorage.setItem("justAddedEvent", ids.join(","));
            history.push("/calendar" + (window.location.href.indexOf("testing=true") !== -1 ? "?testing=true" : ""));

            (window as any).attemptToShowReviewPrompt();

        }

        setLoading(null)

    }

    // @ts-ignore
    const hirerProfilePic = (offer && offer.creator) ? UserApi.getProfilePicture(offer.creator.userId) : "";
    // @ts-ignore
    const companyProfilePic = (offer && offer.creator && offer.creator.organisation) ? UserApi.getOrgPicture(offer.creator.organisation.id) : ""
    // @ts-ignore
    const hirerStr = (offer && offer.creator) ? (offer.creator.firstName + " " + offer.creator.lastName + ", from " + (offer.creator.organisation ? offer.creator.organisation.name : "No organization") ) : ""

    let MODAL_CONTENT_DOM = null;

    if (offer) {

        MODAL_CONTENT_DOM = 
        <div id="offer-edit-drawer-content" style={{ overflow: 'auto', paddingBottom: 78 }} className="swipeable-drawer-body" data-drawer="offer-edit">
            <div className="content" ref={contentRef}>
                { (true) &&
                    <div className="details">
                        { (offer.respondByDeadline && offerState === OfferState.New) &&
                            <OfferCountdown 
                                deadline={offer.respondByDeadline}
                            />
                        }
                        <h2>{ (isDirectOffer) ? "Assignment" : "Offer" } Details</h2>
                        { (offerState === OfferState.Updated) &&
                            <div className="top-banner warning info">
                                <IonIcon icon={informationCircle} />
                                <div>
                                    <p>The originally confirmed offer has changed - please review & select your response.</p>
                                </div>
                            </div>
                        }
                        { (offer.autoInsert) &&
                            <div className="top-banner warning info" onClick={() => { removeFromCalendar() }}>
                                <IonIcon icon={informationCircle} />
                                { (offer.events.find(e => e.deletedFromTimelineByHirer)) ?
                                    <div>
                                        { (offer.events.filter(e => e.deletedFromTimelineByHirer).length ===  offer.events.length) ?
                                            <p>We automatically removed these events from your calendar</p>
                                            :
                                            <p>We automatically removed all affected events from your calendar</p>
                                        }
                                    </div>
                                    :
                                    <div>
                                        <p>We automatically inserted these events into your calendar</p>
                                    </div>
                                }
                            </div>
                        }
                        { (offer.autoInsert === false) &&
                            <div className="warning withdrawn-warning" onClick={() => { addToCalendar(offer, isDirectOffer, true); }}>
                                 <IonIcon icon={informationCircle} />
                                <div>
                                    <p>Your current availability prevented this offer's events being added to your timeline automatically. Tap to resolve the conflict</p>
                                </div>
                            </div>
                        }
                        { (offerState === OfferState.Withdrawn) &&
                            <div className="warning withdrawn-warning" onClick={() => { removeFromCalendar() }}>
                                <IonIcon icon={informationCircle} />
                                <div>
                                    <p>This offer has been withdrawn by either you or the hirer.</p>
                                    { (addedToSchedule) &&
                                        <button>Remove from calendar</button>
                                    }
                                    { (offer.withdrawnReason) &&
                                        <p>Reason: {offer.withdrawnReason}</p>
                                    }
                                </div>
                            </div>
                        }
                        { (offerState === OfferState.Unsuccessful) &&
                            <div className="warning withdrawn-warning">
                                <IonIcon icon={informationCircle} />
                                <div>
                                    <p>Sorry, you were not selected for this offer this time</p>
                                </div>
                            </div>
                        }
                        { (offerState === OfferState.Rejected || offerState === OfferState.UpdatedRejected) &&
                            <div className="warning withdrawn-warning">
                                <IonIcon icon={informationCircle} />
                                <div>
                                    <p>{offer.autoDeclined ? ('Offer auto declined - You missed the requested response deadline.' + ( offer.complete ? "" : " Apply now; as this offer hasn’t been confirmed to others yet." )) : 'This offer has been rejected by you'}</p>
                                </div>
                            </div>
                        }
                        <IonItem mode="ios">
                            { (hirerStr) &&
                                <IonLabel className="ion-text-wrap">
                                    <h3>Hirer</h3>
                                    <p>{hirerStr}</p>
                                </IonLabel>
                            }
                            <IonAvatar 
                                slot="end" 
                                    onClick={() => {
                                    openChat(offer);
                                }}
                                style={{
                                    '--border-radius': '10px',
                                    margin: 5
                                }}
                            >
                                <img src={hirerProfilePic} />
                            </IonAvatar>
                            <IonAvatar 
                                slot="end"
                                style={{
                                    '--border-radius': '10px',
                                    margin: 5
                                }}
                            >
                                <img src={companyProfilePic} />
                            </IonAvatar>
                            { (offer.organisationUrl && !organizationUrlFailed) &&
                                <IonAvatar 
                                    slot="end" 
                                    onClick={() => {
                                        if (!offer.organisationUrl.startsWith("http")) 
                                        window.open(!offer.organisationUrl.startsWith("http") ? ("https://" + offer.organisationUrl) : offer.organisationUrl)
                                    }}
                                    style={{
                                        '--border-radius': '10px',
                                        margin: 5
                                    }}
                                >
                                    <img
                                        src={UserApi.getExtOrgPicture(offer.organisationUrl)}
                                        onError={() => {
                                            setOrganizationUrlFailed(true);
                                        }}
                                    />
                                </IonAvatar>
                            }
                        </IonItem>
                        { (!isDirectOffer) &&
                            <IonItem mode="ios">
                                <IonLabel>
                                    <h3>Title</h3>
                                    <p>{offer.title}</p>
                                </IonLabel>
                            </IonItem>
                        }
                        { (offer && offer.currencyCode && offer.totalGrossPay) &&
                            <IonItem mode="ios">
                                <IonLabel>
                                    <h3>Total Gross Pay</h3>
                                    <p>{offer.currencyCode === "GBP" ? "£" : ""}{offer.totalGrossPay}</p>
                                </IonLabel>
                            </IonItem>
                        }
                        { (offer.details) &&
                            <IonItem mode="ios">
                                <IonLabel className="ion-text-wrap">
                                    <h3>Description</h3>
                                    <p>{offer.details}</p>
                                </IonLabel>
                            </IonItem>
                        }
                        { (!compiledEventChanges) &&
                            <IonItem mode="ios">
                                <IonLabel>
                                    <h3>Vacancy Times</h3>
                                    { offer.events.map(item => {
                                        return (
                                            <p 
                                                style={{
                                                    textDecoration: item.deletedFromTimelineByHirer ? "line-through" : "none",
                                                    color: item.deletedFromTimelineByHirer ? "red" : "black",
                                                }}
                                                key={item.start + "-" + item.end}>{Utilities.formatDate(new Date(item.start), "d mms YYYY") + " - " + Utilities.formatDate(new Date(item.start), "HH:MM") + "-" + Utilities.formatDate(new Date(item.end), "HH:MM")}
                                            </p>
                                        )
                                    }) }
                                </IonLabel>
                            </IonItem>
                        }
                        { (compiledEventChanges) &&
                            <IonItem mode="ios">
                                <IonLabel>
                                    <h3>Vacancy Changes</h3>
                                    { compiledEventChanges.map(item => {
                                        return (
                                            <p 
                                                key={item.start + "-" + item.end}
                                                style={{
                                                    textDecoration: item.updateState === "Deleted" ? "line-through" : "none",
                                                    color: item.updateState === "New" ? "green" : (item.updateState === "Deleted") ? "red" : "black",
                                                }}
                                            >
                                                {Utilities.formatDate(new Date(item.start), "d mms YYYY") + " - " + Utilities.formatDate(new Date(item.start), "HH:MM") + "-" + Utilities.formatDate(new Date(item.end), "HH:MM")}
                                                <span style={{
                                                    float: "right",
                                                }}>{item.updateState}</span>
                                            </p>
                                        )
                                    }) }
                                </IonLabel>
                            </IonItem>
                        }
                        { (offer.locationPlaceId || (offer.address && offer.address.address1)) &&
                            <IonItem mode="ios">
                                <IonLabel className="ion-text-wrap">
                                    <h3>Location</h3>
                                    { offer.locationPlaceId && <LocationPreview locationFriendlyName={offer.locationFriendlyName} locationFriendlyAddress={offer.locationFriendlyAddress} showLocationNameFromGoogle={offer.locationFriendlyName && offer.locationFriendlyAddress ? false : true} googlePlacesId={offer.locationPlaceId} /> }
                                    { (offer.address && offer.address.address1) &&
                                        <p className="full-address">
                                            { offer.address.address1 }
                                            { offer.address.address2 && (', ' + offer.address.address2 ) }
                                            { offer.address.address3 && (', ' + offer.address.address3 ) }
                                            { offer.address.town && (', ' + offer.address.town ) }
                                            { offer.address.county && (', ' + offer.address.county ) }
                                            { offer.address.postcode && (', ' + offer.address.postcode ) }
                                            { offer.locationFriendlyName && <span>{offer.locationFriendlyName}</span> }
                                        </p>
                                    }
                                </IonLabel>
                            </IonItem>
                        }
                        { (offer.organisationUrl) &&
                            <IonItem mode="ios">
                                <IonLabel className="ion-text-wrap hirers-website">
                                    <h3>Hirer's Website</h3>
                                    <p>
                                        {offer.organisationUrl}
                                        <button onClick={() => {
                                            window.open(!offer.organisationUrl.startsWith("http") ? ("https://" + offer.organisationUrl) : offer.organisationUrl)
                                        }}>Open Website</button>
                                    </p>
                                </IonLabel>
                            </IonItem>
                        }
                        { (offer.files && offer.files.length > 0) &&
                            <IonItem mode="ios">
                                <IonLabel className="ion-text-wrap">
                                    <h3>Files</h3>
                                    <div className='file-list'>
                                        { offer.files.filter(file => file.moderatorApproved).map((file, fileI) => {
                                            return (
                                                <FileListItem
                                                    file={file}
                                                    fileI={fileI}
                                                    onClick={() => {
                                                        if (!file.virusScanned) {
                                                            (window as any).toast("This file has not been virus scanned yet. Please try again later", "error");
                                                            return;
                                                        }
                                                        if (!file.accessLink) {
                                                            alert("You don't have access to this file")
                                                        } else {
                                                            if (
                                                                ["docx", "doc", "jpg", "jpeg", "png", "pdf", "ppt", "pptx", "xls", "xlsx"].indexOf(file.fileFormat || "") !== -1 ||
                                                                file.fileType === FileType.VideoLink || 
                                                                file.fileType === FileType.Link
                                                            ) {
                                                                setOpenedFileUri(file.accessLink);
                                                                setOpenedFileName(file.fileName);
                                                                setOpenedFileId(file.id);
                                                                setOpenedFileType(file.fileType || null);                                                              
                                                                Utilities.onDrawerShow();
                                                            } else {
                                                                window.open(file.accessLink)
                                                            }
                                                        }
                                                    }}
                                                    showAccess={false}
                                                    showDescription={true}
                                                    showOwner={false}
                                                />
                                            )
                                        }) }
                                    </div>
                                </IonLabel>
                            </IonItem>
                        }
                    </div>
                }
                { ( ((offerState === OfferState.New) && canChooseDirect) || (offerState === OfferState.Rejected && !offer.complete) ) &&
                    <div className="details">
                        <h2>Select Engagement Type</h2>
                        <div className="switch">
                            <IonItem>
                                <IonLabel className="ion-text-wrap">
                                    <h3>via Agencies or Third-parties</h3>
                                    { (engagementMoreInfoHiddenAgency) ?
                                        <IonIcon icon={informationCircle} onClick={() => { setEngagementMoreInfoHiddenAgency(false) }} />
                                        :
                                        <React.Fragment>
                                            <p>Add each agency organizations e.g. Randstad who represent you.</p>
                                            <p>Adding agencies, gives your hirers greater choice who they can engage you through. </p>
                                            <p>You confirm you have a current agreement with each organization listed, and that all legal obligations & checks are complete. </p>
                                            <p>Please keep this list up-to-date.</p>
                                        </React.Fragment>
                                    }
                                </IonLabel>
                                <IonToggle slot="start" checked={agencyEngagement} onIonChange={() => {
                                    if (!loading && !agencyEngagement && contacts === null) {
                                        reloadContacts();
                                    }

                                    if (agencyEngagement) {
                                        setContacts(null);
                                    }
                                    
                                    InternalTracker.trackEvent("Offer Engagement Type Change", {
                                        type: "agency",
                                        enabled: !agencyEngagement,
                                        id: offer.id,
                                    })

                                    setAgencyEngagement(!agencyEngagement);
                                }}></IonToggle>
                            </IonItem>
                        </div>
                        { contacts &&
                            <React.Fragment>
                                <div className="contacts">
                                    <h2>Agencies</h2>
                                    <button className="add-agency-btn edit-agency-btn" onClick={() => {
                                        onClose();
                                        history.push("/contacts") 
                                    }}>Edit List</button>
                                    <button className="add-agency-btn" onClick={() => {
                                        onClose();
                                        history.push("/agency") 
                                    }}>Add Agency</button>
                                    <label>I warrant I have an agreement with each organization listed.</label>
                                    <div className="group">
                                        { contacts.map(hirer => { return ( <div> { hirer.contacts.map((contact, i) => <ContactListItem onDeleteOrg={removeAgency} companyVerified={contact.verified} everFirst={false} hideOptions={true} onEdit={() => { }} first={i === 0} contact={{ verified: contact.verified, email: contact.email, userId: contact.userId, companyName: hirer.organisationName, companyId: hirer.organisationId, name: "Agency Contact" } as Contact} list="EMPLOYER" /> )} </div> ) }) }
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                        <div className="switch" style={{ marginTop: 20 }}>
                            <IonItem lines="none">
                                <IonLabel className="ion-text-wrap">
                                    <h3>Direct</h3>
                                    { (engagementMoreInfoHiddenDirect) ?
                                        <IonIcon icon={informationCircle} onClick={() => { setEngagementMoreInfoHiddenDirect(false) }} />
                                        :
                                        <p>Allow hirers to engage and pay me directly</p>
                                    }
                                </IonLabel>
                                <IonToggle slot="start" checked={directEngagement} onIonChange={(e) => {
                                    InternalTracker.trackEvent("Offer Engagement Type Change", {
                                        type: "direct",
                                        enabled: !directEngagement,
                                        id: offer.id,
                                    })

                                    if (!canChooseDirect) {
                                        setCanChooseDirectAlert(true);
                                    } else {
                                        setDirectEngagement(!directEngagement)
                                    }
                                }}></IonToggle>
                            </IonItem>
                        </div>
                        <div className="obligations" onClick={() => { 
                            InternalTracker.trackEvent("Offer Obligations Viewed", {
                                id: offer.id,
                            });
                            setDirectEngagementObligationsAlert(true);
                        }}>
                            Your Obligations
                            <IonIcon 
                                icon={informationCircle}
                                style={{
                                    position: "relative",
                                    top: 4,
                                    left: 6,
                                    fontSize: 20
                                }}
                            />
                        </div>
                    </div>
                }
            </div>
        </div>
    }

    return (
        <IonPage>
            <IonContent className="offer-wrapper">
                <IonHeader>
                    <IonToolbar>
                    <IonTitle>{
                        (isDirectOffer) ?
                            "New Assignment" :
                            (offerState === OfferState.New) ?
                                "New Offer" :
                                (offerState === OfferState.Withdrawn) ?
                                    "Withdrawn Offer" :
                                    (offerState === OfferState.Applied) ?
                                        "Applied" :
                                        (offerState === OfferState.Confirmed) ?
                                        "Confirmed Offer" :
                                        (offerState === OfferState.Updated) ?
                                            "Updated Offer" :
                                            (offerState === OfferState.UpdatedAccepted) ?
                                                "Update Accepted" :
                                                (offerState === OfferState.UpdatedRejected) ?
                                                    "Update Declined" :
                                                    (offerState === OfferState.Rejected) ?
                                                        "Offer Declined" :
                                                        (offerState === OfferState.Unsuccessful) ?
                                                        "Unsuccessful" :
                                                        "Offer"
                    }</IonTitle>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/notifications" />
                    </IonButtons>
                    <IonButtons slot="end">
                        <IonButton onClick={() => {
                            setMoreOptions(true);
                        }}><IonIcon icon={ellipsisHorizontal} /></IonButton>
                    </IonButtons>
                    </IonToolbar>
                </IonHeader>
                { MODAL_CONTENT_DOM }
                    <IonLoading
                        isOpen={loading !== null}
                        onDidDismiss={() => setLoading(null)}
                        message={loading}
                        duration={12000}
                    />
                    { (openedFileUri) &&
                        <DocumentViewer
                            uri={openedFileUri}
                            id={openedFileId}
                            name={openedFileName}
                            type={openedFileType}
                            close={() => {
                                setOpenedFileUri(undefined);
                            }}
                            report={() => {
                                if (window.confirm("Are you sure you want to report this file?")) {
                                  UserApi.reportFile(openedFileId).then(() => {
                                    (window as any).toast("File reported successfully", "success");
                                  }).catch((err) => {
                                    (window as any).toast("Error reporting file", "error");
                                  })
                                }
                            }}
                        />
                    }
                    <IonToast isOpen={successToast !== null} color="success" onDidDismiss={() => setSuccessToast(null)} message={successToast} position="top" duration={2500} buttons={[ { text: 'Hide', role: 'cancel' } ]}/>
                    <IonToast isOpen={errorToast !== null} color="danger" onDidDismiss={() => setErrorToast(null)} message={errorToast} position="top" duration={2500} buttons={[ { text: 'Hide', role: 'cancel' } ]} />
                    <IonAlert
                        isOpen={collidingEventsError !== null}
                        onDidDismiss={() => setCollidingEventsError(null)}
                        header={"Clashing Events"}
                        message={collidingEventsError}
                        buttons={[
                            { text: 'Cancel Adding', handler: () => { 
                                setCollidingEventsError(null); 

                                InternalTracker.trackEvent("Offer Add Cancelled due to Collistion", {
                                    id: offer.id,
                                })
                            } },
                            { text: 'Review Collisions', handler: () => { reviewCollidingEvents(collidingEvents, offer.events); } },
                        ]}
                    />
            </IonContent>
            <div className='bottom-buttons'>
                { ( (OfferState.New === offerState || OfferState.Updated === offerState || OfferState.Applied === offerState) && !isDirectOffer && !firstEventStarted) &&
                    <button className="danger" onClick={() => { reject(); }}>
                        <IonIcon icon={closeOutline} />
                        <span>
                            { offer && offer.parentOfferId ? 'Reject' : 'Decline' }
                        </span>
                    </button>
                }
                { (offer && offer.id) &&
                    <button className="primary" onClick={() => {
                        openChat(offer);
                    }}>
                        <img src={UserApi.getProfilePicture(offer.creator.userId)} />
                        <span style={{ width: 'calc(100% - 28px)', marginLeft: 8 }}>
                            Chat
                            {/* { offer.creator.firstName && ` with ${offer.creator.firstName}` } */}
                        </span>
                    </button>
                }
                { ( ( (OfferState.New === offerState || OfferState.Updated === offerState) && !isDirectOffer && offer.autoInsert === undefined && !firstEventStarted) || (offerState === OfferState.Rejected && !offer.complete) ) &&
                    <button className="success" onClick={() => {
                        if (directEngagement || (agencyEngagement && contacts.length !== 0) || !canChooseDirect) {
                            accept();
                        } else {
                            (window as any).toast("Select direct engagement or add at least one agency.")
                        }
                    }}>
                        <IonIcon icon={checkmark} />
                        <span>
                            { offer && offer.parentOfferId ? 'Accept' : 'Apply' }
                        </span>
                    </button>
                }
                { (offer && offer.autoInsert === false) &&
                    <button className='primary' onClick={() => { addToCalendar(offer, isDirectOffer, true); }}>
                        <IonIcon style={{ fontSize: '1.2em' }} icon={calendar} />
                        <span>Review Collision</span>
                    </button>
                }
                { (isDirectOffer || (offer && !offer.addedToTimeline && (offerState === OfferState.Confirmed || offerState === OfferState.UpdatedAccepted) ) ) && (offer && offer.autoInsert === undefined) &&
                    <button className='primary' onClick={() => { addToCalendar(offer, isDirectOffer); }}>
                        <IonIcon style={{ fontSize: '1.2em' }} icon={calendar} />
                        <span>Add to Calendar</span>
                    </button>
                }
            </div>
            <IonAlert
                isOpen={canChooseDirectAlert}
                onDidDismiss={() => setCanChooseDirectAlert(false)}
                header={'You cannot choose direct engagement, as this offer is from an agency.'}
                buttons={[
                    {
                        text: 'Ok',
                        handler: () => {

                        }
                    }
                ]}
            />
            <IonAlert
                isOpen={directEngagementObligationsAlert}
                onDidDismiss={() => setDirectEngagementObligationsAlert(false)}
                header={'Direct Engagement Your Obligations'}
                message={'For the avoidance of doubt updatedge facilitates availability and offers. We do not engage or employ end-users contacts and do not assess the right to work, references, suitability, or do any checks, nor do we invoice or pay for assignments. These obligations remain the end-user’s responsibility and or the agency through which end-users engage any contacts or temporary workers as per their normal terms of engagement for temporary workers.'}
                buttons={[
                    {
                        text: 'Ok',
                        handler: () => { }
                    }
                ]}
            />
            <IonActionSheet
                isOpen={moreOptions}
                onDidDismiss={() => setMoreOptions(false)}
                header={"Options"}
                buttons={[
                    {
                        text: "Report Offer",
                        handler: () => {
                            InternalTracker.trackEvent("Offer Reported");
                            setReportConfirmOfferId(offer.id);
                        }
                    },
                    {
                        text: 'Close',
                        handler: () => {}
                    }
                ].filter(item => item)}
            />
            { (reportConfirmOfferId !== null) &&
                <ReportModal
                    type={ReportTypeId.Offer}
                    id={offer?.creator?.userId}
                    isUserId={true}
                    reportedEntityId={reportConfirmOfferId/* TODO pass later */}
                    open={true}
                    onClose={() => {
                        setReportConfirmOfferId(null);
                    }}
                />
            }
        </IonPage>
    );
    
};

export default OfferEditModal;
