import { Event, CollidingEvent } from './types/Event'
import { AvailabilityCircleSlice } from './types/AvailabilityCircleSlice'
import { getAllEvents, getEventById, getInferredUnavailabilityDates, getLatestAvailableEventDate, saveEvent } from './stores/Event'
import store from './state/store';
import { updateEventCache } from './state/actions/EventCache';
import { Auth } from './services/AuthService';
import { DestroyStorage } from './stores/Index';
import Configuration from './Configuration';
import { isPlatform } from '@ionic/react';
import { Plugins } from '@capacitor/core';
import { User, UserDTO } from './types/User';
import UserAPI from './apis/User';
import NotificationAPI from './apis/Notification';
import { Offer } from './types/Offer';
import OfferAPI from './apis/Offer';
import ContactAPI from './apis/ContactAPI';
import InternalTracker from './InternalTracker';
import { Contact, ContactResultDto } from './types/Contact';
import AuthOwn from './Auth';
import { LocalNotifications } from '@capacitor/local-notifications';
import PublicEmailProviders from './PublicEmailProviders.json';

import { Geolocation } from '@capacitor/geolocation';
// @ts-ignore
const { nativegeocoder } = window

enum OfferState {
  New = 1,
  Withdrawn = 2,
  Applied = 3,
  Confirmed = 4,
  Updated = 5,
  UpdatedAccepted = 6,
  UpdatedRejected = 7,
  Rejected = 8,
  Unsuccessful = 9,
  Direct = 10,
}

const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

class Utilities {

    static daysDiffBetween(start: Date, end: Date): number {
      return Math.round((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
    }

    static decodeJWTToken(token: string): any {
      if (!token) return null;
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    }

    static async scheduleNotifications() {
      let permissionState = await LocalNotifications.checkPermissions();
      // Don't ask for permission if not granted at push
      if (permissionState.display !== "granted") { 
        return;
      }

      const haveContacts = localStorage.getItem("haveContact") === "true";
      let res = await LocalNotifications.cancel({ notifications: [{id: 1}] });
      if (!haveContacts) {
        const sendAt = Utilities.dateAdd(new Date(), "hour", 3);
        sendAt.setMinutes(0);
        sendAt.setSeconds(0);
        while (sendAt.getHours() > 21 && sendAt.getHours() < 8) {
          sendAt.setHours(sendAt.getHours() + 1);
        }
        await LocalNotifications.schedule({ notifications: [{
          title: "You are not sharing with anyone",
          body: "Add your contacts, so they can see your availability",
          id: 1,
          schedule: { at: sendAt },
          sound: "default",
          attachments: null,
          actionTypeId: "",
          extra: null
        }] });
        if (localStorage.getItem("debugAlerts")) {
          (window as any).toast("Scheduled no-contact notification at " + sendAt);
        }
      }
    }

    static isValidHttpUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    }

    static getContactsName(userId: string): string {
      const contacts = localStorage.getItem("chat-contacts") ? JSON.parse(localStorage.getItem("chat-contacts")) : [];
      const match = contacts.find(c => c.userId === userId);
      if (match && match.fullName) {
        return match.fullName;
      }
      return "Unknown User";
    }

    static async getNotifications(): Promise<Object> {
      return new Promise( async (resolve, reject) => {
        const res = await Promise.all([
          NotificationAPI.get(),
          NotificationAPI.getNew()
        ])
        if (res && res[0] && res[1]) {
          resolve ({
            ...res[0],
            ...res[1]
          })
        } else {
          throw reject("Failed to get notifications")
        }
      })
    }

    static async autoResolveCollodingEvents() {
      let draftEvent = localStorage.getItem("draftEvent") ? JSON.parse(localStorage.getItem("draftEvent")) : null;
      const collidingEvents = localStorage.getItem("collidingEvents") ? JSON.parse(localStorage.getItem("collidingEvents")) : null;
      
      if ((draftEvent || localStorage.getItem("collidingEventsOfferId")) && collidingEvents) {

        for (let i = 0; i < collidingEvents.length; i++) {
          const cEvent = collidingEvents[i];
          const event = await getEventById(cEvent.id);
          if (event) {

            if (cEvent.resolutionMethod === "delete") {

              console.log("@@@ Deleting colliding event", event);
              if (event.repeatForever || event.repeatUntil) {

                const REPEAT_STARTED_TIME: Date = new Date(event.start);
                const REPEAT_ENDED_TIME: Date = new Date(event.end);
                const REPEAT_UNTIL: Date = event.repeatForever ? null : new Date(event.repeatUntil);
    
                let originalEvent = await getEventById(event.id);
                const dateInstance = new Date(cEvent.start);
    
                if (Utilities.areDatesAreOnSameDay(REPEAT_STARTED_TIME, dateInstance)) {
    
                    // Increasing the start date on the original repeating event, no delete is required
                    if (event.repeatType === 1 || event.repeatType === 2) {
                        originalEvent.start = Utilities.dateAdd(REPEAT_STARTED_TIME, "day", 1).toISOString();
                        originalEvent.end = Utilities.dateAdd(REPEAT_ENDED_TIME, "day", 1).toISOString();
                    } else if (event.repeatType === 3) {
                        originalEvent.start = Utilities.dateAdd(REPEAT_STARTED_TIME, "week", 1).toISOString();
                        originalEvent.end = Utilities.dateAdd(REPEAT_ENDED_TIME, "week", 1).toISOString();
                    } else if (event.repeatType === 4) {
                        originalEvent.start = Utilities.dateAdd(REPEAT_STARTED_TIME, "month", 1).toISOString();
                        originalEvent.end = Utilities.dateAdd(REPEAT_ENDED_TIME, "month", 1).toISOString();
                    }
    
                    // Only one day left of repetition, so making that one not repeating
                    if (REPEAT_UNTIL && Utilities.areDatesAreOnSameDay(new Date(originalEvent.start), REPEAT_UNTIL)) { 
                        originalEvent.repeatType = 0;
                        originalEvent.repeatUntil = null;
                    }
    
                    console.log(originalEvent);
                    let saveEventRes = await saveEvent(originalEvent);
    
                } else if (REPEAT_UNTIL && Utilities.areDatesAreOnSameDay(dateInstance, REPEAT_UNTIL)) {
    
                    // Decrease the end date on the original repeating event
                    if (REPEAT_UNTIL) {
                        if (event.repeatType === 1 || event.repeatType === 2) {
                            originalEvent.repeatUntil = Utilities.dateSub(new Date(originalEvent.repeatUntil), "day", 1).toISOString();
                        } else if (event.repeatType === 3) {
                            originalEvent.repeatUntil = Utilities.dateSub(new Date(originalEvent.repeatUntil), "week", 1).toISOString();
                        } else if (event.repeatType === 4) {
                            originalEvent.repeatUntil = Utilities.dateSub(new Date(originalEvent.repeatUntil), "month", 1).toISOString();
                        }
                    }
    
                    // Only one day left of repetition, so making that one not repeating
                    if (REPEAT_UNTIL && Utilities.areDatesAreOnSameDay(new Date(originalEvent.start), new Date(originalEvent.repeatUntil))) { 
                        originalEvent.repeatType = 0;
                        originalEvent.repeatUntil = null;
                    }
    
                    console.log(originalEvent);
    
                    let saveEventRes = await saveEvent(originalEvent);
    
                } else {
    
                    let firstHalfRepetition = JSON.parse(JSON.stringify(originalEvent));
                    let lastHalfRepetition = JSON.parse(JSON.stringify(originalEvent));
    
                    // Repeat right until the start of the deleted repeat instance
                    if (event.repeatType === 1 || event.repeatType === 2) {
                        firstHalfRepetition.repeatUntil = new Date(Utilities.dateSub(dateInstance, "day", 1)).toISOString();
                    } else if (event.repeatType === 3) {
                        firstHalfRepetition.repeatUntil = new Date(Utilities.dateSub(dateInstance, "week", 1)).toISOString();
                    } else if (event.repeatType === 4) {
                        firstHalfRepetition.repeatUntil = new Date(Utilities.dateSub(dateInstance, "month", 1)).toISOString();
                    }
                    // Only one day left of repetition, so making that one not repeating
                    if (REPEAT_UNTIL && Utilities.areDatesAreOnSameDay(new Date(firstHalfRepetition.repeatUntil), new Date(firstHalfRepetition.start))) { 
                        firstHalfRepetition.repeatType = 0;
                        firstHalfRepetition.repeatUntil = null;
                    }
    
                    let saveEventRes = await saveEvent(firstHalfRepetition);
    
                    // Start a day after the deleted repetition and repeat until the original end date
                    if (event.repeatType === 1 || event.repeatType === 2) {
                        lastHalfRepetition.start = Utilities.dateAdd(new Date(dateInstance.getFullYear(), dateInstance.getMonth(), dateInstance.getDate(), new Date(originalEvent.start).getHours(), new Date(originalEvent.start).getMinutes()), "day", 1).toISOString();
                        lastHalfRepetition.end = Utilities.dateAdd(new Date(dateInstance.getFullYear(), dateInstance.getMonth(), dateInstance.getDate(), new Date(originalEvent.end).getHours(), new Date(originalEvent.end).getMinutes()), "day", 1).toISOString();
                    } else if (event.repeatType === 3) {
                        lastHalfRepetition.start = Utilities.dateAdd(new Date(dateInstance.getFullYear(), dateInstance.getMonth(), dateInstance.getDate(), new Date(originalEvent.start).getHours(), new Date(originalEvent.start).getMinutes()), "week", 1).toISOString();
                        lastHalfRepetition.end = Utilities.dateAdd(new Date(dateInstance.getFullYear(), dateInstance.getMonth(), dateInstance.getDate(), new Date(originalEvent.end).getHours(), new Date(originalEvent.end).getMinutes()), "week", 1).toISOString();
                    } else if (event.repeatType === 4) {
                        lastHalfRepetition.start = Utilities.dateAdd(new Date(dateInstance.getFullYear(), dateInstance.getMonth(), dateInstance.getDate(), new Date(originalEvent.start).getHours(), new Date(originalEvent.start).getMinutes()), "month", 1).toISOString();
                        lastHalfRepetition.end = Utilities.dateAdd(new Date(dateInstance.getFullYear(), dateInstance.getMonth(), dateInstance.getDate(), new Date(originalEvent.end).getHours(), new Date(originalEvent.end).getMinutes()), "month", 1).toISOString();
                    }
    
                    if (REPEAT_UNTIL) {
                        lastHalfRepetition.repeatUntil = REPEAT_UNTIL.toISOString();
                        if (Utilities.areDatesAreOnSameDay(new Date(REPEAT_UNTIL), new Date(lastHalfRepetition.end))) {
                            lastHalfRepetition.repeatType = 0;
                            lastHalfRepetition.repeatUntil = null;
                        }
                    }
    
                    lastHalfRepetition.id = Utilities.uuidv4();
                    // lastHalfRepetition.groupId = Utilities.uuidv4();
    
                    saveEventRes = await saveEvent(lastHalfRepetition);
    
                    console.log(firstHalfRepetition, lastHalfRepetition);
    
                }    

              } else {
                const changedEvent = Object.assign({}, event);
                changedEvent.deleted = true;
                changedEvent.updatedAt = (new Date()).toISOString();
                let saveEventRes = await saveEvent(changedEvent);
                console.log("@@@ single EVent deleted", event);
              }

            } else if (cEvent.resolutionMethod === "merge") { 

              let collidingEventTodayStart = new Date();
              collidingEventTodayStart.setHours(new Date(cEvent.start).getHours());
              collidingEventTodayStart.setMinutes(new Date(cEvent.start).getMinutes());
  
              let collidingEventTodayEnd = new Date();
              collidingEventTodayEnd.setHours(new Date(cEvent.end).getHours());
              collidingEventTodayEnd.setMinutes(new Date(cEvent.end).getMinutes());

              const newEvents = [];

              if (!draftEvent) {
                draftEvent = cEvent.collidingWithOfferEvents;
              }

              if (Array.isArray(draftEvent)) {
                for (let j = 0; j < draftEvent.length; j++) {
                  const newEventInstance = draftEvent[j];
                  let draftEventTodayStart = new Date();
                  draftEventTodayStart.setHours(new Date(newEventInstance.start).getHours());
                  draftEventTodayStart.setMinutes(new Date(newEventInstance.start).getMinutes());
                  let draftEventTodayEnd = new Date();
                  draftEventTodayEnd.setHours(new Date(newEventInstance.end).getHours());
                  draftEventTodayEnd.setMinutes(new Date(newEventInstance.end).getMinutes());
                  console.log("@@@ LOOPING Draft event instance" + collidingEventTodayStart + " " + collidingEventTodayEnd + " || " + draftEventTodayStart + " " + draftEventTodayEnd)

                  if (j === 0 && collidingEventTodayStart < draftEventTodayStart) {
                      console.log("@@@ GAP HERE Start " + cEvent.start + " -> " + newEventInstance.start)
                      // There is a gap at the start to fill out with the existing event before the first new event start
                      newEvents.push({
                          ...event,
                          start: cEvent.start,
                          end: newEventInstance.start,
                      });
                  }
                  
                  if (draftEvent[j+1] && !Utilities.isSameDate(draftEventTodayEnd, new Date(draftEvent[j+1].start))) {
                      // There is a gap between this new event and the next new one
                      const newStartDate = new Date(draftEvent[j+1].start);
                      newStartDate.setHours(new Date(draftEventTodayEnd).getHours());
                      newStartDate.setMinutes(new Date(draftEventTodayEnd).getMinutes());
                      newEvents.push({
                          ...event,
                          start: newStartDate,
                          end: draftEvent[j+1].start
                      });
                  }
                  
                  if (j === draftEvent.length - 1 && collidingEventTodayEnd > draftEventTodayEnd) {
                      console.log("@@@ GAP HERE End" + newEventInstance.end + " -> " + cEvent.end)
                      // There is a gap at the end to fill out with the existing event after the last new event end
                      newEvents.push({
                          ...event,
                          start: newEventInstance.end,
                          end: cEvent.end,
                      });
                  }
                }
                draftEvent = null
              } else {
                let draftEventTodayStart = new Date();
                draftEventTodayStart.setHours(new Date(draftEvent.start).getHours());
                draftEventTodayStart.setMinutes(new Date(draftEvent.start).getMinutes());
        
                let draftEventTodayEnd = new Date();
                draftEventTodayEnd.setHours(new Date(draftEvent.end).getHours());
                draftEventTodayEnd.setMinutes(new Date(draftEvent.end).getMinutes());

                if (draftEventTodayStart > collidingEventTodayStart && draftEventTodayEnd < collidingEventTodayEnd) {
                  let newEnd = new Date(event.start);
                  newEnd.setHours(new Date(draftEvent.start).getHours());
                  newEnd.setMinutes(new Date(draftEvent.start).getMinutes());
                  newEvents.push({
                    ...event,
                    end: newEnd.toISOString(),
                  });

                  let newStart = new Date(event.start);
                  newStart.setHours(new Date(draftEvent.end).getHours());
                  newStart.setMinutes(new Date(draftEvent.end).getMinutes());
                  newEvents.push({
                      ...event,
                      start: newStart.toISOString(),
                  });
                } else if (draftEventTodayStart < collidingEventTodayStart || (draftEventTodayStart.getHours() === collidingEventTodayStart.getHours() && draftEventTodayStart.getMinutes() === collidingEventTodayStart.getMinutes())) {
                  let newStart = new Date(event.start);
                  newStart.setHours(new Date(draftEvent.end).getHours());
                  newStart.setMinutes(new Date(draftEvent.end).getMinutes());
                  newEvents.push({
                      ...event,
                      start: newStart
                  });
                } else if (draftEventTodayEnd > collidingEventTodayEnd || (draftEventTodayEnd.getHours() === collidingEventTodayEnd.getHours() && draftEventTodayEnd.getMinutes() === collidingEventTodayEnd.getMinutes())) {
                  let newEnd = new Date(event.start);
                  newEnd.setHours(new Date(draftEvent.start).getHours());
                  newEnd.setMinutes(new Date(draftEvent.start).getMinutes());
                  newEvents.push({
                      ...event,
                      end: newEnd
                  });
                }
              }

              if (event.repeatForever || event.repeatUntil) {

                const REPEAT_STARTED_TIME: Date = new Date(event.start);
                const REPEAT_ENDED_TIME: Date = new Date(event.end);
                const REPEAT_UNTIL: Date = event.repeatForever ? null : new Date(event.repeatUntil);

                let originalEvent = await getEventById(event.id);
                const dateInstance = new Date(cEvent.start);

                if (Utilities.areDatesAreOnSameDay(REPEAT_STARTED_TIME, dateInstance)) {

                    // Increasing the start date on the original repeating event
                    originalEvent.start = Utilities.dateAdd(REPEAT_STARTED_TIME, "day", 1).toISOString();
                    originalEvent.end = Utilities.dateAdd(REPEAT_ENDED_TIME, "day", 1).toISOString();

                    // Increasing the start date on the original repeating event, no delete is required
                    if (event.repeatType === 1 || event.repeatType === 2) {
                        originalEvent.start = Utilities.dateAdd(REPEAT_STARTED_TIME, "day", 1).toISOString();
                        originalEvent.end = Utilities.dateAdd(REPEAT_ENDED_TIME, "day", 1).toISOString();
                    } else if (event.repeatType === 3) {
                        originalEvent.start = Utilities.dateAdd(REPEAT_STARTED_TIME, "week", 1).toISOString();
                        originalEvent.end = Utilities.dateAdd(REPEAT_ENDED_TIME, "week", 1).toISOString();
                    } else if (event.repeatType === 4) {
                        originalEvent.start = Utilities.dateAdd(REPEAT_STARTED_TIME, "month", 1).toISOString();
                        originalEvent.end = Utilities.dateAdd(REPEAT_ENDED_TIME, "month", 1).toISOString();
                    }

                    // Only one day left of repetition, so making that one not repeating
                    if (REPEAT_UNTIL && Utilities.areDatesAreOnSameDay(new Date(originalEvent.start), REPEAT_UNTIL)) { 
                        originalEvent.repeatType = 0;
                        originalEvent.repeatUntil = null;
                    }

                    let saveEventRes = await saveEvent(originalEvent);

                } else if (REPEAT_UNTIL && Utilities.areDatesAreOnSameDay(dateInstance, REPEAT_UNTIL)) {

                    // Decrease the end date on the original repeating event
                    if (REPEAT_UNTIL) {
                        if (event.repeatType === 1 || event.repeatType === 2) {
                            originalEvent.repeatUntil = Utilities.dateSub(new Date(originalEvent.repeatUntil), "day", 1).toISOString();
                        } else if (event.repeatType === 3) {
                            originalEvent.repeatUntil = Utilities.dateSub(new Date(originalEvent.repeatUntil), "week", 1).toISOString();
                        } else if (event.repeatType === 4) {
                            originalEvent.repeatUntil = Utilities.dateSub(new Date(originalEvent.repeatUntil), "month", 1).toISOString();
                        }
                    }

                    // Only one day left of repetition, so making that one not repeating
                    if (REPEAT_UNTIL && Utilities.areDatesAreOnSameDay(new Date(originalEvent.start), new Date(originalEvent.repeatUntil))) { 
                        originalEvent.repeatType = 0;
                        originalEvent.repeatUntil = null;
                    }

                    let saveEventRes = await saveEvent(originalEvent);

                } else {

                    let firstHalfRepetition = JSON.parse(JSON.stringify(originalEvent));
                    let lastHalfRepetition = JSON.parse(JSON.stringify(originalEvent));

                    // Repeat right until the start of the deleted repeat instance
                    if (event.repeatType === 1 || event.repeatType === 2) {
                        firstHalfRepetition.repeatUntil = new Date(Utilities.dateSub(dateInstance, "day", 1)).toISOString();
                    } else if (event.repeatType === 3) {
                        firstHalfRepetition.repeatUntil = new Date(Utilities.dateSub(dateInstance, "week", 1)).toISOString();
                    } else if (event.repeatType === 4) {
                        firstHalfRepetition.repeatUntil = new Date(Utilities.dateSub(dateInstance, "month", 1)).toISOString();
                    }

                    // Only one day left of repetition, so making that one not repeating
                    if (REPEAT_UNTIL && Utilities.areDatesAreOnSameDay(new Date(firstHalfRepetition.repeatUntil), new Date(firstHalfRepetition.start))) { 
                        firstHalfRepetition.repeatType = 0;
                        firstHalfRepetition.repeatUntil = null;
                    }

                    let saveEventRes = await saveEvent(firstHalfRepetition);

                    // Start a day after the deleted repetition and repeat until the original end date
                    if (event.repeatType === 1 || event.repeatType === 2) {
                        lastHalfRepetition.start = Utilities.dateAdd(new Date(dateInstance.getFullYear(), dateInstance.getMonth(), dateInstance.getDate(), new Date(originalEvent.start).getHours(), new Date(originalEvent.start).getMinutes()), "day", 1).toISOString();
                        lastHalfRepetition.end = Utilities.dateAdd(new Date(dateInstance.getFullYear(), dateInstance.getMonth(), dateInstance.getDate(), new Date(originalEvent.end).getHours(), new Date(originalEvent.end).getMinutes()), "day", 1).toISOString();
                    } else if (event.repeatType === 3) {
                        lastHalfRepetition.start = Utilities.dateAdd(new Date(dateInstance.getFullYear(), dateInstance.getMonth(), dateInstance.getDate(), new Date(originalEvent.start).getHours(), new Date(originalEvent.start).getMinutes()), "week", 1).toISOString();
                        lastHalfRepetition.end = Utilities.dateAdd(new Date(dateInstance.getFullYear(), dateInstance.getMonth(), dateInstance.getDate(), new Date(originalEvent.end).getHours(), new Date(originalEvent.end).getMinutes()), "week", 1).toISOString();
                    } else if (event.repeatType === 4) {
                        lastHalfRepetition.start = Utilities.dateAdd(new Date(dateInstance.getFullYear(), dateInstance.getMonth(), dateInstance.getDate(), new Date(originalEvent.start).getHours(), new Date(originalEvent.start).getMinutes()), "month", 1).toISOString();
                        lastHalfRepetition.end = Utilities.dateAdd(new Date(dateInstance.getFullYear(), dateInstance.getMonth(), dateInstance.getDate(), new Date(originalEvent.end).getHours(), new Date(originalEvent.end).getMinutes()), "month", 1).toISOString();
                    }

                    if (REPEAT_UNTIL) {
                        lastHalfRepetition.repeatUntil = REPEAT_UNTIL.toISOString();
                        if (Utilities.areDatesAreOnSameDay(new Date(REPEAT_UNTIL), new Date(lastHalfRepetition.end))) {
                            lastHalfRepetition.repeatType = 0;
                            lastHalfRepetition.repeatUntil = null;
                        }
                    }

                    lastHalfRepetition.id = Utilities.uuidv4();
                    // lastHalfRepetition.groupId = Utilities.uuidv4();

                    saveEventRes = await saveEvent(lastHalfRepetition);

                }

                for (let j = 0; j < newEvents.length ; j++) {
                  let newSingleEvent: Event = JSON.parse(JSON.stringify(event))
                  let newStart = new Date(dateInstance);
                  newStart.setHours(new Date(newEvents[j].start).getHours());
                  newStart.setMinutes(new Date(newEvents[j].start).getMinutes());
                  newSingleEvent.start = newStart.toISOString();
                  let newEnd = new Date(dateInstance);
                  newEnd.setHours(new Date(newEvents[j].end).getHours());
                  newEnd.setMinutes(new Date(newEvents[j].end).getMinutes());
                  newSingleEvent.end = newEnd.toISOString();
                  newSingleEvent.repeatType = 0;
                  newSingleEvent.repeatUntil = null;
                  newSingleEvent.id = Utilities.uuidv4();
                  let saveEventRes = await saveEvent(newSingleEvent);
                }

              } else {
                const changedEvent = JSON.parse(JSON.stringify(event));
                changedEvent.deleted = true;
                changedEvent.updatedAt = (new Date()).toISOString();
                let saveEventRes = await saveEvent(changedEvent);
                console.log("@@@@@@@@@@@@@ single EVent deleted", changedEvent.start + " - " + changedEvent.end)

                for (let i = 0; i < newEvents.length; i++) {
                  const newEvent = newEvents[i];
                  newEvent.deleted = false;
                  newEvent.id = Utilities.uuidv4();
                  let saveEventRes = await saveEvent(newEvent);
                  console.log("@@@@@@@@@@@@@@@ NEW EVENT saved", newEvent.start + " - " + newEvent.end);
                }
              }

            }

          }
        }
      }
    }

    static intOrNaN(x) {
      return /^\d+$/.test(x) ? +x : NaN
    }

    static async getContacts(): Promise<Object> {
      // todo pending doesn't add to agencies? 
      return ContactAPI.getContacts().then(data => {
        const contactIds = [];
        const userIds = [];
        let blockedByContacts = [];
        // @ts-ignore
        let deletedContacts = data.data.deletedContacts

        let agenciesCount = 0;
        // @ts-ignore
        data.data.agencies = data.data.agencies.map(item => {
          if (!item.contacts || item.contacts.length === 0) {
            item.contacts = [{ firstName: "No contact at this agency" }];
            // agenciesCount++ // just agency record doesn't count as contact
          } else {
            agenciesCount += item.contacts.length
          }
          return item;
        });

        let contactsList: Contact[] = []; // contacts you are sharing with
        let agenciesList: Contact[] = []; // contacts that represent you

        // @ts-ignore
        let hirersCount = data.data ? data.data.hirers.length : 0;
        // @ts-ignore
        let  pendingRequests = data.data && data.data.outgoing ? data.data.outgoing.length : 0;

        // @ts-ignorez
        localStorage.setItem("haveContact", data.data && (data.data.agencies.length !== 0 || data.data.hirers.length !== 0 || data.data.others.length !== 0 || data.data.outgoing.length !== 0))
        // @ts-ignore
        localStorage.setItem("contactsCount", data.data ? (data.data.agencies.length + data.data.hirers.length + data.data.others.length + data.data.outgoing.length) : 0)
        // @ts-ignore
        localStorage.setItem("hirersCount", hirersCount + pendingRequests)
        // @ts-ignore
        localStorage.setItem("agenciesCount", agenciesCount)
        // @ts-ignore
        localStorage.setItem("contacts", JSON.stringify(data.data));
        // @ts-ignore
        localStorage.setItem("archivedChats", JSON.stringify(data.data.archivedChats));

        this.scheduleNotifications();

        let updateTargets = [];

        // @ts-ignore
        const hirers = data.data.hirers as ContactType[];
        for (let i = 0; i < hirers.length; i++) {
          updateTargets.push("o-" + hirers[i].organisationId)
          for (let j = 0; j < hirers[i].contacts.length; j++) {
            if (hirers[i].contacts[j].contactId)
              contactIds.push(hirers[i].contacts[j].contactId)
              userIds.push(hirers[i].contacts[j].userId)
              contactsList.push({ // must be a sharing relationship if in hirers
                ...hirers[i].contacts[j],
                organisationId: hirers[i].organisationId,
                organisationName: hirers[i].organisationName
              });
            if (hirers[i].contacts[j].notificationStatusTypeId === 4)
              blockedByContacts.push(hirers[i].contacts[j].userId)
          }
        };

        // @ts-ignore
        const agencies = data.data.agencies as ContactType[];
        for (let i = 0; i < agencies.length; i++) {
          updateTargets.push("o-" + agencies[i].organisationId)
          // must be a representing relationship if in agencies, and no contacts
          if (!agencies[i].contacts || agencies[i].contacts.filter(c => c.firstName !== "No contact at this agency" && !c.email).length === 0) {
            if (!agenciesList.find(item => item.organisationId === agencies[i].organisationId)) {
            agenciesList.push({
                organisationId: agencies[i].organisationId,
                organisationName: agencies[i].organisationName,
              });
            }
          }

          for (let j = 0; j < agencies[i].contacts.length; j++) {
            if (agencies[i].contacts[j].contactId) {
              contactIds.push(agencies[i].contacts[j].contactId);
              userIds.push(agencies[i].contacts[j].userId)
              const contactItem = agencies[i].contacts[j]
              // if contact it must be sharing relationship
              if (agencies[i].contacts[j].firstName !== "No contact at this agency") {
                if (agencies[i].hasAgreementWith) {
                  if (!agenciesList.find(item => item.organisationId === agencies[i].organisationId)) {
                    agenciesList.push({
                      ...contactItem,
                      organisationId: agencies[i].organisationId,
                      organisationName: agencies[i].organisationName
                    });
                  }
                }
                if (agencies[i].isSharingWith) {
                  contactsList.push({
                    ...contactItem,
                    organisationId: agencies[i].organisationId,
                    organisationName: agencies[i].organisationName
                  }); 
                }
              }
            }
            if (agencies[i].contacts[j].notificationStatusTypeId === 4)
              blockedByContacts.push(agencies[i].contacts[j].userId)
          }
        };
        
        // @ts-ignore
        const others = data.data.others as ContactType[];
        for (let i = 0; i < others.length; i++) {
          for (let j = 0; j < others[i].contacts.length; j++) {
            if (others[i].contacts[j].contactId)
              contactIds.push(others[i].contacts[j].contactId)
              userIds.push(others[i].contacts[j].userId)
              contactsList.push(others[i].contacts[j]);
              if (others[i].contacts[j].userId) {
                updateTargets.push("u-" + others[i].contacts[j].userId)
              }
            if (others[i].contacts[j].notificationStatusTypeId === 4)
              blockedByContacts.push(others[i].contacts[j].userId)
          }
        };

        // @ts-ignore
        const outgoing = data.data.outgoing as ContactType[];
        if (outgoing && outgoing[0] && outgoing[0].contacts)
        for (let i = 0; i < outgoing[0].contacts.length; i++) {
          const contact = outgoing[0].contacts[i];
          if (contact.contactId) {
            contactsList.push(contact);
          }
        };

        // @ts-ignore
        localStorage.setItem("contactIds", JSON.stringify(contactIds));
        localStorage.setItem("userIds", JSON.stringify(userIds));
        localStorage.setItem("blockedByContacts", JSON.stringify(blockedByContacts))
        localStorage.setItem("deletedContacts", JSON.stringify(deletedContacts))
        localStorage.setItem("updateTargets", JSON.stringify([...new Set(updateTargets)]))
        localStorage.setItem("contactsList", JSON.stringify(contactsList))
        localStorage.setItem("agenciesList", JSON.stringify(agenciesList))

        // @ts-ignore
        return {
          hirers: hirers,
          agencies: agencies,
          others: others,
          // @ts-ignore
          outgoings: data.data.outgoing,
          // @ts-ignore
          archivedChats: data.data.archivedChats,
          blockedByContacts: blockedByContacts,
          deletedContacts: deletedContacts,
        }
      }).catch(e => {
        throw e;
      })
    }

    static async getUserProfile(): Promise<User> {
      let data = await UserAPI.getNew()

      // @ts-ignore
      let user = data.results;
      if (user) {
        if (user.settings && user.settings.find(item => item.settingName === "Events_DefaultPinned_1_Dismissed") && user.settings.find(item => item.settingName === "Events_DefaultPinned_1_Dismissed").value === "true")
          user.events_DefaultPinned_1_Dismissed = "true";

        if (user.settings && user.settings.find(item => item.settingName === "Events_DefaultPinned_2_Dismissed") && user.settings.find(item => item.settingName === "Events_DefaultPinned_2_Dismissed").value === "true")
          user.events_DefaultPinned_2_Dismissed = "true";

        if (user.settings && user.settings.find(item => item.settingName === "Events_DefaultPinned_3_Dismissed") && user.settings.find(item => item.settingName === "Events_DefaultPinned_3_Dismissed").value === "true")
          user.events_DefaultPinned_3_Dismissed = "true";

        if (user.settings && user.settings.find(item => item.settingName === "Events_DefaultPinned_4_Dismissed") && user.settings.find(item => item.settingName === "Events_DefaultPinned_4_Dismissed").value === "true")
          user.events_DefaultPinned_4_Dismissed = "true";

        let oldUser: User = {};

        if (user.ratings) {
          user.ratings = user.ratings.map(item => {
            if (item.publicComment && !item.publicComment.trim()) {
              item.publicComment = "";
            }
            return item;
          })
        }

        if (!user.workerAttributes) {
          user.workerAttributes = [];
        }
      
        (window as any).globalUserId = user.id;
        localStorage.setItem("user", JSON.stringify({...oldUser, ...user}))
      }

      return user;
    }

    static async attemptRefresh() {
      if ((window as any).refreshStarted === true) {
        return;
      }

      (window as any).refreshStarted = true;
      setTimeout(() => {
        (window as any).refreshStarted = false;
      }, 5000)

      if (!isPlatform("mobileweb") && ( isPlatform("android") || isPlatform("ios") )) {
        Auth.Instance.addActionListener((action) => {
          console.log("@@@@@@@@@@@", action)
          if (action && action.action === "Get Token From Storage Success") {
            Auth.Instance.refreshToken();
          } else if (action && action.tokenResponse && action.tokenResponse.accessToken) {
              localStorage.setItem("access_token", action.tokenResponse.accessToken);
              window.location.href = "/";
          } else {
            this.logOut();
          }
        });
        setTimeout(() => {
          Auth.Instance.loadTokenFromStorage();
        }, 1000)
      } else {
        // alert("Desktop refres tart")
        // AuthOwn.refresh();
        // this.logOut();

        AuthOwn.registerProfiles(
          CONFIG["AUTH_APP_ID"],
          CONFIG["AUTH_MOBILE_SCOPE"],
          CONFIG["AUTH_POLICY_NAME"],
          CONFIG["AUTH_TENANT_NAME"],
          `${window.location.origin}/redirect`
        );

        const oldToken = localStorage.getItem("access_token");

        AuthOwn.login();

        (window as any).refreshTokenCheckInterval = setInterval(() => {
          const hellojs = localStorage.getItem("hello");
          if (!hellojs) return;
          const helloJsObj = JSON.parse(hellojs);
          const newToken = helloJsObj.b2cSignInAndUpPolicy.access_token;
          if (oldToken !== newToken) {
            // alert("Refreshed")
            clearInterval((window as any).refreshTokenCheckInterval);
            localStorage.setItem("access_token", newToken);
            window.location.href = "/";
          }
        }, 200);
      }
    }

    static getOfferState(offer: Offer): OfferState {
      if (offer.withdrawn === true) {
          return OfferState.Withdrawn;
      } else if (offer.parentOfferEvents && offer.response === "Rejected") {
          return OfferState.UpdatedRejected;
      } else if (offer.cancelled === true || offer.response === "Rejected") {
          return OfferState.Rejected;
      } else if (offer.parentOfferId && offer.complete === false && offer.response === "AwaitingResponse" && offer.withdrawn === false) {
          return OfferState.Updated;
      } else if (offer.parentOfferId && offer.complete === true && offer.response === "Accepted" && offer.withdrawn === false) {
          return OfferState.UpdatedAccepted;
      } else if (offer.complete === false && offer.response === "AwaitingResponse" && offer.withdrawn === false) {
          return OfferState.New;
      } else if (offer.complete === false && offer.response === "Accepted" && offer.withdrawn === false) {
          return OfferState.Applied;
      } else if (offer.confirmation === "Unsuccessful" && offer.response === "Accepted") {
          return OfferState.Unsuccessful;
      } else if (offer.complete === true && offer.response === "Accepted" && offer.withdrawn === false) {
          return OfferState.Confirmed;
      } else {
          return null;
      }
    }

    static async rejectOffer(offerId: string): Promise<any> {
      return OfferAPI.reject(offerId)
    }

    static offerHash(offer: Offer): string {
      return offer.id + "_" + offer.complete + "_" + offer.confirmation + "_" + offer.withdrawn + "_" + (offer.autoDeclined || false);
    }

    static async logOut() {

      let hello = localStorage.getItem("hello");
      // @ts-ignore
      let idToken = hello ? JSON.parse(hello).b2cSignInAndUpPolicy.id_token : null;
      let redirectUri = CONFIG.PORTAL_URL + "/external/logged-out";
      localStorage.clear();
      await DestroyStorage();

      if (!isPlatform("mobileweb") && ( isPlatform("android") || isPlatform("ios") )) {
        Auth.Instance.addActionListener((action) => {
          if (action && action.action === "Sign Out Success") {
            if (!(window as any).justShowedSignedOut) {
              alert("Successfully Signed Out");
            }
            (window as any).justShowedSignedOut = true;
            setTimeout(() => {
              (window as any).justShowedSignedOut = false;
            }, 5000)
            if (localStorage.getItem("admin_access_token")) {
              localStorage.setItem("access_token", localStorage.getItem("admin_access_token"));
              localStorage.removeItem("admin_access_token");
              window.location.href = "/";
            } else {
              window.location.href = "/onboarding"
            }
          }
        });
        Auth.Instance.signOut();
      } else {
        if ((window as any).Cypress || window.location.href.indexOf("testing") !== -1) {
          window.open(`https://${CONFIG.AUTH_TENANT_NAME}.b2clogin.com/${CONFIG.AUTH_TENANT_NAME}.onmicrosoft.com/oauth2/v2.0/logout?p=${CONFIG.AUTH_POLICY_NAME}&id_token_hint=${idToken}&post_logout_redirect_uri=${redirectUri}`, '_self')
        } else {
          window.open(`https://${CONFIG.AUTH_TENANT_NAME}.b2clogin.com/${CONFIG.AUTH_TENANT_NAME}.onmicrosoft.com/oauth2/v2.0/logout?p=${CONFIG.AUTH_POLICY_NAME}&id_token_hint=${idToken}&post_logout_redirect_uri=${redirectUri}`)
        }
        setTimeout(function() {
          if (localStorage.getItem("admin_access_token")) {
            localStorage.setItem("access_token", localStorage.getItem("admin_access_token"));
            localStorage.removeItem("admin_access_token");
            window.location.href = "/";
          } else {
            window.location.href = "/onboarding"
          }
        }, 1500);
      }

    }

    static isObjectEmpty(obj) {
      for(var prop in obj) {
        if(Object.prototype.hasOwnProperty.call(obj, prop)) {
          return false;
        }
      }
    
      return JSON.stringify(obj) === JSON.stringify({});
    }

    static hoursDiffBetween(date1, date2) {
      return Math.abs(date1 - date2) / 36e5;
    }

    static upsertLocalUser = function(newFields: UserDTO) {
      let user: string = localStorage.getItem("user");
      if (user) {
        let oldUser: User = JSON.parse(user);
        localStorage.setItem("user", JSON.stringify({...oldUser, ...newFields}))
      }
    }

    static toHHMM(seconds: number): string {
      return new Date(seconds * 1000).toISOString().substr(11, 5);
    }

    static toHoursMinutes(seconds: number): string {
      return parseInt(new Date(seconds * 1000).toISOString().substr(11, 2)) + " hours, " + parseInt(new Date(seconds * 1000).toISOString().substr(14, 2)) + " mins";
    }

    /**
     * Genrates a random integer between two numbers
     * @param min from
     * @param max to
     * @returns random number
     */
    static randomIntFromInterval(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    /**
     * Takes a day of the month and returns the cardinal format
     * @param d the day number of the month
     * @returns the cardinal date format
     */
    static nth(d: number): string {
        if (d > 3 && d < 21) return d + 'th';
        switch (d % 10) {
            case 1:  return d + "st";
            case 2:  return d + "nd";
            case 3:  return d + "rd";
            default: return d + "th";
        }
    }

    /**
     * Takes two dates and returns the difference in days between them
     * @param date1 date 1
     * @param date2 date 2
     * @returns difference between two dates in days
     */
    static differenceBetweenDatesDays(date1, date2): number {
      return Math.round(Math.abs(date1 - date2) / 1000 / 3600 / 24);
  }

    /**
     * Takes two dates and returns the difference in seconds between them
     * @param date1 date 1
     * @param date2 date 2
     * @returns difference between two dates in seconds
     */
    static differenceBetweenDatesSeconds(date1, date2) {
        return Math.abs(date1 - date2) / 1000;
    }

    /**
     * Determines whether two dates are on the same day
     * @param first first date
     * @param second second date
     * @returns whether they are the same date
     */
    static areDatesAreOnSameDay(first: Date, second: Date): boolean {
        return first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth() && first.getDate() === second.getDate();
    }

    /**
     * Determines whether two dates are in the same month
     * @param first first date
     * @param second second date
     * @returns whether they are in the same month
     */
    static areDatesInSameMonth(first: Date, second: Date): boolean {
        return first.getFullYear() === second.getFullYear() && first.getMonth() === second.getMonth();
    }

    /**
     * Determines whether two dates are on the same day
     * @param str string to capitalize
     * @returns capitalized string
     */
    static capitalizeFirstLetter(str: string): string {
      if (!str) return "";
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    static capitalizeEachFirstLetter(str: string): string {
      if (!str) return "";
      var splitStr = str.split(' ');
      for (var i = 0; i < splitStr.length; i++) {
          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
          console.log(splitStr[i])
      }
      return splitStr.join(' ').replace(/Of/g, 'of').replace(/In/g, 'in').replace(/And/g, 'and').replace(/With/g, 'with'.replace(/On/g, 'on')).replace(/From/g, 'from');
    }

    static capitalizeEachSentence(str: string): string {
      if (!str) return "";
      return str.replace(/(^\w{1})|(\.\s+\w{1})/gi, function (char) {
          return char.toUpperCase();
      });
    }

    /**
     * Determines whether a string is an email
     * @param email email address
     * @returns whether it is a valid email
     */
    static isEmail(email: string): boolean {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    /**
     * Calculates the time between the current date and supplied date
     * @param date date to calculate from
     * @returns time since string
     */
    static timeSince(date: Date, short?: true): string {

      // @ts-ignore
      var seconds = Math.floor((new Date() - date) / 1000);

      if (seconds < 0) {
        return "Just Now";
      }
    
      var interval = seconds / 31536000;
    
      if (interval > 1) {
        return Math.floor(interval) + (short ? "y ago" : " years ago");
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + (short ? "m ago" : " months ago");
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + (short ? "d ago" : " days ago");
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + (short ? "h ago" : " hours ago");
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + (short ? "m ago" : " minutes ago");
      }

      return short ? "Just Now" : Math.floor(seconds) + (short ? "s" : " seconds");
      
    }

    static timeToFormattedDate(num_sec: number): string {
        let hours: number | string = Math.floor(num_sec / 3600);
        let minutes: number | string = Math.floor((num_sec - (hours * 3600)) / 60);
        let seconds: number | string = num_sec - (hours * 3600) - (minutes * 60);
    
        // if (hours   < 10) {hours = "0"+hours;}
        // if (minutes < 10) {minutes = "0"+minutes;}
        // if (seconds < 10) {seconds = "0"+seconds;}
        if (hours === 0 && minutes === 0) {
          return seconds + " seconds";
        } else {
          return (hours ? hours + "h " : " ") + (minutes ? minutes + "m " : " ").trim();
        }
    }

    static secondsToDhms(seconds) {
      seconds = Number(seconds);

      return {
        days: Math.floor(seconds / (3600*24)),
        hours: Math.floor(seconds % (3600*24) / 3600),
        minutes: Math.floor(seconds % 3600 / 60),
        seconds: Math.floor(seconds % 60)
      }
    }

    static isSameDate(date1, date2): boolean {
      return (
        date2.getFullYear() === date1.getFullYear() &&
        date2.getMonth() === date1.getMonth() &&
        date2.getDate() === date1.getDate()
      );
    }

    static formatOfferDate(events: Event[]) {
      let fullStr = ((events.length === 1) ? 
          (Utilities.formatDate(new Date(events[0].start), "ds d mms YYYY") + " (" + Utilities.formatDate(new Date(events[0].start), "HH:MM") + "-" + Utilities.formatDate(new Date(events[0].end), "HH:MM") + ")") : 
          (Utilities.formatDate(new Date(events[0].start), "ds d mms YYYY") + " (" + Utilities.formatDate(new Date(events[0].start), "HH:MM") + ")" + " - "  + Utilities.formatDate(new Date(events[events.length-1].end), "d mms YYYY") + " (" + Utilities.formatDate(new Date(events[events.length-1].end), "HH:MM") + ")" ) )
      return fullStr;
    }

    static formatAddressComponents(address_components): string {
      if (!address_components) {
        return "";
      }
      let addressObj = {}
      address_components.forEach(function(value) {
          addressObj[value.types[0]] = value.long_name
      });
      let addressFormatted = {
        // @ts-ignore
        streetHouse: ( ( addressObj.street_number ? addressObj.street_number + " " : " ") + ( addressObj.route ? addressObj.route : "") ).trim(),
        // @ts-ignore
        city: addressObj.postal_town ? addressObj.postal_town : addressObj.locality,
        // @ts-ignore
        country: addressObj.country,
        // @ts-ignore
        postcode: addressObj.postal_code ? addressObj.postal_code : addressObj.postal_code_prefix
      }
      return (addressFormatted.streetHouse ? addressFormatted.streetHouse + " " : "") + 
        (addressFormatted.city ? addressFormatted.city + " " : "") + 
        (addressFormatted.country ? addressFormatted.country + " " : "") + 
        (addressFormatted.postcode ? addressFormatted.postcode + " " : "").trim();
    }

    /**
     * Formats a date to a given string format
     * @param dateObj date to format
     * @param format format of the date
     * @returns formatted date
     */
    static formatDate(dateObj: Date, format: string): string {

        var str = new Date();
        const now: Date = new Date();

        if (dateObj !== undefined) {
            str = new Date(dateObj);
        }
        var dateStr = "";

        var months;
        // var week;
        var days;
        var years;
        var hours;
        var minutes;
        var seconds;
        var milliseconds;

        const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const DAYS_SHORT = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
        const MONTHS_SHORT = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];

        switch (format) {

            case "mms YYYY":
                dateStr = MONTHS_SHORT[str.getMonth()] + " " + str.getFullYear();
                break;

            case "mms":
              dateStr = MONTHS_SHORT[str.getMonth()];
              break;

            case "YYYY-MM-DD":
                months = (str.getMonth()+1);
                days = (str.getDate());
                years = str.getFullYear();
                if (months < 10) months = "0" + months
                if (days < 10) days = "0" + days
                dateStr = years + "-" + months + "-" + days;
                break;

            case "YYYY-MM":
                months = (str.getMonth()+1);
                days = (str.getDate());
                years = str.getFullYear();
                if (months < 10) months = "0" + months
                dateStr = years + "-" + months;
                break;

            case "D":
                days = (str.getDate());
                dateStr = days;
                break;

            case "Dth":
                dateStr = this.nth(str.getDate());
                break;

            case "d":
                dateStr = DAYS[str.getDay()];
                break;

            case "ds":
                dateStr = DAYS_SHORT[str.getDay()];
                break;

            case "dss":
              dateStr = DAYS_SHORT[str.getDay()].substring(0, 1);
              break;

            case "YYYY mm":
                dateStr = str.getFullYear() + " " + MONTHS[str.getMonth()];
                break;

            case "d mms, YYYY":
                dateStr = str.getDate() + " " + MONTHS_SHORT[str.getMonth()] + ", " + str.getFullYear();
                break; 

            case "YYYY mms d":
                dateStr = str.getFullYear() + " " + MONTHS_SHORT[str.getMonth()] + " " + str.getDate();
                break; 

            case "d mms YYYY":
                dateStr = str.getDate() + " " + MONTHS_SHORT[str.getMonth()] + " " + str.getFullYear();
                break; 

            case "ds d mms YYYY":
                dateStr = DAYS_SHORT[str.getDay()] + " " + str.getDate() + " " + MONTHS_SHORT[str.getMonth()] + " " + str.getFullYear();
                break; 

            case "HH:MM d mms (YYYY)":
                hours = str.getHours()
                minutes = str.getMinutes()
                if (hours < 10) hours = "0" + hours;
                if (minutes < 10) minutes = "0" + minutes
                dateStr = hours + ":" + minutes;
                if (now.getFullYear() === str.getFullYear()) {
                    dateStr += " " + str.getDate() + " " + MONTHS_SHORT[str.getMonth()];
                } else {
                    dateStr += " " + str.getDate() + " " + MONTHS_SHORT[str.getMonth()] + " " + str.getFullYear();
                }
                break; 

            case "d mms (YYYY)":
                if (now.getFullYear() === str.getFullYear()) {
                    dateStr = str.getDate() + " " + MONTHS_SHORT[str.getMonth()];
                } else {
                    dateStr = str.getDate() + " " + MONTHS_SHORT[str.getMonth()] + " " + str.getFullYear();
                }
                break; 

            case "mm d, YYYY":
                dateStr = MONTHS[str.getMonth()] + " " + str.getDate() + ", " + str.getFullYear();
                break; 

            case "mm YYYY":
                dateStr = MONTHS[str.getMonth()] + " " + str.getFullYear();
                break;

            case "HH:MM":
                hours = str.getHours()
                minutes = str.getMinutes()
                if (hours < 10) hours = "0" + hours;
                if (minutes < 10) minutes = "0" + minutes
                dateStr = hours + ":" + minutes;
                break;

            case "YYYY-MM-DDTHH:MM:SS":
              //2020-11-16T15:00:00
              months = (str.getMonth()+1);
              if (months < 10) months = "0" + months
              years = str.getFullYear();
              if (years < 10) years = "0" + years
              days = (str.getDate());
              if (days < 10) days = "0" + days
              hours = str.getHours()
              if (hours < 10) hours = "0" + hours
              minutes = str.getMinutes()
              if (minutes < 10) minutes = "0" + minutes
              seconds = str.getSeconds()
              if (seconds < 10) seconds = "0" + seconds
              dateStr = years + "-" + months + "-" + days + "T" + hours + ":" + minutes + ":" + seconds;
              break;

            case "YYYY-MM-DDTHH:mm":
                months = (str.getMonth()+1);
                if (months < 10) months = "0" + months
                years = str.getFullYear();
                if (years < 10) years = "0" + years
                days = (str.getDate());
                if (days < 10) days = "0" + days
                hours = str.getHours()
                if (hours < 10) hours = "0" + hours
                minutes = str.getMinutes()
                if (minutes < 10) minutes = "0" + minutes
                dateStr = years + "-" + months + "-" + days + "T" + hours + ":" + minutes;
                break;

            case "HHMM":
              hours = str.getHours()
              minutes = str.getMinutes()
              if (hours < 10) hours = "0" + hours;
              if (minutes < 10) minutes = "0" + minutes
              dateStr = hours + "" + minutes;
              break;

            case "YYYYMMDD":
              months = (str.getMonth()+1);
              days = (str.getDate());
              years = str.getFullYear();
              if (months < 10) months = "0" + months
              if (days < 10) days = "0" + days
              dateStr = years + "" + months + "" + days;
              break;

            default:
                months = (str.getMonth()+1);
                if (months < 10) months = "0" + months
                years = str.getFullYear();
                if (years < 10) years = "0" + years
                days = (str.getDate());
                if (days < 10) days = "0" + days
                hours = str.getHours()
                if (hours < 10) hours = "0" + hours
                minutes = str.getMinutes()
                if (minutes < 10) minutes = "0" + minutes
                seconds = str.getSeconds()
                if (seconds < 10) seconds = "0" + seconds
                milliseconds = str.getMilliseconds();
                if (milliseconds < 10) milliseconds = "00" + milliseconds
                else if (milliseconds < 100) milliseconds = "0" + milliseconds
                dateStr = years + "-" + months + "-" + days + " " + hours + ":" + minutes + ":" + seconds + "." + milliseconds
                break;

        }

        return dateStr;

    }

    /**
     * Generates a random string
     * @param length length of the random string
     * @returns random stright
     */
    static randomStr(length) {

    	let text = "";
    	let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    	for (let i = 0; i < length; i++)
    		text += possible.charAt(Math.floor(Math.random() * possible.length));

    	return text;

    }

    /**
     * Determines whether the two dates are in the same minute
     * @param date date 1
     * @param date2 date 2
     * @returns whether the two dates are in the same minute
     */
    static isSameMinute(date1: Date, date2: Date): boolean {
      return (date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate() && date1.getHours() === date2.getHours() && date1.getMinutes() === date2.getMinutes())
    }

    /**
     * Validates a date
     * @param d date to validate
     * @returns whether the data is valid
     */
    static isValidDate(d: Date):boolean {
        // @ts-ignore: Not assignable error
        return d instanceof Date && !isNaN(d);
    }

    /**
     * Checks whether a date is a workday
     * @param date date to check
     * @returns whether the data is a workday
     */
    static isWorkingDay(d: Date):boolean {
        return !(d.getDay() === 6 || d.getDay() === 0);
    }

    /**
     * Validates a date
     * @param date date to validate
     * @returns whether the data is valid
     */
    static uuidv4():string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    static wordsSimilarity(word1, word2) {
      const stringSimilarity = require("string-similarity");
      return parseFloat(stringSimilarity.compareTwoStrings(word1, word2).toFixed(2));
    }

    /**
     * Decrements the given date with the specified amount of unites
     * @param date date to mutate
     * @param interval interval
     * @param units number of unites to substract
     * @returns mutated dated
     */
    static dateSub(date: Date, interval: string, units: number) {
        var ret = new Date(date); //don't change original date
        var checkRollover = function() { if(ret.getDate() !== date.getDate()) ret.setDate(0);};
        switch(interval.toLowerCase()) {
        case 'year'   :  ret.setFullYear(ret.getFullYear() - units); checkRollover();  break;
        case 'quarter':  ret.setMonth(ret.getMonth() - 3*units); checkRollover();  break;
        case 'month'  :  ret.setMonth(ret.getMonth() - units); checkRollover();  break;
        case 'week'   :  ret.setDate(ret.getDate() - 7*units);  break;
        case 'day'    :  ret.setDate(ret.getDate() - units);  break;
        case 'hour'   :  ret.setTime(ret.getTime() - units*3600000);  break;
        case 'minute' :  ret.setTime(ret.getTime() - units*60000);  break;
        case 'second' :  ret.setTime(ret.getTime() - units*1000);  break;
        default       :  ret = undefined;  break;
        }
        return ret;
    }

    static toEmoji(str) {
      if (str === undefined || str === null || str === '') {
        return str;
      }
  
      if (typeof str !== 'string') {
        str = str.toString();
      }
  
      return str
        .replace(/0/g, '0️⃣')
        .replace(/1/g, '1️⃣')
        .replace(/2/g, '2️⃣')
        .replace(/3/g, '3️⃣')
        .replace(/4/g, '4️⃣')
        .replace(/5/g, '5️⃣')
        .replace(/6/g, '6️⃣')
        .replace(/7/g, '7️⃣')
        .replace(/8/g, '8️⃣')
        .replace(/9/g, '9️⃣');
    }

    static onDrawerShow() {
      document.body.classList.remove("backdrop-no-scroll");
      setTimeout(() => { document.body.classList.remove("backdrop-no-scroll"); }, 500)
      setTimeout(() => { document.body.classList.remove("backdrop-no-scroll"); }, 1000)
      setTimeout(() => { document.body.classList.remove("backdrop-no-scroll"); }, 1500)
    }

    /**
     * Increments the given date with the specified amount of unites
     * @param date date to mutate
     * @param interval interval
     * @param units number of unites to add
     * @returns mutated dated
     */
    static dateAdd(date: Date, interval: string, units: number) {
        var ret = new Date(date); //don't change original date
        var checkRollover = function() { if(ret.getDate() !== date.getDate()) ret.setDate(0);};
        switch(interval.toLowerCase()) {
        case 'year'   :  ret.setFullYear(ret.getFullYear() + units); checkRollover();  break;
        case 'quarter':  ret.setMonth(ret.getMonth() + 3*units); checkRollover();  break;
        case 'month'  :  ret.setMonth(ret.getMonth() + units); checkRollover();  break;
        case 'week'   :  ret.setDate(ret.getDate() + 7*units);  break;
        case 'day'    :  ret.setDate(ret.getDate() + units);  break;
        case 'hour'   :  ret.setTime(ret.getTime() + units*3600000);  break;
        case 'minute' :  ret.setTime(ret.getTime() + units*60000);  break;
        case 'second' :  ret.setTime(ret.getTime() + units*1000);  break;
        default       :  ret = undefined;  break;
        }
        return ret;
    }

    static inRange(ranges: Event[], date: Date) {
      for (let i = 0; i < ranges.length; i++) {
        if (new Date(ranges[i].start) <= date && date <= new Date(ranges[i].end)) {
          return {
            start: new Date(ranges[i].start),
            end: new Date(ranges[i].end),
            id: ranges[i].id,
            title: ranges[i].title
          };
        }
      }
      return false;
    }

    /**
     * Returns events in the given time range
     * @param timelineStart start from date
     * @param timelineEnd end from date
     * @param testEvents prefill with the supplied test events
     * @param getLastAvailable whether to return the last available date
     * @returns event timeline
     */
    static async generateEvents(timelineStart: Date, timelineEnd: Date, testEvents?: Event[], getLastAvailable?: boolean, lazyLoadDays?: boolean): Promise<Object> {

        // console.log("generateEvents(" + timelineStart + ", " + timelineEnd + ")")

        if (window.location.href.indexOf("testing=true") !== -1) {
          timelineStart = Utilities.dateSub(new Date(), "year", 10);
          timelineEnd = Utilities.dateAdd(new Date(), "year", 10);
        }

        let events:Event[];
        let inferredUnavailableRanges: Event[] = [];
        let eventCount: number = 0;
        let eventInstances: number = 0;
        
        // Loading events from test
        if (testEvents) {
          events = testEvents;
        } else {
          events = await getAllEvents();
          if (getLastAvailable) {
            inferredUnavailableRanges = await getInferredUnavailabilityDates();
          } 
        };

        console.log(inferredUnavailableRanges, "<<<<<<<")
    
        // Processing timeline
        let newTimeline:Object = {};
        let newAvailabilityCircle:Object = {};
    
        let currentDaySlot:Date = timelineStart;
        currentDaySlot.setHours(23);
        currentDaySlot.setMinutes(59);
        currentDaySlot.setSeconds(59);
    
        // if (!lazyLoadDays) {
          while (currentDaySlot < timelineEnd) {
            let dateHash = Utilities.formatDate(currentDaySlot, "YYYY-MM-DD");
            newTimeline[dateHash] = []
            newAvailabilityCircle[dateHash] = [];
            currentDaySlot = Utilities.dateAdd(currentDaySlot, "day", 1);
          }
        // }
    
        for (let i = 0; i < events.length; i++) {
    
          let element = events[i];

          let dateHash = Utilities.formatDate(new Date(element.start), "YYYY-MM-DD");

          // if (lazyLoadDays && !newTimeline[dateHash]) {
          //   newTimeline[dateHash] = []
          //   newAvailabilityCircle[dateHash] = [];
          // }
    
          // If out of range and not repetition then skip
          if (!newTimeline[dateHash] && element.repeatType === 0) {
            continue;
          }
    
          if (element.repeatType) {
    
            let currentDaySlot:Date = new Date(element.start);
    
            // We start with increase in loop so go back one day
            currentDaySlot = Utilities.dateSub(currentDaySlot, "day", 1);
            let repeatDateHash:string = dateHash;
            let i:number = 0;

            if (element.title === "PAST") {
              console.log("@@@@@@ GOING FROM " + currentDaySlot + " - " + timelineEnd, element);
            }
    
            while (currentDaySlot < timelineEnd) {

              if (element.title === "PAST") {
                console.log("@@@@@@ AT " + currentDaySlot);
              }
    
              let elementCopy: Event = Object.assign({}, element);
    
              if (i === 0 && (elementCopy.repeatType > 2) ) {
                // Month or week, go back without skipping first
                currentDaySlot = Utilities.dateAdd(currentDaySlot, "day", 1);

                let currentEventDaySlot: Date = new Date(elementCopy.start);
                // Pagination might start on a non-repeating day, we have to skio over to the closest repeat
                while (currentEventDaySlot < currentDaySlot) {
                  if (elementCopy.repeatType === 3) {
                    currentEventDaySlot = Utilities.dateAdd(currentEventDaySlot, "week", 1);
                  } else if (elementCopy.repeatType === 4) {
                    currentEventDaySlot = Utilities.dateAdd(currentEventDaySlot, "month", 1);
                  }
                }
                currentDaySlot = currentEventDaySlot // Utilities.dateSub(currentEventDaySlot, "day", Math.round(Utilities.differenceBetweenDatesSeconds(currentEventDaySlot, currentDaySlot)/86400));

              } else {
    
                switch (elementCopy.repeatType) {
                  // Weekday
                  case 1:
                    currentDaySlot = Utilities.dateAdd(currentDaySlot, "day", 1);
                    if (!Utilities.isWorkingDay(currentDaySlot)) {
                      // Skip adding if not workday
                      continue;
                    }
                    break;
                  // Every day
                  case 2:
                    currentDaySlot = Utilities.dateAdd(currentDaySlot, "day", 1);
                    break;
                  // Every week
                  case 3:
                    currentDaySlot = Utilities.dateAdd(currentDaySlot, "week", 1);
                    break;
                  // Every month
                  case 4:
                    currentDaySlot = Utilities.dateAdd(currentDaySlot, "month", 1);
                    break;
                }
    
              }
    
              elementCopy.start = currentDaySlot.toISOString();
    
              repeatDateHash = Utilities.formatDate(currentDaySlot, "YYYY-MM-DD");
    
              // Visible timeline is out of range, and not repetition, so exit loop
              if (!newTimeline[repeatDateHash] && elementCopy.repeatType === 0) {
                break;
              }

              let currentDaySlotForCompare: Date = new Date(currentDaySlot);
              currentDaySlotForCompare.setHours(23);
              currentDaySlotForCompare.setMinutes(59);
              currentDaySlotForCompare.setSeconds(59);
    
              // End if repeatUntil date is less than current day
              if (elementCopy.repeatUntil && !elementCopy.repeatForever && (Utilities.dateAdd(new Date(elementCopy.repeatUntil), "day", 1) < currentDaySlotForCompare)) {
                console.log("@@@@@@@@@ OUT OF RANGE")
                break;
              }
    
              if (!newTimeline[repeatDateHash]) {
                continue;
              }
    
              // Overnight event
              if (new Date(element.start).getDate() !== new Date(element.end).getDate()) {
                
                // Cloning for start day of the overnight event
                let elementStartDay: Event = Object.assign({}, elementCopy);
                let elementStartDayEnd: Date = new Date(elementStartDay.end);
                // Ending at midnight
                elementStartDayEnd.setHours(23);
                elementStartDayEnd.setMinutes(59);
                elementStartDay.end = elementStartDayEnd.toISOString();
                elementStartDay.overnight = true;
                newTimeline[repeatDateHash].push(Object.assign({}, elementStartDay))
                eventCount++;
    
                // Cloning for start day of the overnight event
                let elementEndDay: Event = Object.assign({}, elementCopy);
                let elementEndDayStart: Date = new Date(elementEndDay.end);
                // Starting at midnight
                elementEndDayStart.setHours(0);
                elementEndDayStart.setMinutes(1);
                elementEndDay.start = elementEndDayStart.toISOString();
                elementEndDay.overnight = true;
                
                let nextDaySlotHash: string = Utilities.formatDate(Utilities.dateAdd(new Date(repeatDateHash), "day", 1), "YYYY-MM-DD");
                if (newTimeline[nextDaySlotHash]) {
                  newTimeline[nextDaySlotHash].push(Object.assign({}, elementEndDay))
                  eventCount++;
                }
    
              } else {
                newTimeline[repeatDateHash].push(Object.assign({}, elementCopy));
                eventCount++;
              }
    
              i++
    
            }
    
          } else {
    
            // Overnight event
            if (new Date(element.start).getDate() !== new Date(element.end).getDate()) {
    
              // Cloning for start day of the overnight event
              let elementStartDay: Event = Object.assign({}, element);
              let elementStartDayEnd: Date = new Date(elementStartDay.end);
              // Ending at midnight
              elementStartDayEnd.setHours(23);
              elementStartDayEnd.setMinutes(59);
              elementStartDay.end = elementStartDayEnd.toISOString();
              elementStartDay.overnight = true;
              newTimeline[dateHash].push(Object.assign({}, elementStartDay))
              eventCount++;
    
              // Cloning for start day of the overnight event
              let elementEndDay: Event = Object.assign({}, element);
              let elementEndDayStart: Date = new Date(elementEndDay.end);
              // Starting at midnight
              elementEndDayStart.setHours(0);
              elementEndDayStart.setMinutes(1);
              elementEndDay.start = elementEndDayStart.toISOString();
              elementEndDay.overnight = true;
              
              let nextDaySlotHash: string = Utilities.formatDate(new Date(elementEndDay.end), "YYYY-MM-DD");
              if (newTimeline[nextDaySlotHash]) {
                newTimeline[nextDaySlotHash].push(Object.assign({}, elementEndDay))
                eventCount++;
              }
    
            } else {
              newTimeline[dateHash].push(Object.assign({}, element))
              eventCount++;
            }
    
          }
    
        }
    
        let now = new Date();
    
        // Overlapping work over available dates if clash and order
        for (let key in newTimeline) {
    
          let day: Event[] = newTimeline[key];
          let deletedAvailableEvents: Event[] = [];
    
          if (day.length !== 0) {
    
            // Order
            day = day.sort((a, b) => new Date(a.start) > new Date(b.start) ? 1 : -1)
    
            // Reconsiliation of clashes
            let availableEvents: Event[] = [];
            let workEvents: Event[] = [];
    
            for (let i = 0; i < day.length; i++) {
              if (day[i].eventType === 4) {
                availableEvents.push(day[i] as Event)
              } else {
                workEvents.push(day[i] as Event)
              }
            }
    
            // Going over available dates
            for (let i = 0; i < availableEvents.length; i++) {
    
              let aEvent: Event = Object.assign({}, availableEvents[i]);
              // Going over available dates
    
              for (let j = 0; j < workEvents.length; j++) {
    
                // We might go out of bound when we split up availabilities and skip back
                if (i < 0 || j < 0)
                  continue;
    
                let wEvent: Event = Object.assign({}, workEvents[j]);
                let aEventStart = new Date(aEvent.start)
                let aEventEnd = new Date(aEvent.end)
                let wEventStart = new Date(wEvent.start)
                let wEventEnd = new Date(wEvent.end)
    
                wEventStart.setFullYear(now.getFullYear())
                wEventStart.setMonth(now.getMonth(), now.getDate())
                wEventEnd.setFullYear(now.getFullYear())
                wEventEnd.setMonth(now.getMonth(), now.getDate())
                aEventStart.setFullYear(now.getFullYear())
                aEventStart.setMonth(now.getMonth(), now.getDate())
                aEventEnd.setFullYear(now.getFullYear())
                aEventEnd.setMonth(now.getMonth(), now.getDate())

                // Removing the extra randomness from the id that is used to have unique keys for the same repeating events on the timeline
                availableEvents[i].id = aEvent.id.split("|")[0] + "|" + Utilities.randomStr(24);
                workEvents[j].id = wEvent.id.split("|")[0] + "|" + Utilities.randomStr(24);

                if (
                  (wEventStart > aEventStart && wEventEnd < aEventEnd) || // Available completely overlaps the work event
                  ((wEventStart < aEventStart || (wEventStart.getHours() === aEventStart.getHours() && wEventStart.getMinutes() === aEventStart.getMinutes())) && (wEventEnd > aEventEnd || (wEventEnd.getHours() === aEventEnd.getHours() && wEventEnd.getMinutes() === aEventEnd.getMinutes()))) || // Work completely overlaps the available event
                  (wEventEnd > aEventStart && !(wEventStart > aEventStart)) || // Begining overlaps but not all the way to the end
                  (wEventStart < aEventEnd && !(wEventEnd < aEventEnd)) // End overlaps but not from the beginning
                ) {
                  
                  if (wEventStart > aEventStart && wEventEnd < aEventEnd) {
                    console.log("@@@@@ 1. Available completely overlaps the work event => Split into two availables")
                    let dupeAvailableEventToEnd: Event = Object.assign({}, aEvent); // Clone availability event
                    dupeAvailableEventToEnd.start = (new Date(wEvent.end)).toISOString(); // Change the start of the availabile to the end of the work event
                    dupeAvailableEventToEnd.id = dupeAvailableEventToEnd.id.split("|")[0] + "|" + Utilities.randomStr(24);
                    availableEvents[i].end = (new Date(wEvent.start)).toISOString(); // Change the end of the availabile to the start of the work event
                    availableEvents.splice(i, 0, dupeAvailableEventToEnd); // Append the duplicate available event to the right place
                    i--;
                  } else if ((wEventStart < aEventStart || (wEventStart.getHours() === aEventStart.getHours() && wEventStart.getMinutes() === aEventStart.getMinutes())) && (wEventEnd > aEventEnd || (wEventEnd.getHours() === aEventEnd.getHours() && wEventEnd.getMinutes() === aEventEnd.getMinutes()))) {
                    console.log("@@@@@ 2. Work completely overlaps the available event => Delete available date");
                    deletedAvailableEvents.push(JSON.parse(JSON.stringify(availableEvents[i])));
                    availableEvents.splice(i, 1); // Delete available date
                    i--;
                  } else if (wEventEnd > aEventStart && !(wEventStart > aEventStart)) {
                    console.log("@@@@@ 3. Begining overlaps but not all the way to the end => Change available start date to work end")
                    availableEvents[i].start = (new Date(wEvent.end)).toISOString();
                  } else if (wEventStart < aEventEnd && !(wEventEnd < aEventEnd)) {
                    console.log("4@@@@@ . End overlaps but not from the beginning => Change available end date to work start")
                    availableEvents[i].end = (new Date(wEvent.start)).toISOString();
                  }
    
                } else {
                  console.log("@@@@@ 0. No overlap whatsoever")
                }
    
              }
    
            }
    
            // We may have created duplicates of availabilites, so we concat the work and available events
            day = workEvents.concat(availableEvents)
    
            for (let i = 0; i < day.length; i++) {
              const element: Event = day[i];
              // Deleting short events that was created after overlaying work events over available events
              if (element.eventType === 4 && Utilities.differenceBetweenDatesSeconds(new Date(element.start), new Date(element.end)) < 300) {
                day.splice(i, 1);
                i--;
              } 
            }

            // TODONOW restore placeholders
            // if (day[0] && Utilities.isSameDate(new Date(day[0].start), new Date(lastAvailableDate)) && deletedAvailableEvents.length && !day.find(item => item.eventType === 4)) { 
            //   day = day.concat(deletedAvailableEvents.map(item => {
            //     item.lastAvailableDate = true;
            //     return item;
            //   }));
            // }

            day = day.map(item => {
              let starta: Date = new Date(key);
              starta.setHours(new Date(item.start).getHours());
              starta.setMinutes(new Date(item.start).getMinutes());
              item.start = starta.toISOString();
              return item
            })
    
            // Order
            day = day.sort((a, b) => {
              // TODONOW restore placeholders
              // if (a.lastAvailableDate || b.lastAvailableDate) {
              //   return a.lastAvailableDate ? -1 : 1;
              // }
              return new Date(a.start) > new Date(b.start) ? 1 : -1
            });
    
            newTimeline[key] = day;
            newAvailabilityCircle[key] = [];

            let atMinute: number = 0;
    
            // Compiling ranges needed for the availabilit circle
            for (let i = 0; i < day.length; i++) {
              const ev: Event = day[i];
              if (i !== 0) {
                let prevEv: Event = day[i-1];
                // If more than 5 mins gap push transparent gap
                let eventStart: Date = new Date(prevEv.start);
                let eventEnd: Date = new Date(prevEv.end);
                if (!Utilities.areDatesAreOnSameDay(eventStart, eventEnd))  {
                  eventEnd.setMonth(eventStart.getMonth(), eventStart.getDate());
                  eventEnd.setFullYear(eventStart.getFullYear());
                }
                let diff: number = Utilities.differenceBetweenDatesSeconds(eventEnd, new Date(ev.start))/60;
                if (diff > 5) {
                  newAvailabilityCircle[key].push({ start: atMinute, end: atMinute + diff, type: 0 } as AvailabilityCircleSlice);
                  atMinute += diff;
                }
              }
              let eventStart: Date = new Date(ev.start);
              let eventEnd: Date = new Date(ev.end);
              if (!Utilities.areDatesAreOnSameDay(eventStart, eventEnd))  {
                eventEnd.setMonth(eventStart.getMonth(), eventStart.getDate());
                eventEnd.setFullYear(eventStart.getFullYear());
              }
              let length: number = Math.round(Utilities.differenceBetweenDatesSeconds(eventStart, eventEnd) / 60);
              newAvailabilityCircle[key].push({ start: atMinute, end: atMinute + length, type: ev.eventType } as AvailabilityCircleSlice);
              atMinute += length;
            }
    
          }
        }

        return {
          timeline: newTimeline,
          availabilityCircle: newAvailabilityCircle,
          eventCount: eventCount,
          eventInstanceCount: events.length,
          inferredUnavailableRanges: inferredUnavailableRanges
        };

    }

    /**
     * Checks whether a new event is colliding with any of the existing events
     * @param start start date and time of new event
     * @param end end date and time of new event
     * @param id id of the existing event (if editing)
     * @param type type of new event
     * @returns whether the edited event is colliding with any other events
     */
    static async isEventColliding(start: Date, end: Date, id: string, type: number, ignoreAvailabilityOverWork?: boolean, repeatingForever?: boolean) : Promise<CollidingEvent[] | string> {

      // return [] // todo

      // @ts-ignore
      let calendarEntries: Object = store.getState().eventCache;
      // console.log("calendarEntries 1: ", calendarEntries);

      if (!calendarEntries) {
          // @ts-ignore
          calendarEntries = ((await Utilities.generateEvents(Utilities.dateSub(new Date(), "year", 2), Utilities.dateAdd(new Date(), "year", 5), null, null, true)).timeline);
          // console.log("calendarEntries 2: ", calendarEntries);
      }

      // console.log(calendarEntries)
      updateEventCache(calendarEntries);

      let dateHash: string = Utilities.formatDate(start, "YYYY-MM-DD");
      let day: Event[] = calendarEntries[dateHash];

      // The day is not in the -2 years +5 years frame
      if (!day) {
          if (new Date(dateHash) < Utilities.dateSub(new Date(), "year", 2)) {
              // Cannot add in the past
              return [null];
          } else {
              // Out of the 5 year window, let's assume there is no collision
              return [null];
          }
      }

      // Nothing to clash with
      if (day.length === 0) {
          return [null]
      }

      let collisions: CollidingEvent[] = [];
      // let availableOverWorkAcknowledged: boolean = null;

      for (let i = 0; i < day.length; i++) {

          const existingEvent: Event = day[i];
          let existingEventStart: Date = new Date(existingEvent.start);
          let existingEventEnd: Date = new Date(existingEvent.end);

          while (existingEventEnd < existingEventStart) {
              existingEventEnd = Utilities.dateAdd(existingEventEnd, "day", 1);
          }

          if (
              (existingEventStart > start && existingEventEnd < end) || // new completely overlaps the exiting event
              (existingEventStart < start && existingEventEnd > end) || // exiting completely overlaps the new event
              (existingEventEnd > start && !(existingEventStart > start)) || // Begining overlaps but not all the way to the end
              (existingEventStart < end && !(existingEventEnd < end)) // End overlaps but not from the beginning
          ) {

              // Overnight
              if (start.getHours() > end.getHours()) {
                  if (start > existingEventEnd && end < existingEventStart) {
                      return null
                  }
              }

              let resolutionMethod: "delete" | "merge" =
                (
                  (existingEventStart > start && existingEventEnd < end) || // Total overlap, nothing to salvage
                  (existingEventStart.getHours() === start.getHours() && existingEventStart.getMinutes() === start.getMinutes() && existingEventEnd.getHours() === end.getHours() && existingEventEnd.getMinutes() === end.getMinutes()) || // Exact overlap
                  (existingEventStart > start && existingEventEnd.getHours() === end.getHours() && existingEventEnd.getMinutes() === end.getMinutes()) || // Existing starts later, ends exactly same time
                  (existingEventStart.getHours() === start.getHours() && existingEventStart.getMinutes() === start.getMinutes() && existingEventEnd < end) || // Existing starts exactly same time, ends earlier
                  ((existingEvent.creator && existingEvent.creator.organisation) || existingEvent.organisationUrl) // cannot break up offers from hirers/agencies
                )
                  ? "delete"
                  : "merge"
              let allowedMergeToResolve = resolutionMethod !== "delete"
              let disallowedMergeReason = (existingEventStart > start && existingEventEnd < end) ? "overlap" : ((existingEvent.creator && existingEvent.creator.organisation) || existingEvent.organisationUrl) ? "offer" : null
              let confirmedOfferGroupId = existingEvent.groupId && ((existingEvent.creator && existingEvent.creator.organisation) || existingEvent.organisationUrl) ? existingEvent.groupId : null
              
              if (type === 4 && existingEvent.eventType === 4) {
                  // Available colliding with available
                  console.log("@@@ Available with available");
                  // if not editing or editing but not colliding with itself then collision
                  if (!id || (id && existingEvent.id.split("|")[0] !== id)) {
                      collisions.push({ 
                        date: new Date(dateHash), 
                        id: existingEvent.id.split("|")[0], 
                        name: existingEvent.title, 
                        resolutionMethod: resolutionMethod, 
                        allowedMergeToResolve: allowedMergeToResolve,
                        start: new Date(existingEvent.start),
                        end: new Date(existingEvent.end), 
                        eventType: existingEvent.eventType,
                        disallowedMergeReason: disallowedMergeReason,
                        confirmedOfferGroupId: confirmedOfferGroupId,
                        collisionType: "available-available"
                      }); // TODO cacluate best
                      // return [true, new Date(dateHash)];
                      if (existingEvent.repeatForever && repeatingForever) {
                        return "repeating-forever-clash";
                      }
                  }
              } else if (type !== 4 && existingEvent.eventType !== 4) {
                  // Work with work
                  console.log("@@@ Work with work")
                  if (!id || (id && existingEvent.id.split("|")[0] !== id)) {
                      collisions.push({ 
                        date: new Date(dateHash), 
                        id: existingEvent.id.split("|")[0], 
                        name: existingEvent.title, 
                        resolutionMethod: resolutionMethod, 
                        allowedMergeToResolve: allowedMergeToResolve,
                        start: new Date(existingEvent.start), 
                        end: new Date(existingEvent.end), 
                        eventType: existingEvent.eventType,
                        disallowedMergeReason: disallowedMergeReason,
                        confirmedOfferGroupId: confirmedOfferGroupId,
                        collisionType: "work-work"
                      }); // TODO cacluate best
                      // return [true, new Date(dateHash)];
                      if (existingEvent.repeatForever && repeatingForever) {
                        return "repeating-forever-clash";
                      }
                  }
              } else if (type === 4 && existingEvent.eventType !== 4) {
                  // Existing work with available
                  console.log("@@@ Existing work with available => Allowed, we will just overlap");
                  // if (availableOverWorkAcknowledged === null) {
                    if (!ignoreAvailabilityOverWork) {
                      return "availability-over-work";
                    }
                    // if (window.confirm("This available event is clashing with an existing work event, saving this event will only fill out empty space, but won't clear work events. If you want to remove your work event you must edit it manually. Tap Ok to save this available event")) {
                    //     availableOverWorkAcknowledged = true;
                    // } else {
                    //     availableOverWorkAcknowledged = false;
                    // }
                    collisions.push({ 
                      date: new Date(dateHash), 
                      id: existingEvent.id.split("|")[0], 
                      name: existingEvent.title, 
                      resolutionMethod: resolutionMethod, 
                      allowedMergeToResolve: allowedMergeToResolve,
                      start: new Date(existingEvent.start), 
                      end: new Date(existingEvent.end), 
                      eventType: existingEvent.eventType,
                      disallowedMergeReason: disallowedMergeReason,
                      confirmedOfferGroupId: confirmedOfferGroupId,
                      collisionType: "work-available"
                    });
                  // }
                  // return true;
              } else if (type !== 4 && existingEvent.eventType === 4) {
                  // Existing available with work - Only this one is allowd, but not returning as there might be a collision later
                  console.log("@@@ Existing available with work => Allowed, we will just overlap")
              }
          }
          
      }

      // Acknowledged availability over work, so save
      // if (availableOverWorkAcknowledged && collisions.length === 1) return null;

      if (ignoreAvailabilityOverWork && !collisions.find(c => c.collisionType !== "work-available")) {
        return [];
      }

      console.log("@@@ collisions: " + collisions.length, collisions);
      return collisions;

  }

  static manualUserPaging(): boolean {
    let body = document.getElementsByTagName("body");
    if (body && body[0]) {
      return body[0].classList.contains("allow-tour-paging")
    }
    return false;
  }

  static showTourPaging(show: boolean) {
    let body = document.getElementsByTagName("body");
    if (body && body[0]) {
      if (show) {
        body[0].classList.add("allow-tour-paging");
      } else {
        body[0].classList.remove("allow-tour-paging");
      }
    }
  }

  /**
   * Resized and rotates and image file
   * @param file file to resize
   * @returns resized file
   */
  static resizeAndRotateImage(file: Blob | string, maxLongerDimension: number = 512, square: boolean = false): Promise<File> {

    let dataURLtoFile = function(dataurl, filename) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
    }

    let resizeImage = function(base64) {

          let quality = 0.85;

          return new Promise(function(resolve, reject) {

              var canvas = document.createElement("canvas");
              var imgResize = document.createElement("img");

              imgResize.onerror = function(e) {
                  reject(e);
                  return;
              }
          
              imgResize.onload = function() {
          
                  var ctx = canvas.getContext("2d");

                  if (ctx) {

                    // drawer image to square by cutting down excess, and centering the image but make it equal height and width
                    if (square) {
                      var width = imgResize.width;
                      var height = imgResize.height;
                      var offsetX = 0;
                      var offsetY = 0;
                      if (width > height) {
                        offsetX = (width - height) / 2;
                        width = height;
                      } else {
                        offsetY = (height - width) / 2;
                        height = width;
                      }
                      canvas.width = width;
                      canvas.height = height;
                      ctx.drawImage(imgResize, offsetX, offsetY, width, height, 0, 0, width, height);
                    } else {

                      ctx.drawImage(imgResize, 0, 0);
                        var MAX_WIDTH = maxLongerDimension;
                        var MAX_HEIGHT = maxLongerDimension;
                        var width = imgResize.width;
                        var height = imgResize.height;
        
                        if (width > height) {
                            if (width > MAX_WIDTH) {
                              height *= MAX_WIDTH / width;
                              width = MAX_WIDTH;
                            }
                        } else {
                            if (height > MAX_HEIGHT) {
                                width *= MAX_HEIGHT / height;
                                height = MAX_HEIGHT;
                            }
                        }
        
                        canvas.width = Math.ceil(width);
                        canvas.height = Math.ceil(height);
                        ctx = canvas.getContext("2d");
                        if (ctx) 
                            ctx.drawImage(imgResize, 0, 0, Math.ceil(width), Math.ceil(height));
                    }
              
                    resolve(canvas.toDataURL("image/jpeg", quality));

                  }
          
              }
          
              imgResize.src = base64;

          })
      
      }

      return new Promise( async (resolve, reject) => {

        if (typeof file === "string") {
          let img = await resizeImage(file).catch(e => {
            reject((e))
          })
          if (img) {
            let file = dataURLtoFile(img, "1.jpeg");
            resolve(file);
          }
        } else {
          const reader = new FileReader();

          reader.addEventListener("load", async function () {
              let img = await resizeImage(reader.result).catch(e => {
                reject((e))
              })
              if (img) {
                let file = dataURLtoFile(img, "1.jpeg");
                resolve(file);
              }
          }.bind(this), false);

          reader.readAsDataURL(file);
        }

      })

  }

  static dynamicSort(property: string): (a: any, b: any) => number {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers, 
        * and you may want to customize it to your needs
        */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
  }

  static async getCurrentAddress(): Promise<string> {

    const os = (window as any).os || "web";

      // @ts-ignore
      const coordinates: {
        lat: number;
        lng: number;
      } = await (new Promise(async function(resolve, reject) {

          if (os === "web") {
              if (window.navigator) {
                  window.navigator.geolocation.getCurrentPosition(function(position) {
                  if (position.coords && position.coords.latitude && position.coords.longitude) {
                      resolve({lat: position.coords.latitude, lng: position.coords.longitude})
                  }}.bind(this), function(error) { reject(error) });
              } else {
                  reject("Not supported")
              }
          } else if (os === "android") {
              const position = await Geolocation.getCurrentPosition({enableHighAccuracy: false}).catch(e => { reject(e) });
              if (position)
                resolve({lat: position.coords.latitude, lng: position.coords.longitude})
          } else if (os === "ios") {
              let cbId = await Geolocation.watchPosition({enableHighAccuracy: true}, function(position, error) {
                  Geolocation.clearWatch({ id: cbId })
                  if (!error && position && position.coords && position.coords.latitude && position.coords.longitude) {
                      resolve({lat: position.coords.latitude, lng: position.coords.longitude})
                  } else {
                      reject(error)
                  }
              }.bind(this))
          }

      })).catch(e => {

      })

      if (coordinates && coordinates.lat && coordinates.lng && (os === "android" || os === "ios") && (nativegeocoder && nativegeocoder.reverseGeocode) ) {
        const address = await new Promise(function(resolve, reject) {
            nativegeocoder.reverseGeocode(function(res) {
                if (res && res[0]) resolve(res[0])
                else resolve(false)
            }, function(res) {
                resolve(false);
            }, coordinates.lat, coordinates.lng, { useLocale: true, maxResults: 1 });
        })
        // @ts-ignore
        return address && address.postalCode ? address.postalCode : null;
      } else {
        return null;
      }

  }

  static isElementInViewport(el): boolean {
    // Special bonus for those using jQuery
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }

    var rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
  }

}

export default Utilities;
