import axios, { AxiosRequestConfig } from "axios";
import { Plugins } from '@capacitor/core';
import { BackButtonEvent } from '@ionic/core/components';
import { setupIonicReact } from '@ionic/react';
import { IonAlert, IonApp, IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, isPlatform } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import './styles/all.scss';

import { calendarOutline, chatbubbleEllipsesOutline, cogOutline, fileTrayFull, fileTrayFullOutline, notificationsOutline, peopleOutline, personCircle, personCircleOutline, pieChart, pieChartOutline } from 'ionicons/icons';
import React from 'react';
import { Route } from 'react-router-dom';
import UserAPI from './apis/User';
import ChatAPI from './apis/Chat';
import Configuration from './Configuration';
import Logo from './images/icon.svg';
import Calendar from './pages/Calendar';
import Chat from './pages/Chat';
import HelpGuide from './pages/Components/Settings/HelpGuide';
import HelpGuides from './pages/Components/Settings/HelpGuides';
import Contacts from './pages/Contacts';
import ReferralsList from './pages/ReferralsList';
import ReferralsAdd from './pages/ReferralsAdd';
import Notifications from './pages/Notifications';
import Offer from './pages/Offer';
import Onboarding from './pages/Onboarding';
import OnboardingPreferences from './pages/OnboardingPreferences';
import ProfileSettings from './pages/ProfileSettings';
import PushNotificationManagement from './pages/PushNotificationManagement';
import Rating from './pages/Rating';
import Files from './pages/Files';
import OpenSection from './pages/OpenSection';
import Impersonation from './pages/Impersonation';
import LoginRedirect from './pages/Redirect';
import Settings from './pages/Settings';
import Insight from './pages/Me';
import TimePresets from './pages/TimePresets';
import EmailConfirmed from './pages/EmailConfirmed';
import VerifyEmail from './pages/VerifyEmail';
import ConfirmAvailability from './pages/ConfirmAvailability';
import RatingView from './pages/RatingView';
import ErrorPage from './pages/Error';
import { updateSettings } from './state/actions/Settings';
import { updateOutstandingNotifications } from './state/actions/Notifications';
import { updateNewRatings } from './state/actions/Ratings';
import store from './state/store';
import { InitStorage } from './stores/Index';
// import './styles/App.scss';
// import './styles/Desktop.scss';
import Sync from "./Sync";
import './theme/variables.css';
import { User, UserDTO } from './types/User';
import NotificationAPI from './apis/Notification';
import { NotificationListCombined } from './types/NotificationList';
import { useSelector } from 'react-redux';
import InternalTracker from './InternalTracker';
import { Auth } from './services/AuthService';
import loadedEnv from './env';
import { reactPlugin } from "./AppInsights.js";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import versionName from './versionName';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ChatService } from "./services/ChatService";
import { Tooltip } from 'reactstrap';
import TimelineClearup from './pages/TimelineClearup';
import Utilities from "./Utilities";
import Tracker from '@openreplay/tracker';
import trackerAssist from '@openreplay/tracker-assist';
import trackerProfiler from '@openreplay/tracker-profiler';
import { GrowthBook } from "@growthbook/growthbook";
import { getTotalEventsCount, hasUndeletedEvents } from "./stores/Event";
import { Device } from "@capacitor/device";
import { App } from "@capacitor/app";
import { PushNotifications } from "@capacitor/push-notifications";
import { Network } from "@capacitor/network";
import { LocalNotifications } from "@capacitor/local-notifications";
import posthog from 'posthog-js'

const signalR = require('@microsoft/signalr');
const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

interface PendingNotification {
  id: string;
  type: number;
  msg?: string;
  label: string;
}

let alreadyCalledRegistration = false;

const TRACKED_PATHS = {
  'notifications': "Notifications",
  'offer': "Offer",
  'calendar': "Calendar",
  'settings': "Settings",
  'contacts': "Contacts",
  'messages': "Messages",
  'insight': "Insight"
}

let lastNewRatingCount: any = null;
let lastNewNotificationCount: any = null;
let lastNewMessagesCount: any = null;

export class PwaApp extends React.Component<{}, { confirmUnblurringOfReportedUserId, showFilesTab, newFilesCount, reviewPrompt, signalRIniting, signalRInited, showNewRatingTooltip, showNewMessageTooltip, showNewNotificationTooltip, inited, version, user, newNotificationsCount, newRatingCount, newMessagesCount, url, pendingNotification: PendingNotification }> {

  constructor(props) {

    super(props);
    this.state = {
      inited: false,
      version: (window.innerWidth >= 724 && isPlatform("desktop")) ? "desktop" : "mobile",
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
      url: window.location.href,
      pendingNotification: null,
      newNotificationsCount: 0,
      newRatingCount: 0,
      newMessagesCount: 0,
      showNewNotificationTooltip: false,
      showNewMessageTooltip: false,
      showNewRatingTooltip: false,
      signalRInited: false,
      signalRIniting: false,
      reviewPrompt: null,
      newFilesCount: 0,
      showFilesTab: false,
      confirmUnblurringOfReportedUserId: null
    }

    store.dispatch(updateSettings({ 
      version: (window.innerWidth >= 724 && isPlatform("desktop")) ? "desktop" : "mobile",
      appVersion: CONFIG.VERSION + " (" + CONFIG.BUILD_NUMBER + ")",
      cypressTesting: (window.location.href.indexOf("testing=true") !== -1)
    }));

    (window as any).os = "web";
    (window as any).hiddenExternalOrgImages = {};
    (window as any).pictureVersions = 0;
    (window as any).unRepeatedEventTypeCounts = {};
    (window as any).abConfig = {
      "image": [
        { header: "Add your profile picture", body: "Be recognized & get more work offers" },
        { header: "Increase your chances of getting offer confirmations", body: "People with pictures are 13% more likely to be confirmed for an offer" }
      ],
      "headline": [
        { header: "Add an impressive headline", body: "Highlight your areas of expertise. Good headlines increase your contacts and offers received" },
        { header: "Increase your chances of getting offer confirmations", body: "People with short headlines are 4% more likely to be confirmed for an offer" }
      ],
      "postalcode": [
        { header: "Prefer to work locally?", body: "Add your Zip/Postcode & proximity for more local offers" },
        { header: "Get offers closer to your home", body: "People who enter their postcode need to travel up 45km / month less" }
      ],
      "sectorssubsectors": [
        { header: "Add your sector skills", body: "Get more suitable offers by adding your sector skills" },
        { header: "Only relevant offers please", body: "People who enter their sectors, tend to reject offers 12% less" }
      ],
      "smsnotifications": [
        { header: "Add your mobile number to never miss offers", body: "Add your mobile number to avoid missing any offers when there is no internet or data connection" },
        { header: "No internet? No problem", body: "Get instantly notified of new offers via SMS, and reply with a simple yes to apply" }
      ],
      "qualifications": [
        { header: "Add your qualifications to get better offers", body: "Select or add your qualifications to get more suitable offers" },
        { header: "Let people know what you achieved", body: "Select your qualifications, so your contacts can see what you can be hired for" }
      ],
      "files": [
        { header: "Showcase your skills & successes in your portfolio", body: "Showcase your skills, by uploading documents and links to work you’ve completed in the past. Good quality portfolios secure more offers" },
        { header: "Widen your circles", body: "Upload public documents of your work, qualifications, and link documents for potential contacts" }
      ]
    }

    Device.getInfo().then(async function(deviceInfo) {
      (window as any).os = deviceInfo.platform;
    })

    // if (isPlatform("desktop")) {
      setInterval(() => {
        if (window.location.href !== this.state.url) {
          const pathname =  window.location.pathname.replace("/", "").split("/")[0];
          this.setState({
            url: window.location.href
          })
          this.initSignalR();
        }
      }, 300);
    // }

    // Disabling animation on desktop view
    // if (window.innerWidth >= 724) {
      
    // }

    if (!localStorage.getItem("animation")) {
      setupIonicReact({ animated: false });
    }

    // Adding push notification events
    if (!isPlatform("desktop") && (window as any).os !== "web") {
      PushNotifications.addListener('pushNotificationReceived', (notification) => { this.handleNotification(notification) } );
      PushNotifications.addListener('pushNotificationActionPerformed', (notification) => { this.handleNotification(notification.notification, true) } );
    }

  }

  reloadContactable() {
    ChatAPI.getContactable().catch(e => console.log);
  }

  reloadUserProfile() {
    Utilities.getUserProfile().then(user => {
      
      if (user.appVersion !== versionName && !localStorage.getItem("admin_access_token")) {
        UserAPI.update({ appVersion: versionName } as UserDTO).catch(e => { console.error(e); })
      }

      if (user.ratings) {
        const ratings = user.ratings;
        let existingRatings: number[] = localStorage.getItem("existingRatings") ? JSON.parse(localStorage.getItem("existingRatings")) : [];

        let notYetSeen = [];
        for (let i = 0; i < ratings.length; i++) {
          if (existingRatings.indexOf(ratings[i].id) === -1) {
            notYetSeen.push(ratings[i]);
          }
        }

        store.dispatch(updateNewRatings(notYetSeen));
      }

      if (window.location.href.indexOf("/insight") !== -1) {
        if ((window as any).reloadInsightsOnPage) {
          (window as any).reloadInsightsOnPage();
        }
      }

      if (user.userType === 0) {
        UserAPI.setWorkerUserType();
      }

    }).catch(data => {
      console.error("Failed to get user");
    })
  }

  reloadNotifications() {
    if (localStorage.getItem("user")) {
      Utilities.getNotifications().then(data => {
        let notifications: NotificationListCombined = data;
        let existingNotifications: string[] = localStorage.getItem("existingNotifications") ? JSON.parse(localStorage.getItem("existingNotifications")) : [];
        let allOutstandingNotifications = [];
        if (notifications.offersConfirmed) { for (let i = 0; i < notifications.offersConfirmed.length; i++) { allOutstandingNotifications.push(Utilities.offerHash(notifications.offersConfirmed[i])) } }
        if (notifications.offersRejected) { for (let i = 0; i < notifications.offersRejected.length; i++) { allOutstandingNotifications.push(Utilities.offerHash(notifications.offersRejected[i])) } }
        if (notifications.offersPending) { for (let i = 0; i < notifications.offersPending.length; i++) { allOutstandingNotifications.push(Utilities.offerHash(notifications.offersPending[i])) } }
        if (notifications.offersWithdrawn) { for (let i = 0; i < notifications.offersWithdrawn.length; i++) { allOutstandingNotifications.push(Utilities.offerHash(notifications.offersWithdrawn[i])) } }
        if (notifications.offersApplied) { for (let i = 0; i < notifications.offersApplied.length; i++) { allOutstandingNotifications.push(Utilities.offerHash(notifications.offersApplied[i])) } }
        if (notifications.offerUpdates) { for (let i = 0; i < notifications.offerUpdates.length; i++) { allOutstandingNotifications.push(Utilities.offerHash(notifications.offerUpdates[i])) } }
        if (notifications.events) { for (let i = 0; i < notifications.events.length; i++) { allOutstandingNotifications.push(Utilities.offerHash(notifications.events[i])) } }
        if (notifications.shareRequests) { for (let i = 0; i < notifications.shareRequests.length; i++) { allOutstandingNotifications.push(notifications.shareRequests[i].id) } }
        if (notifications.referrals) { for (let i = 0; i < notifications.referrals.length; i++) { allOutstandingNotifications.push(notifications.referrals[i].id) } }
        if (notifications.offersConfirmedWorkerCancelled) { for (let i = 0; i < notifications.offersConfirmedWorkerCancelled.length; i++) { allOutstandingNotifications.push(Utilities.offerHash(notifications.offersConfirmedWorkerCancelled[i])) } }
        let notYetSeen = [];
        for (let i = 0; i < allOutstandingNotifications.length; i++) {
          if (existingNotifications.indexOf(allOutstandingNotifications[i]) === -1) {
            notYetSeen.push(allOutstandingNotifications[i]);
          }
        }
        store.dispatch(updateOutstandingNotifications(notYetSeen));
        localStorage.setItem("notifications", JSON.stringify(notifications));
      }).catch(e => { })
    }
    
  }

  // Executes the notification (immediate if coming from notification tap, need to confirm to open if in app)
  executeNotification(notification: PendingNotification) {

    let type: number = typeof notification.type === "string" ?  parseInt(notification.type) : notification.type;

    switch(type) {

      // Message
      case 0:
        // if (notification.msg) {
        //   alert(notification.msg);
        // }
        break;

      // Offer
      case 1:
        if (notification.id) {
          window.location.href = "/offer/" + notification.id;
        } else {
          console.error("No id")
        }
        break;

      // Contact Request
      case 2:
        window.location.href = "/notifications"
        break;
      
      // Message
      case 3:
        window.location.href = "/messages/" + notification.id + "/auto"
        break;

      // Direct Offer
      case 6:
        if (notification.id) {
          window.location.href = "/directoffer/" + notification.id;
        } else {
          console.error("No id")
        }
        break;

      // Rating
      case 8:
        window.location.href = "/rating/" + notification.id;
        break;

      // Rating request
      case 9: 
        window.location.href = "/contacts/" + notification.id + "#rate";
        break;

      // File
      case 10:
        if (notification.msg.indexOf(" requested access ") !== -1) {
          window.location.href = "/notifications"
        } else {
          window.location.href = "/files/file/" + notification.id;
        }
        break;

      default:
        console.error("Unknown notification type " + notification.type);
        break;

    }

  }

  resetNotificationCount = async () => {
    // if (!isPlatform("mobileweb") && ( isPlatform("android") || isPlatform("ios") )) {
    //   const check = await FancyNotifications.hasPermission();
    //   if (check.value) {
    //     FancyNotifications.setBadgeCount(0);
    //   }
    // }
  }

  onFocus = () => {
    // Reloading for new day
    if (!Utilities.isSameDate(new Date(), (window as any).lastDateFocused)) {
      window.location.reload();
    }

    this.resetNotificationCount();
    if (localStorage.getItem("user") && window.location.href.indexOf("testing=true") === -1) {
      this.reloadNotifications();
      if ((window as any).Sync && (window as any).Sync.sync) {
        (window as any).Sync.sync();
      } 
    }

    this.initSignalR();
    Utilities.scheduleNotifications();
  }

  // Creaters a unified notification object ready to be executd on iOS and Android
  handleNotification(notification: object, execute?: boolean) {

    let id: string = null;
    let type: number = null;
    let msg: string = null;
    let label: string = null;

    if (isPlatform("android")) {
      // @ts-ignore
      id = notification.data.id; type = parseInt(notification.data.type); msg = notification.data.msg; label = "New Notification"; // notification.body;
    } else if (isPlatform("ios")) {
      // @ts-ignore
      id = notification.data.id; type = parseInt(notification.data.type); msg = notification.data.msg; label = "New Notification"; // notification.body
    } else {
      return;
    }

    let pendingNotification: PendingNotification = {
      type: type,
      id: id,
      msg: msg,
      label: label,
    }

    if (execute) {
      this.executeNotification(pendingNotification)
    } else {
      if (type === 7 || type === 8 || type === 10 || type === undefined || type === null) {
        // Do nothing on badge reset notifications, or other new notifications
      } else if (type === 3 && window.location.href.indexOf("/messages") !== -1) {
        // Do nothing if on messages and new message arrives
      } else {
        this.setState({ pendingNotification: pendingNotification })
      }
    }

  }

  onFeatureEnabled(feature) {
    const callbackFuncName = 'onFeatureEnabled' + feature;
    if (window[callbackFuncName]) {
        window[callbackFuncName]();
    }
    const callbackFuncName2 = 'onFeatureEnabled' + feature + "2";
    if (window[callbackFuncName2]) {
        window[callbackFuncName2]();
    }
    const localStorageKey = "Feature" + feature
    localStorage.setItem(localStorageKey, "true");
  }

  async initGrowthBook(params: object) {
    const growthbook = new GrowthBook({
      apiHost:  CONFIG.GROWTH_BOOK_URL,
      clientKey: CONFIG.GROWTH_BOOK_KEY,
      enableDevMode: true,
      trackingCallback: (experiment, result) => {
        if ((window as any).openreplayTracker) {
          console.log("Experiment Viewed - " + experiment.key);
          InternalTracker.trackEvent("Experiment Viewed - " + experiment.key, {
            variationId: result.key,
            experimentId: experiment.key,
          })
        }
      }
    });

    growthbook.setAttributes(params);

    const features = await growthbook.loadFeatures({ autoRefresh: true });
    const experiments = await growthbook.getExperiments();
    if (growthbook.isOn("filemanager")) { this.onFeatureEnabled("Files") }
    if (growthbook.isOn("referrals")) { this.onFeatureEnabled("Referrals") }
    (window as any).growthbook = growthbook;
  }

  async componentDidMount() {

    (window as any).hasInternetConnection = true;

    Network.addListener('networkStatusChange', status => {
      // (window as any).hasInternetConnection = status.connected;
      // console.log("@@@@ Connection: " + status.connected)
    });

    (window as any).onFeatureEnabledFiles = function() {
      this.setState({ showFilesTab: true })
     }.bind(this);

    const logCurrentNetworkStatus = async () => {
      const status = await Network.getStatus();
      // (window as any).hasInternetConnection = status.connected;
      // console.log("@@@@ Connection: " + status.connected)
      if (!status.connected) {
        this.setState({
          signalRInited: false,
          signalRIniting: false
        })
      } else {
        this.initSignalR();
      }
    };

    logCurrentNetworkStatus();

    (window as any).lastDateFocused = new Date();

    (window as any).attemptToShowReviewPrompt = (customMessage) => {
      const hiddenForever = JSON.parse(localStorage.getItem("user")).settings.find(s => s.settingId === 27)?.value === "true";
      if (!hiddenForever) {
        const lastOpen = localStorage.getItem("lastReviewPromot") ? new Date(localStorage.getItem("lastReviewPromot")) : null;
        if (!lastOpen || Utilities.dateAdd(new Date(lastOpen), "day", 7) < new Date()) {
          localStorage.setItem("lastReviewPromot", new Date().toString());
          InternalTracker.trackEvent("App Rating Shown", {
            "text": customMessage || "Would you please rate the Updatedge App?"
          });
          this.setState({
            reviewPrompt: customMessage || "Would you please rate the Updatedge App?"
          })
        }
      }
    }

    (window as any).toast = (text, type, options) => {
      if (text === "No internet connection") {
        if (!(window as any).preventNoInternetToast) {
          clearTimeout((window as any).toastNoInternetConnectionTimeout);
          (window as any).toastNoInternetConnectionTimeout = setTimeout(() => {
            (window as any).preventNoInternetToast = false
          }, 4500)
          toast.dismiss();
          toast.error(text);
          (window as any).preventNoInternetToast = true
        }
        return;
      }

      if ((window as any).preventNoInternetToast) {
        return;
      }

      let mergedOptions = options || {timeout: 1000000};

      if (type === "error")
          toast.error(text, mergedOptions);
      if (type === "success")
          toast.success(text, mergedOptions)
      if (type === "info" || !type)
          toast.info(text, mergedOptions);
    }

    App.addListener('appStateChange', ({ isActive }) => {
      console.log('App state changed. Is active? ' + isActive);
      if (isActive) {
        this.onFocus()
      }
    });

    LocalNotifications.addListener('localNotificationActionPerformed', (notification) => {
      if (notification && notification.notification) {
        // alert("Notification clicked " + notification.notification.id + " " + typeof notification.notification.id)
        if (notification.notification.id === 1) {
          window.location.href = '/contacts?promptAdd=true';
        }
      }
    })

    Utilities.scheduleNotifications();

    App.addListener('appUrlOpen', async (data: any) => {
      InternalTracker.trackEvent("Open From Url", {
        url: data.url
      })
      // alert(data.url);
      if (data.url.indexOf("invitations") !== -1) {
        // setLoading(true);
        let token = data.url.substring(data.url.indexOf("invitations")+12, data.url.length-1);
        if (localStorage.getItem("access_token")) {
          await UserAPI.acceptInvite(token).then(data => {
            alert("The invitation has been accepted");
            window.location.href = "/";
          }).catch(e => {
            alert("Failed to accept invite " + e);
          })
        } else {
          alert("The invitation has been accepted. Log in or Sign Up to start sharing");
        }
      } else if (data.url.indexOf("/offer/") !== -1) {
        window.location.href = "/offer/" + data.url.split("/offer/")[1];
      } else if (data.url.indexOf("/messages/") !== -1) {
        localStorage.setItem("open-message", data.url.split("/messages/")[1].replace("/auto", ""));
        window.location.href = "/messages/";
      } else if (data.url.indexOf("/rating/") !== -1) {
        window.location.href = "/rating/" + data.url.split("/rating/")[1];
      } else if (data.url.indexOf("/notifications") !== -1) {
        window.location.href = "/notifications";
      } else if (data.url.indexOf("/help/close-account") !== -1) {
        window.location.href = "/help/close-account";
      } else if (data.url.indexOf("/contacts") !== -1) {
        window.location.href = "/contacts" + data.url.split("/contacts")[1];
      } else {

      }
    });

    let currentPath = window.location.pathname;

    let token: string = localStorage.getItem("access_token");
    let hellojs: string = localStorage.getItem("hello");
    let env: string = localStorage.getItem("env");
    let pushNotificationToken: string = localStorage.getItem("notificationToken");
    let loggedIn: boolean = false;
    let userStr: string = localStorage.getItem("user");
    let user: User = (userStr) ? JSON.parse(userStr) : null;

    if (user) {
      (window as any).globalUserId = user.id
    }

    // No env defined, defaulting to live
    if (!env) {
      env = loadedEnv;
      if (window.location.href.indexOf("test-my.updatedge.com") !== -1) {
        env = "dev"
      } else if (window.location.href.indexOf("my.updatedge.com") !== -1) { 
        env = "prod"
      }
      localStorage.setItem("env", env);
    }

    const envOverwrite = window.location.href.split("env=")[1];
    if (envOverwrite) {
        localStorage.setItem("env", envOverwrite.split("&")[0]);
    }
    const screenResOverride = window.location.href.split("screen=")[1];
    if (screenResOverride) {
        localStorage.setItem("screenResOverride", screenResOverride.split("&")[0]);
    }
    if (envOverwrite || screenResOverride) {
      window.location.href = "/";
      return;
    }

    // If didn't start from this instance (pwa web version) then redirect to the native app
    if (window.location.href.indexOf("/email-confirmed") !== -1) {
      if (localStorage.getItem("access_token")) {
        window.location.href = "/";
        return;
      } else {
        this.setState({
          inited: true
        })
        return;
      }
    }

    if (window.location.href.indexOf("iframe=true") !== -1) {
      localStorage.setItem("iframe", "true");
    }

    if (window.location.href.indexOf("access_token=") !== -1) {
      console.log(window.location.href.split("access_token=")[1])
      localStorage.setItem("access_token", window.location.href.split("access_token=")[1].split("&")[0]);
      if (window.location.href.indexOf("user_email=") !== -1) {
        localStorage.setItem("iframe_user_email", window.location.href.split("user_email=")[1].split("&")[0]);
      }
      if (window.location.href.split("redirect_to=")[1]) {
        const redicrectTo = window.location.href.split("redirect_to=")[1].split("&")[0];
        window.location.href = redicrectTo ? ("/" + redicrectTo) : "/";
        return;
      }
    }

    if (window.self !== window.top) {
      document.body.classList.add("iframe");
    }

    // We don't authenticate in testing or with external links
    if (window.location.href.indexOf("testing=true") === -1 && window.location.href.indexOf("/external/") === -1) {

      // Token already saved by hellojs, manually saving access_token - FIXED to make it work even when devtools is not open
      if (hellojs && !token && currentPath.startsWith("/redirect")) {
        const helloJsObj = JSON.parse(hellojs);
        localStorage.setItem("access_token", helloJsObj.b2cSignInAndUpPolicy.access_token)
        window.location.href = "/"
        return;
      }

      // Token is present
      if (token) {

        // Already have token but something else is missing
        if ( (
          (!user) ||
          (!user.canSync) ||
          (!pushNotificationToken) ||
          (!localStorage.getItem("firstSyncDone")) ||
          (!localStorage.getItem("passedOnboarding"))
        ) && (currentPath !== "/onboarding-preferences" && currentPath !== "/help/close-account") ) {
          console.log("Just logged in, redirecting to?: " + localStorage.getItem("redirectOnLogin"))
          if (window.self === window.top) {
            const redirectTo = localStorage.getItem("redirectOnLogin")
            // if (redirectTo) {
            //   localStorage.removeItem("redirectOnLogin")
            //   window.location.href = redirectTo;
            // } else {
              window.location.href = "/onboarding-preferences"
            // }
            return;
          }
        }

        let colors = user && user.eventColors ? JSON.parse(user.eventColors) : { availability: 5, work: 1, private: 7 };
        (window as any).colors = colors;

        const body = document.getElementsByTagName("body");
        if (body && body[0] && colors && colors.availability && colors.work && colors.private) {
          body[0].setAttribute("data-availability-color", colors.availability);
          body[0].setAttribute("data-work-color", colors.work);
          body[0].setAttribute("data-private-color", colors.private);
        }
        
        // If just opened / just authenticated go to main view
        if (currentPath === "/" || currentPath === "/onboarding" || currentPath === "/onboarding/auto") {
          const redirectTo = localStorage.getItem("redirectOnLogin")
          if (redirectTo) {
            localStorage.removeItem("redirectOnLogin")
            window.location.href = redirectTo;
          } else {
            window.location.href = "/calendar";
          }
          return;
        }

        ChatService.init();
        this.initSignalR();
        this.reregisterNotifications();

        loggedIn = true;

      }

      // If not logged in and not on onboarding then redirect to onboarding
      if (!loggedIn && currentPath !== "/onboarding" && currentPath !== "/onboarding/auto") {
        if (['/help/close-account'].indexOf(window.location.pathname) !== -1) {
          localStorage.setItem("redirectOnLogin", window.location.pathname);
          window.location.href = "/onboarding"
        } else {
          window.location.href = "/onboarding"
        }
        return;
      }
    }

    await InitStorage();

    this.setState({ inited: true })

    // Keep notifications & ratings tab number up-to-date
    store.subscribe(() => {
      const state = store.getState();
      if (state && state.notifications) {
        let newCount = state.notifications.length;
        if (newCount !== lastNewNotificationCount) {
          lastNewNotificationCount = newCount
          this.setState({ newNotificationsCount: state.notifications.length, showNewNotificationTooltip: newCount > this.state.newNotificationsCount }, () => {
            if (this.state.showNewNotificationTooltip) {
              setTimeout(() => {
                this.setState({ showNewNotificationTooltip: false })
              }, 2000)
            }
          })
        }
      }

      if (state && state.ratings) {
        let newCount = state.ratings.length;
        if (newCount !== lastNewRatingCount) {
          lastNewRatingCount = newCount

          this.setState({ newRatingCount: state.ratings.length, showNewRatingTooltip: newCount > this.state.newRatingCount }, () => {
            if (this.state.showNewRatingTooltip) {
              setTimeout(() => {
                this.setState({ showNewRatingTooltip: false })
              }, 2000)
            }
          })
        }
      }

      if (state && state.chats) {
        let newCount = state.chats ? state.chats.filter(item => item.hasUnread).length : 0;
        if (newCount !== lastNewMessagesCount) {
          lastNewMessagesCount = newCount
          this.setState({ newMessagesCount: newCount, showNewMessageTooltip: newCount > this.state.newMessagesCount }, () => {
            if (this.state.showNewMessageTooltip) {
              setTimeout(() => {
                this.setState({ showNewMessageTooltip: false })
              }, 2000)
            }
          })
        }
      }
    });
    
    this.reloadNotifications();

    // window.removeEventListener("focus", this.onFocus);
    // window.addEventListener("focus", this.onFocus);

    setTimeout(function() {
      let notchSize:any = getComputedStyle(window.document.documentElement).getPropertyValue("--ion-safe-area-bottom");
      let notchSizeTop:any = getComputedStyle(window.document.documentElement).getPropertyValue("--ion-safe-area-top");
      let body = window.document.getElementsByTagName("body");
      notchSize = parseInt(notchSize.replace("px", ""));
      notchSizeTop = parseInt(notchSizeTop.replace("px", ""));

      if (notchSize && notchSize > 0) {
          body[0].setAttribute("data-notch", "true");
      }

      if (notchSize || notchSizeTop) {
        (window as any).notchTop = notchSizeTop || 0;
        (window as any).notchBottom = notchSize || 0;
      }

      if ((loggedIn && localStorage.getItem("user")) || localStorage.getItem("iframe_user_email")) {

        let lastUrl = window.location.href;

        setInterval(() => {
          if (lastUrl !== window.location.href) {
            lastUrl = window.location.href;
            InternalTracker.trackEvent("Page View " + window.location.pathname.substr(1).replace(/\//g, "-"));
          }
        }, 250)

        InternalTracker.trackEvent("Page View calendar");
          
        this.reloadUserProfile();

        this.reloadContactable();

        this.attemptToInitTracker();
      } else if (window.location.href.indexOf("/onboarding-preferences") !== -1) {
        setTimeout(() => {
          if (localStorage.getItem("user")) {
            this.attemptToInitTracker();
          } else {
            setTimeout(() => {
              if (localStorage.getItem("user")) {
                this.attemptToInitTracker();
              } else {
                setTimeout(() => {
                  if (localStorage.getItem("user")) {
                    this.attemptToInitTracker();
                  }
                }, 1500)
              }
            }, 1500);
          }
        }, 1500)
      }

    }.bind(this), 500);

    (window as any).Sync = new Sync();

    // Handle going back when back button is pressed
    document.addEventListener('ionBackButton', (ev: BackButtonEvent) => {
      ev.detail.register(0, () => {
        ev.stopImmediatePropagation();
        ev.preventDefault();
        ev.stopPropagation();
        if (window.location.href.indexOf("message") !== -1) {
          let el = document.getElementById("message-back");
          if (el) {
            el.click();
          }
        }
      }); 
    });

    let deviceInfo = await Device.getInfo();
    store.dispatch(updateSettings({ 
      os: deviceInfo.platform,
    }))

    this.runExperiments();
    
    // dyanmic event listener to all elements wiht data-report-blurred='true'
    document.addEventListener('click', (event) => {
      // @ts-ignore
      if (event?.target?.getAttribute('data-report-blurred') === 'true' || (event?.target?.parentElement && event?.target?.parentElement?.getAttribute('data-report-blurred') === 'true')) {
          // @ts-ignore
          const userId = event?.target?.getAttribute('data-user-id') || (event?.target?.parentElement && event?.target?.parentElement?.getAttribute('data-user-id'));
          event.stopImmediatePropagation();
          event.preventDefault();
          this.setState({
            confirmUnblurringOfReportedUserId: userId
          })
      }
    }, true);
  }

  async attemptToInitTracker() {

    const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "") : null;
    if (!user) {
      return;
    }

    if (user && !localStorage.getItem("admin_access_token")) {// todo restore} && window.location.port !== "5000") {
      if ((user.settings &&  user.settings.find(setting => setting.settingId === 28)) || localStorage.getItem("filesOnboardingScreen3Passed") === "true") { } else {
        this.setState({
          newFilesCount: 1
        })
      }

      const openreplayTracker = new Tracker({
        projectKey: 
          window.self !== window.top ? 
          (window.location.href.includes("test-") || window.location.href.includes("localhost") ? "B6nqekmFpircT53hdHlt" : "gncn7t1ngA6wMzom3RdU") :
          CONFIG.OPEN_REPLAY_KEY,
        __DISABLE_SECURE_MODE: true,
        ingestPoint: "https://analytics.updatedge.com/ingest",
        defaultInputMode: 0,
        obscureInputEmails: false,
        obscureTextNumbers: false,
        obscureTextEmails: false,
        obscureInputNumbers: false,
        obscureInputDates: false,
        network: {
          capturePayload: true,
          failuresOnly: false,
          ignoreHeaders: true,
          sessionTokenHeader: false,
          captureInIframes: false
        }
      });

      openreplayTracker.use(trackerAssist({}));
      openreplayTracker.use(trackerProfiler());
      openreplayTracker.start()
      let emailToUse = user.email;
      if (!emailToUse) {
        const token = localStorage.getItem("access_token");
        if (token) {
          const decodedToken = Utilities.decodeJWTToken(token);
          if (decodedToken && decodedToken.emails && decodedToken.emails.length > 0) {
            emailToUse = decodedToken.emails[0];
          }
        }
      }
      openreplayTracker.setUserID(emailToUse);
      openreplayTracker.setMetadata('email', emailToUse);
      openreplayTracker.setMetadata('name', user.firstName + " " + user.lastName);
      openreplayTracker.setMetadata('userId', user.id);
      if (CONFIG.VERSION) {
        openreplayTracker.setMetadata('appVersion', versionName);
      }
      if (user.organisationId) {
        openreplayTracker.setMetadata('organisationId', user.organisationId);
        openreplayTracker.setMetadata('organisationName', user.organisationName);
      }
      if (localStorage.getItem("miscMetadata")) {
        openreplayTracker.setMetadata('misc', localStorage.getItem("miscMetadata"));
      }

      posthog.init(CONFIG.POSTHOG_KEY, { 
        api_host: 'https://eu.i.posthog.com', 
        person_profiles: 'identified_only',
        session_recording: {
          maskAllInputs: false,
          maskInputOptions: {
            password: true,
          }
        }
      });

      posthog.identify(
        user.id,
        {
          email: emailToUse,
          name: user.firstName + " " + user.lastName,
          version: versionName,
          organisationId: user.organisationId ? user.organisationId : null,
          organisationName: user.organisationName ? user.organisationName : null,
          misc: localStorage.getItem("miscMetadata") ? localStorage.getItem("miscMetadata") : null
        }
      );

      (window as any).posthogTracker = posthog;
    
      (window as any).openreplayTracker = openreplayTracker;
      (window as any).globalUserId = user.id

      if (user.id) {
        this.initGrowthBook({
          "id": user.id,
          "email": emailToUse,
          "name": user.firstName + " " + user.lastName,
          "organisationId": user.organisationId,
          "organisationName": user.organisationName,
        })
      }
    }
  }

  async runExperiments() {

    if (!(window as any).growthbook) {
      setTimeout(() => {
        this.runExperiments();
      }, 1000)
      return;
    }

    const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || "") : null;
    let haveEvent = await hasUndeletedEvents()
    let haveContact = (localStorage.getItem("haveContact") === "true");

    if (!user || !haveEvent || !haveContact) {
      console.log("@@@ NO EXPERIMENTS hasn't finished vital parts")
      return;
    }

    const PROMPT_TASKS = [
      {
        id: "image",
        completed: user.userSetProfileImage
      },
      {
          id: "headline",
          completed: user.headline !== undefined && user.headline !== null && user.headline !== "",
      },
      {
          id: "postalcode",
          completed: user.postalCode !== null && user.postalCode !== undefined && user.postalCode !== ""
      },
      {
          id: "sectorssubsectors",
          completed: user.industries && user.industries.length !== 0 && user.industries.filter(item => item.subSectors && item.subSectors.length !== 0).length !== 0
      },
      {
          id: "qualifications",
          completed: user.qualifications && user.qualifications.length !== 0
      },
      localStorage.getItem("FeatureFiles") ? {
          id: "files",
          completed: user.files && user.files.length !== 0,
      } : {
          id: "files",
          completed: true,
      },
      {
          id: "smsnotifications",
          completed: user.phoneNumber && user.phoneNumberVerified,
      }
    ]

    const PROMPT_SCHEDULE = [
      3, 5, 7, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10
    ]

    if (!localStorage.getItem("onboardingPromptScheduleI")) {
      const previousLoggedInValue = user.settings && user.settings.find(setting => setting.settingId === 40);
      if (!previousLoggedInValue) {
        UserAPI.updateSetting("40", "0");
      }
      localStorage.setItem("onboardingPromptScheduleI", previousLoggedInValue ? previousLoggedInValue.value : "0");
      localStorage.setItem("onboardingPromptScheduleNextDate", Utilities.dateAdd(new Date(), "day", PROMPT_SCHEDULE[parseInt(localStorage.getItem("onboardingPromptScheduleI"))]).toISOString());
      return;
    }

    const scheduleI = parseInt(localStorage.getItem("onboardingPromptScheduleI") || "0");
    const showFrom = new Date(localStorage.getItem("onboardingPromptScheduleNextDate") || "");

    const now = localStorage.getItem("artificialDate") ? new Date(parseInt(localStorage.getItem("artificialDate")) * 1000) : new Date();

    if (now < showFrom) { 
      console.log("@@@@@ NOT yet showing")
      return;
    }

    console.log("@@@@@ YEs showing now", PROMPT_TASKS)

    if (PROMPT_SCHEDULE[scheduleI + 1]) {
      localStorage.setItem("onboardingPromptScheduleI", (scheduleI + 1).toString());
      localStorage.setItem("onboardingPromptScheduleNextDate", Utilities.dateAdd(now, "day", PROMPT_SCHEDULE[scheduleI + 1]).toISOString());
      UserAPI.updateSetting("40", (scheduleI + 1).toString());
    } else {
      localStorage.setItem("onboardingPromptScheduleNextDate", Utilities.dateAdd(now, "year", 20).toISOString());
    }

    if (user) {
      const uncompletedPromptTasks = 
        PROMPT_TASKS
        .filter(item => {
          const hiddenForever = user.settings && user.settings.find(us => 
            (us.settingId === 32 && item.id === "image") ||
            (us.settingId === 33 && item.id === "headline") ||
            (us.settingId === 34 && item.id === "postalcode") ||
            (us.settingId === 35 && item.id === "files") ||
            (us.settingId === 36 && item.id === "sectorssubsectors") ||
            (us.settingId === 37 && item.id === "smsnotifications") ||
            (us.settingId === 38 && item.id === "qualifications")
          );
          console.log(item.id + " " + item.completed + " " + hiddenForever)
          if (item.completed || hiddenForever) {
            return false;
          }
          return true
        })
        .map(item => item.id);

      if (uncompletedPromptTasks.length === 0) {
        console.log("@@@@@ NO uncompletedPromptTasks")
        return; 
      }

      const randomTask = uncompletedPromptTasks[Math.floor(Math.random() * uncompletedPromptTasks.length)];

      console.log("@@@ Getting variation ", randomTask)

      const featureKey = "onboarding-prompt-" + randomTask;

      const onboardingPromptVariation = (window as any).growthbook.getFeatureValue(featureKey, null);
      console.log(uncompletedPromptTasks, " @@@@@ ", onboardingPromptVariation);

      console.log("@@@@ VARIATION: ", onboardingPromptVariation, "onboarding-prompt-" + randomTask)

      if (onboardingPromptVariation !== null && onboardingPromptVariation !== -1) {
        const experimentConcifg = (window as any).abConfig && (window as any).abConfig[randomTask] && (window as any).abConfig[randomTask][onboardingPromptVariation] ? (window as any).abConfig[randomTask][onboardingPromptVariation] : null;
        console.log("@@@ EXPERIMENT CONFIG", experimentConcifg, randomTask)
        if (experimentConcifg) {
          if ((window as any).showOnboardingTipPrompt) {
            (window as any).showOnboardingTipPrompt(onboardingPromptVariation, randomTask, {
              ...experimentConcifg,
              img: onboardingPromptVariation
            });
            console.log("Experiment Viewed - " + featureKey);
            InternalTracker.trackEvent("Experiment Viewed - " + featureKey, {
              variationId: onboardingPromptVariation,
              experimentId: featureKey,
            });
          }
        }
      }
    }
  }

  initSignalR() {
    if (this.state.signalRInited || this.state.signalRIniting) {
      console.log("signalR - no init needed")
      return null;
    }

    console.log("signalR initing")

    this.setState({
      signalRIniting: true
    })

    const accessToken = localStorage.getItem("access_token");
    const updateTargets = localStorage.getItem("updateTargets") ? JSON.parse(localStorage.getItem("updateTargets") || "") : [];

    if (accessToken) {
      let connection = new signalR.HubConnectionBuilder()
      .withUrl(
        CONFIG.SIGNALR_URI + "/notifications?targets=" + updateTargets.join(",") + "&authorization=" + accessToken
      ).withAutomaticReconnect().build()

      connection.onclose(() => {
        console.log("signalR closed")
        this.setState({
          signalRInited: false,
          signalRIniting: false
        })
      })

      connection.on(
        'UserNotificationsUpdated',
        () => {
          if (window.location.href.indexOf("/notifications") !== -1) {
            if ((window as any).reloadNotificationOnPage) {
              (window as any).reloadNotificationOnPage();
            }
          } else {
            this.reloadNotifications();
          }
        }
      )

      connection.on(
        'UserContactRequest',
        () => {
          if (window.location.href.indexOf("/notifications") !== -1) {
            if ((window as any).reloadNotificationOnPage) {
              (window as any).reloadNotificationOnPage();
            }
          } else {
            this.reloadNotifications();
          }
        }
      )

      connection.on(
        'ReferralReceived',
        () => {
          if (window.location.href.indexOf("/notifications") !== -1) {
            if ((window as any).reloadNotificationOnPage) {
              (window as any).reloadNotificationOnPage();
            }
          } else {
            this.reloadNotifications();
          }
        }
      )

      connection.on(
        'ReferralCompleted',
        () => {
          if (window.location.href.indexOf("/notifications") !== -1) {
            if ((window as any).reloadNotificationOnPage) {
              (window as any).reloadNotificationOnPage();
            }
          } else {
            this.reloadNotifications();
          }
        }
      )

      connection.on(
        'FileSharedPrivately',
        (fileId) => {
          if ((window as any).onSignalRFileShared) {
            (window as any).onSignalRFileShared();
          }
        }
      )

      connection.on(
        'FileSharedPublicly',
        (fileId) => {
          if ((window as any).onSignalRFileShared) {
            (window as any).onSignalRFileShared();
          }
        }
      )

      connection.on(
        'UserRatingUpdated',
        () => {
          this.reloadUserProfile();
        })

      connection.start().then(() => {
        console.log("signalR inited")
        this.setState({
          signalRInited: true,
          signalRIniting: false
        })
      }).catch(e => {
        console.log("signalR failed to init")
        this.setState({
          signalRInited: false,
          signalRIniting: false
        })
      });
    }
  }

  reregisterNotifications() {

    // if (isPlatform("android") && localStorage.getItem("notificationToken") !== "skipped" && !localStorage.getItem("upgradedAndroidPush") ) {
    //   localStorage.removeItem("notificationToken");
    //   localStorage.setItem("upgradedAndroidPush", "true");
    //   alert("Re-registering")
    // }

    if (
      localStorage.getItem("notificationToken") && 
      localStorage.getItem("notificationToken") !== "skipped" &&
      (!isPlatform("mobileweb") && ( isPlatform("android") || isPlatform("ios") )) &&
      !localStorage.getItem("disablePushReRegisterOnInit") &&
      !localStorage.getItem("admin_access_token")
    ) {
      
      PushNotifications
        .register()
        .then(data => {
          PushNotifications
            .requestPermissions()
            .then(data => { }).catch(error => { })
        }).catch(error => { })

      PushNotifications.addListener(
        'registration',
        (token) => {

          if (alreadyCalledRegistration) {
            return;
          } else {
            alreadyCalledRegistration = true;
          }

          // @ts-ignore
          UserAPI.pushNotificationRegsiter(token.value, isPlatform("android") ? "fcm" : "apns").then(data => {

            localStorage.setItem("notificationToken", token.value);

            InternalTracker.trackEvent("Push Notification Enabled");

          }).catch(e => {
            console.error("Failed to REGISTER WIHT SERVER", e);
          })

        },
      );
    }

    if (localStorage.getItem("disablePushReRegisterOnInit")) {
      localStorage.removeItem("disablePushReRegisterOnInit")
    }

  }

  render() {

    // Wait for IndexedDB to initialize
    if (!this.state || !this.state.inited)
      return null;

    const showTooltip = this.state.showNewMessageTooltip || this.state.showNewRatingTooltip || this.state.showNewNotificationTooltip;

    let confirmUnblurAlertDOM = <IonAlert
      isOpen={this.state.confirmUnblurringOfReportedUserId !== null}
      onDidDismiss={() => { this.setState({ confirmUnblurringOfReportedUserId: null }) }}
      header={"This user has been reported, and under review. Some parts of the profile might be unsuitable"} 
      buttons={[
        {
          text: 'Unblur Details',
          handler: () => {
            // @ts-ignore
            document.querySelectorAll('[data-report-blurred="true"][data-user-id="' + this.state.confirmUnblurringOfReportedUserId + '"]').forEach((el) => {
              el.setAttribute('data-report-blurred', 'false');
            });
            this.setState({
              confirmUnblurringOfReportedUserId: null
            })
          }
        },
        {
          text: 'Cancel',
          handler: () => {
            this.setState({
              confirmUnblurringOfReportedUserId: null
            })
          }
        },
      ]}
    />

    let reviewModalDOM =  <IonAlert
      isOpen={this.state.reviewPrompt !== null}
      onDidDismiss={() => { this.setState({ reviewPrompt: null }) }}
      header={this.state.reviewPrompt} 
      buttons={[
        {
          text: 'Rate Now',
          handler: () => {
            InternalTracker.trackEvent("App Rating Opened");
            UserAPI.updateSetting("27", "true");
            if ((window as any).os === "ios") {
              window.open("https://apps.apple.com/gb/app/id1665791926")
            } else if ((window as any).os === "android") {
              window.open("https://play.google.com/store/apps/details?id=com.updatedge")
            } else  {
              window.open("https://g.page/r/CdxU3PCYv1MXEAg/review");
            }
          }
        },
        {
          text: 'Maybe Later',
          handler: () => {
            InternalTracker.trackEvent("App Rating Maybe Later");
          }
        },
        {
          text: "Don't show again",
          handler: () => {
            InternalTracker.trackEvent("App Rating Hidden Forever");
            UserAPI.updateSetting("27", "true");
          }
        }
      ]}
    />

    let newChatsTooltipDOM = <div 
        className="tab-tooltip-wrapper"
        onClick={() => {
          this.setState({
            showNewNotificationTooltip: false,
            showNewMessageTooltip: false,
            showNewRatingTooltip: false
          })
        }}
        style={ (showTooltip) ? {

        } : {
          position: 'absolute',
          bottom: '-200px'
        } }
      >
        { (this.state.showNewMessageTooltip) &&
          <Tooltip
            target={document.getElementById("tab-button-tab-messages")}
            isOpen={true}
          >
            <div className="tooltip-styled">
              {this.state.newMessagesCount}
            </div>
          </Tooltip>
        }
        { (this.state.showNewRatingTooltip) &&
          <Tooltip
            target={document.getElementById("tab-button-tab-insight")}
            isOpen={true}
          >
            <div className="tooltip-styled">
              {this.state.newRatingCount}
            </div>
          </Tooltip>
        }
        { (this.state.showNewNotificationTooltip) &&
          <Tooltip
            target={document.getElementById("tab-button-tab-notifications")}
            isOpen={true}
          >
            <div className="tooltip-styled">
              {this.state.newNotificationsCount}
            </div>
          </Tooltip>
        }
      </div>;
    
    let notificationAlertDOM =             
      <IonAlert
        isOpen={this.state.pendingNotification !== null}
        onDidDismiss={() => { this.setState({ pendingNotification: null }) }}
        header={this.state.pendingNotification ? this.state.pendingNotification.label : "New Notification"} 
        message={this.state.pendingNotification ? (this.state.pendingNotification.msg || null) : null} 
        buttons={[
          {
            text: 'Close',
            handler: () => {  }
          },
          {
            text: 'Open',
            handler: () => { this.executeNotification(this.state.pendingNotification); }
          }
        ]}
      />

    if (this.state.version !== "desktop" || localStorage.getItem("screenResOverride") === "mobile") {
      return (
        <IonApp 
          data-version={this.state.version} 
          data-url={this.state.url}
          style={
            (localStorage.getItem("screenResOverride") === "mobile") ? 
            {
              "width": 360,
              "height": 680,
              "position": "absolute",
              "top": "50%",
              "left": "50%",
              "transform": "translate(-50%, -50%)",
              "maxHeight": "100vh"
            } :
            { }
          }
        >
          <IonReactRouter>
            <IonTabs>
              <IonRouterOutlet>
                <Route path="/calendar" component={Calendar} exact={true} />
                <Route path="/contacts/add" component={Contacts} exact={true} />
                <Route path="/selectreferral" component={Contacts} exact={true} />
                <Route path="/sharefile/:id" component={Contacts} exact={true} />
                <Route path="/sharefolder/:id" component={Contacts} exact={true} />
                <Route path="/agency" component={Contacts} exact={true} />
                <Route path="/onboarding" component={Onboarding} exact />
                <Route path="/email-confirmed" component={EmailConfirmed} exact />
                <Route path="/messages" component={Chat} />
                <Route path="/insight" component={Insight} />
                <Route path="/rating/:id" component={RatingView} />
                <Route path="/ratings" component={Rating} exact />
                <Route path="/files" component={Files} exact />
                <Route path="/files/:entity/:id" component={Files} exact />
                <Route path="/onboarding/auto" component={Onboarding} exact />
                <Route path="/login" component={LoginRedirect} exact />
                <Route path="/redirectimp" component={Impersonation} exact />
                <Route path="/login/:id" component={LoginRedirect} exact />
                <Route path="/redirect" component={LoginRedirect} exact />
                <Route path="/redirect/:id" component={LoginRedirect} exact />
                <Route path="/settings" component={Settings} exact />
                <Route path="/settings/profile" component={ProfileSettings} exact />
                <Route path="/settings/time-presets" component={TimePresets} exact />
                <Route path="/help/:slug" component={HelpGuide} exact />
                <Route path="/help" component={HelpGuides} exact />
                <Route path="/external/opensection/:section" component={OpenSection} exact />
                <Route path="/notifications" component={Notifications} exact />
                <Route path="/offer/:id/autoadd" component={Offer} exact />
                <Route path="/offer/:id/:hash" component={Offer} exact />
                <Route path="/offer/:id" component={Offer} exact />
                <Route path="/directoffer/:id" component={Offer} exact />
                <Route path="/directoffer/:id/:hash" component={Offer} exact />
                <Route path="/verify-email" component={VerifyEmail} exact />
                <Route path="/push" component={PushNotificationManagement} exact />
                <Route path="/settings/timeline-clearup" component={TimelineClearup} exact />
                <Route path="/external/confirm-availability/:token" component={ConfirmAvailability} exact />
                <Route path="/onboarding-preferences" render={(props) => <OnboardingPreferences /> } exact />
                <Route path="/contacts" component={Contacts} />
                <Route path="/referrals/list" component={ReferralsList} />
                <Route path="/referrals/add" component={ReferralsAdd} />
              </IonRouterOutlet>
              <IonTabBar slot="bottom" mode="ios">
                <IonTabButton tab="tab-messages" href="/messages" style={ this.state.showNewMessageTooltip ? { /* background: '#3573E6', color: "white" */ } : { } }>
                  <IonIcon style={{ fontSize: '2.6em' }} icon={chatbubbleEllipsesOutline} />
                  <IonLabel>Chat { this.state.newMessagesCount !== 0 && !this.state.showNewMessageTooltip && <span className="badge">{this.state.newMessagesCount}</span> }</IonLabel>
                </IonTabButton>
                <IonTabButton tab="tab-contacts" href="/contacts">
                  <IonIcon style={{ fontSize: '2.6em' }} icon={peopleOutline} />
                  <IonLabel>Contacts</IonLabel>
                </IonTabButton>
                <IonTabButton tab="tab-calendar" href="/calendar">
                  <IonIcon style={{ fontSize: '2.6em' }} icon={calendarOutline} />
                  <IonLabel>Availability</IonLabel>
                </IonTabButton>
                <IonTabButton tab="tab-notifications" href="/notifications" style={ this.state.showNewNotificationTooltip ? {  /* background: '#3573E6', color: "white" */ } : { } }>
                  <IonIcon style={{ fontSize: '2.6em' }} icon={notificationsOutline} />
                  <IonLabel>Notifications { this.state.newNotificationsCount !== 0 && !this.state.showNewNotificationTooltip && <span className="badge">{this.state.newNotificationsCount}</span> }</IonLabel>
                </IonTabButton>
                <IonTabButton tab="tab-insight" href="/insight">
                  <IonIcon style={{ fontSize: '2.6em' }} icon={pieChartOutline} />
                  <IonLabel>Insights { this.state.newRatingCount !== 0 && !this.state.showNewRatingTooltip && <span className="badge">{this.state.newRatingCount}</span> }</IonLabel>
                </IonTabButton>
                { (this.state.showFilesTab) &&
                  <IonTabButton tab="tab-files" href="/files">
                    <IonIcon style={{ fontSize: '2.6em' }} icon={fileTrayFull} />
                    <IonLabel>Files { this.state.newFilesCount !== 0 && <span className="badge">{this.state.newFilesCount}</span> }</IonLabel>
                  </IonTabButton>
                }
                <IonTabButton tab="tab-setting" href="/settings">
                  <IonIcon style={{ fontSize: '2.6em' }} icon={cogOutline} />
                  <IonLabel>Settings</IonLabel>
                </IonTabButton>
              </IonTabBar>
            </IonTabs>
          </IonReactRouter>
          {notificationAlertDOM}
          {newChatsTooltipDOM}
          {reviewModalDOM}
          {confirmUnblurAlertDOM}
          <ToastContainer
              position="top-right"
              autoClose={4000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss={false}
              draggable={false}
              pauseOnHover={false}
              theme="colored"
          />
        </IonApp>
      )
    }

    const LEFT_SIDE_DOM =
      <div className="side-header">
        <img alt="Updatedge Logo" className="logo" src={Logo} />
        <h1>Updatedge</h1>
      </div>

    const NAVIGATION_DOM =  <div className="side-navigation">
      <div className="links">
        <a href="/notifications" data-active={window.location.href.indexOf("/notifications") !== -1}>
          <IonIcon icon={notificationsOutline} />
          <div className="side">
            <span>Notifications</span>
            <p>Check share request and offer confirmations</p>
            { this.state.newNotificationsCount !== 0 && 
              <span className="badge">{this.state.newNotificationsCount}</span>
            }
          </div>
        </a>
        <a href="/messages" data-active={window.location.href.indexOf("/messages") !== -1}>
          <IonIcon icon={chatbubbleEllipsesOutline} />
          <div className="side">
            <span>Message</span>
            <p>Message your contacts to discuss offer details</p>
            { this.state.newMessagesCount !== 0 && 
              <span className="badge">{this.state.newMessagesCount}</span>
            }
          </div>
        </a>
        <a href="/insight" data-active={window.location.href.indexOf("/insight") !== -1}>
          <IonIcon icon={pieChartOutline} />
          <div className="side">
            <span>Insight</span>
            <p>View the insight of your interactions on Updatedge</p>
            { this.state.newRatingCount !== 0 && 
              <span className="badge">{this.state.newRatingCount}</span>
            }
          </div>
        </a>
        <a href="/contacts" data-active={window.location.href.indexOf("/contacts") !== -1}>
          <IonIcon icon={peopleOutline} />
          <div className="side">
            <span>Contacts</span>
            <p>Add new contacts or manage existing ones</p>
          </div>
        </a>
        { (this.state.showFilesTab) &&
          <a href="/files" data-active={window.location.href.indexOf("/files") !== -1}>
            <IonIcon icon={fileTrayFullOutline} />
            <div className="side">
              <span>Files</span>
              <p>View files shared with you, and upload your own files</p>
            </div>
          </a>
        }
        <a href="/settings" data-active={window.location.href.indexOf("/settings") !== -1}>
          <IonIcon icon={cogOutline} />
          <div className="side">
            <span>Settings</span>
            <p>Change your settings or get support</p>
          </div>
        </a>
      </div>
    </div>

    if (this.state.version === "desktop") 
      return (
        <div 
          id="desktop-wrapper" 
          data-url={this.state.url}
          style={
            (localStorage.getItem("screenResOverride") === "mobile") ? 
            {
              "width": 360,
              "height": 680,
              "position": "absolute",
              "top": "50%",
              "left": "50%",
              "transform": "translate(-50%, -50%)",
              "maxHeight": "100vh"
            } :
            { }
          }
        >
          <div className="desktop-wrapper-inner">
          {LEFT_SIDE_DOM}
          <IonApp data-version={this.state.version}>
            <IonReactRouter>
              <IonTabs>
                <IonRouterOutlet>
                  <Route path="/calendar" component={Calendar} exact={true} />
                  <Route path="/contacts/add" component={Contacts} exact={true} />
                  <Route path="/selectreferral" component={Contacts} exact={true} />
                  <Route path="/sharefile/:id" component={Contacts} exact={true} />
                  <Route path="/sharefolder/:id" component={Contacts} exact={true} />
                  <Route path="/agency" component={Contacts} exact={true} />
                  <Route path="/onboarding" component={Onboarding} exact />
                  <Route path="/messages" component={Chat} />
                  <Route path="/insight" component={Insight} />
                  <Route path="/rating/:id" component={RatingView} />
                  <Route path="/email-confirmed" component={EmailConfirmed} exact />
                  <Route path="/ratings" component={Rating} exact />
                  <Route path="/files" component={Files} exact />
                  <Route path="/files/:entity/:id" component={Files} exact />
                  <Route path="/onboarding/auto" component={Onboarding} exact />
                  <Route path="/login" component={LoginRedirect} exact />
                  <Route path="/redirectimp" component={Impersonation} exact />
                  <Route path="/login/:id" component={LoginRedirect} exact />
                  <Route path="/redirect" component={LoginRedirect} exact />
                  <Route path="/redirect/:id" component={LoginRedirect} exact />
                  <Route path="/settings" component={Settings} exact />
                  <Route path="/settings/profile" component={ProfileSettings} exact />
                  <Route path="/settings/time-presets" component={TimePresets} exact />
                  <Route path="/help/:slug" component={HelpGuide} exact />
                  <Route path="/help" component={HelpGuides} exact />
                  <Route path="/external/opensection/:section" component={OpenSection} exact />
                  <Route path="/notifications" component={Notifications} exact />
                  <Route path="/offer/:id/autoadd" component={Offer} exact />
                  <Route path="/offer/:id/:hash" component={Offer} exact />
                  <Route path="/offer/:id" component={Offer} exact />
                  <Route path="/directoffer/:id" component={Offer} exact />
                  <Route path="/directoffer/:id/:hash" component={Offer} exact />
                  <Route path="/verify-email" component={VerifyEmail} exact />
                  <Route path="/push" component={PushNotificationManagement} exact />
                  <Route path="/settings/timeline-clearup" component={TimelineClearup} exact />
                  <Route path="/external/confirm-availability/:token" component={ConfirmAvailability} exact />
                  <Route path="/onboarding-preferences" render={(props) => <OnboardingPreferences /> } exact />
                  <Route path="/contacts" component={Contacts} />
                  <Route path="/referrals/list" component={ReferralsList} />
                  <Route path="/referrals/add" component={ReferralsAdd} />
                </IonRouterOutlet>
                <IonTabBar slot="bottom" mode="ios">
                  <IonTabButton tab="tab4" href="/messages">
                    <IonIcon style={{ fontSize: '2.6em' }} icon={chatbubbleEllipsesOutline} />
                    <IonLabel>Chat { this.state.newMessagesCount !== 0 && <span className="badge">{this.state.newMessagesCount}</span> }</IonLabel>
                  </IonTabButton>
                  <IonTabButton tab="tab1" href="/contacts">
                    <IonIcon style={{ fontSize: '2.6em' }} icon={peopleOutline} />
                    <IonLabel>Contacts</IonLabel>
                  </IonTabButton>
                  <IonTabButton tab="tab2" href="/calendar">
                    <IonIcon style={{ fontSize: '2.6em' }} icon={calendarOutline} />
                    <IonLabel>Availability</IonLabel>
                  </IonTabButton>
                  <IonTabButton tab="tab3" href="/notifications">
                    <IonIcon style={{ fontSize: '2.6em' }} icon={notificationsOutline} />
                    <IonLabel>Notifications { this.state.newNotificationsCount !== 0 && <span className="badge">{this.state.newNotificationsCount}</span> }</IonLabel>
                  </IonTabButton>
                  <IonTabButton tab="tab7" href="/insight">
                    <IonIcon style={{ fontSize: '2.6em' }} icon={pieChartOutline} />
                    <IonLabel>Insight</IonLabel>
                  </IonTabButton>
                  { (this.state.showFilesTab) &&
                    <IonTabButton tab="tab-files" href="/files">
                      <IonIcon style={{ fontSize: '2.6em' }} icon={fileTrayFull} />
                      <IonLabel>Files</IonLabel>
                    </IonTabButton>
                  }
                  <IonTabButton tab="tab6" href="/settings">
                    <IonIcon style={{ fontSize: '2.6em' }} icon={cogOutline} />
                    <IonLabel>Settings</IonLabel>
                  </IonTabButton>
                </IonTabBar>
              </IonTabs>
            </IonReactRouter>
            { notificationAlertDOM }
            {reviewModalDOM}
            <ToastContainer
                position="top-right"
                autoClose={4000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false}
                theme="colored"
            />
          </IonApp>
        </div>
        </div>
      )
  }
};

export default PwaApp;
