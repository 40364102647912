import { IonSearchbar, IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonLoading, IonPage, IonTitle, IonToolbar, isPlatform, IonButton, IonAlert, IonActionSheet } from '@ionic/react';
import 'emoji-mart/css/emoji-mart.css';
import { ellipsisVertical, addCircle, archive, archiveOutline, attach, attachOutline, checkmarkCircle, chevronBack, chevronBackOutline, chevronForwardSharp, closeCircle, ellipse, send, trashOutline } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import ChatAPI from '../apis/Chat';
import UserAPI from '../apis/User';
import { Contact } from '../types/Contact';
import { Channel } from '../types/twilio/channel';
import { Member } from '../types/twilio/member';
import { UserDescriptor } from '../types/twilio/userdescriptor';
import Utilities from '../Utilities';
import ContactSelectorModal from './Components/Chat/ContactSelector';
import EmojiButton from './Components/Chat/EmojiButton';
import ContactListItem from './Components/Contacts/ContactListItem';
import OnboardingGuide from './Components/OnboardingGuide';
import InternalTracker from '../InternalTracker';
import { Conversation, Media, Message, Participant, MediaCategory } from '@twilio/conversations';

import { Plugins } from '@capacitor/core';
import { ChatService } from '../services/ChatService';
import ContactAPI from '../apis/ContactAPI';
import { CircularProgress } from '@material-ui/core';
import { Browser } from '@capacitor/browser';
import ReportModal, { ReportTypeId } from './Components/ReportModal';

const Chat = require('twilio-chat');
let justReceived: string = null;
let tokenLastUpdate: Date | null = null;

interface Props {
  contactsP?: Event[],
  match?: Object,
  test?: boolean,
  channelsT?: ChannelData[],
  channelT?: ChannelData,
}

interface ChannelData {
  id: string;
  contact: UserDescriptor;
  member?: Member;
  messages: Message[];
  channel: Channel;
}

export interface ChatContact {
  userId: string;
  fullName: string;
  externalOnly: boolean;
  reporeted: boolean;
}

enum Status {
  Loading = 0,
  Ready = 1,
  Error = 2,
  ContactsLoading = 3,
  ContactsSelecting = 4
}

export interface LoadedConversation {
  conversation: Conversation,
  participantsAll: Participant[],
  participants: {
      id: string,
      name: string,
      online?: boolean
  }[],
  messages: Message[],
  dateUpdated: Date,
  diffBetweenLastAndNow: number,
  paginate?: () => void,
  lastReadMessageIndex: number | null,
  hasUnread: boolean,
  contactId: string
}

export interface LastMessageCacheEntry {
  body: string,
  date: Date,
  index: number
}

export interface MessageMedia {
  category: string;
  contentType: string;
  filename: string;
  sid: string;
  size: number
}

enum MediaState {
  Loading = 1,
  Loaded = 2,
  Errored = 3
}

interface MediaUrls {
  [key: string]: {
    state: MediaState,
    expiry?: number,
    url?: string
  }
}

let tickInterval;
let activeChannel: LoadedConversation;
let newMessageMedia: MessageMedia[] = []; // Uploaded medias to be attached to the text message
let mediaUrls: MediaUrls = {};
let preventThreadImagePagination: boolean = false;

const Messages: React.FC<Props> = ({ contactsP, match, test, channelsT, channelT }) => {
  const [channels, setChannels] = useState <ChannelData[]> (null) // All threads of the user
  const [searchText, setSearchText] = useState <string> ("") // Search keywords
  const [contactsSelectorModal, setContactSelectorModal] = useState <boolean> (false) // Whether the contact selector modal open or not
  const [currentContacts, setCurrentContacts] = useState <string[]> ([]) // Current contacts (still sharing availability with)
  const [currentInDirectContacts, setCurrentInDirectContacts] = useState <string[]> ([]) // Contacts that are in the same org you share with at least with
  const [currentContactsAll, setCurrentContactsAll] = useState <string[]> ([]) // Current contacts (still sharing availability with any of the team members)
  const [view, setView] = useState <"current" | "archived"> ("current");
  const [archivedChatUserIds, setArchivedChatUserIds] = useState <string[]> ([]); // User ids whose threads have been archived
  const [deletedContactIds, setDeletedContactIds] = useState <string[]> ([]); // User ids who have been delted
  const [blockedByContactIds, setBlockedByContactIds] = useState <string[]> ([]); // User ids who blocked this user
  const [activeChannelId, setActiveChannelId] = useState <string> (null) // Id of the currently open channel
  const [newMessageText, setNewMessageText] = useState <string> ("") // Text of the new draft message
  const [path, setPath] = useState <string> ("") // path of window
  const [loadingMessage, setLoadingMessage] = useState <string> ("") // Loading message to show
  const [blockingLoadMsg, setBlockingLoadMsg] = useState <string> ("");
  const [canAddThread, setCanAddThread] = useState <boolean> (false)
  const [status, setStatus] = useState <number> (0)
  const [extraTextAreaHeight, setExtraTextAreaHeight] = useState <number> (0)
  const contentRef = useRef(null);
  const messageWrapperRef = useRef(null);
  const history = useHistory();
  const [tick, setTick] = useState <number> (0) // used to load new latest messages as they are loaded
  const version = (window.innerWidth >= 724 && !test && isPlatform("desktop")) ? "desktop" : "mobile";
  const [nonBlockingLoading, setNonBlockingLoading] = useState <string> (null) // Full screen loading
  const [listRefresher, setListRefresher] = useState <boolean> (false)
  const [threadOptionsOpen, setThreadOptionsOpen] = useState <boolean> (false)
  const [reportConfirmContactId, setReportConfirmContactId] = useState <string> (null)

  // @ts-ignore
  const lastUpdated = useSelector(state => state.onboardingGuide);
  useEffect(() => { setCanAddThread(localStorage.getItem("haveContact") === "true"); }, [lastUpdated])

  useEffect( () => {

    (window as any).scrollToThreadBottom = function() {
      setTimeout(function() { if (messageWrapperRef && messageWrapperRef.current) messageWrapperRef.current.scrollTop = messageWrapperRef.current.scrollHeight }, 100);
      setTimeout(function() { if (messageWrapperRef && messageWrapperRef.current) messageWrapperRef.current.scrollTop = messageWrapperRef.current.scrollHeight }, 300);
      setTimeout(function() { if (messageWrapperRef && messageWrapperRef.current) messageWrapperRef.current.scrollTop = messageWrapperRef.current.scrollHeight }, 500);
      setTimeout(function() { if (messageWrapperRef && messageWrapperRef.current) messageWrapperRef.current.scrollTop = messageWrapperRef.current.scrollHeight }, 1000);
      if (activeChannel && activeChannel.messages.length) {
        const lastIndex = activeChannel.messages[activeChannel.messages.length - 1].index;

        if (
            activeChannel.conversation.lastReadMessageIndex === null ||
            (activeChannel.conversation.lastReadMessageIndex || 0) < lastIndex
        ) {
            activeChannel.conversation.setAllMessagesRead().catch(e => console.error);
        }
      }
    }

    setCanAddThread(localStorage.getItem("haveContact") === "true");

    window.removeEventListener("focus", onFocus);
    window.addEventListener("focus", onFocus);

    setStatus(Status.Ready);

    setTick(0)
    clearInterval(tickInterval);
    tickInterval = setInterval(() => {
      setTick(Utilities.randomIntFromInterval(0, 1000));
    }, 1000)

    async function onMount() {

      (window as any).handleFileUploaded = function(msg: Message) {
        if (msg.attachedMedia[0]) {
          newMessageMedia.push({
            category: msg.attachedMedia[0].category,
            contentType: msg.attachedMedia[0].contentType,
            filename: msg.attachedMedia[0].filename,
            sid: msg.attachedMedia[0].sid,
            size: msg.attachedMedia[0].size,
          } as MessageMedia)
          loadFile(newMessageMedia[newMessageMedia.length-1], true);
        }
      }

      // Settings dummy messages in tests
      if (test) {

        setStatus(1);

        if (channelsT) {
          setChannels(channelsT as ChannelData[]);
        }

        if (channelT) {
          setActiveChannelId(channelT.id)
        }

      } else {

        let twillioClientLocal = await ChatService.init();
        setNonBlockingLoading("Checking for new messages");

        history.listen(async (e) => {
          if (e.pathname === "/chat" || e.pathname === "/messages") {
            setSearchText(""); 
            await Utilities.getContacts()
            reloadLocalStorageContactStates();
            setPath(window.location.href);
          }
        })

      }

      setInterval(() => {
        if (localStorage.getItem("open-message") && document.querySelector("#chats-wrapper .contact-item[data-user-id='" + localStorage.getItem("open-message") + "']")) {
          (document.querySelector("#chats-wrapper .contact-item[data-user-id='" + localStorage.getItem("open-message") + "']") as HTMLElement).click();
          localStorage.removeItem("open-message")
        }
      }, 500)

      reloadLocalStorageContactStates();

      await Utilities.getContacts()

      reloadLocalStorageContactStates();
    }

    onMount();

    // setTimeout(() => {
    //   setListRefresher(true);
    // }, 3000)

  }, [])

  useEffect(() => {
    if (listRefresher) {
      setListRefresher(false);
    }
  }, [listRefresher])

  const reloadLocalStorageContactStates = () => {

    if (localStorage.getItem("archivedChats")) {
      setArchivedChatUserIds(JSON.parse(localStorage.getItem("archivedChats")));
    }

    if (localStorage.getItem("blockedByContacts")) {
      setBlockedByContactIds(JSON.parse(localStorage.getItem("blockedByContacts")));
    }

    if (localStorage.getItem("deletedContacts")) {
      setDeletedContactIds(JSON.parse(localStorage.getItem("deletedContacts")));
    }

    if (localStorage.getItem("inDirectContacts")) {
      setCurrentInDirectContacts(JSON.parse(localStorage.getItem("inDirectContacts")));
    }

    setCurrentContacts(localStorage.getItem("userIds") ? (JSON.parse(localStorage.getItem("userIds"))) : []);
    setCurrentContactsAll(localStorage.getItem("allUserIds") ? (JSON.parse(localStorage.getItem("allUserIds"))) : []);
  }

  const onFocus = () => {
    // Token has been in use for over 20 hours (expires in 23 hours)
    if (Utilities.differenceBetweenDatesSeconds(new Date(), tokenLastUpdate) > 72000) {
      reloadChats();
    }
    setTick(0)
    clearInterval(tickInterval);
    tickInterval = setInterval(() => {
      setTick(Utilities.randomIntFromInterval(0, 1000));
    }, 1000)
  }

  useEffect(() => {
    if (activeChannel) {
      InternalTracker.trackEvent("Opened Chat Thread", {
        threadId: activeChannel.conversation.sid,
        threadName: activeChannel.conversation.friendlyName
      });
      if (localStorage.getItem("newMessageContent")) {
        setNewMessageText(localStorage.getItem("newMessageContent"));
        localStorage.removeItem("newMessageContent");
      }
    }
    ChatService.updateState();
  }, [activeChannelId])

  // On url change
  useEffect(() => {
    console.log("onUrlChange " + window.location.href.split("messages/"))
    // setListRefresher(true);
    async function onUrlChange() {
      if (test) return;
      let parts = window.location.href.split("messages/");
      if (parts[1]) {
        newMessageMedia = [];

        if (parts[1].indexOf("auto") !== -1) {
          let userId = parts[1].replace("/auto", "");
          startChat(userId);
        } else {
          let threadId = parts[1];

          let currentThread = ChatService.loadedConversations.find(item => item.conversation.sid === threadId);

          if (ChatService.loadedConversations && currentThread) {

            if (currentThread?.paginate) {
                activeChannel = currentThread;
                setActiveChannelId(threadId);
                (window as any).scrollToThreadBottom();
              } else {
                loadCurrentTheadMessages(threadId);
              }

          } else {
            localStorage.setItem("disablePushReRegisterOnInit", "true");
            window.location.href = "/messages";
          }
        }
      } else {
        setActiveChannelId(null);
      }
    }
    setTimeout(() => {
      onUrlChange();
    }, 300)
  }, [path, window.location.href])

  const loadThread = async function(id: string) {
    setStatus(3);
    setBlockingLoadMsg("Loading Thread")
    if (ChatService.staleData || !ChatService.isLoaded) {
      console.log("@@@@@ STALE DATA, retriwying");
      setTimeout(() => {
        loadThread(id)
      }, 200)
      return;
    }
    setBlockingLoadMsg("")
    let activeChannel = ChatService.loadedConversations.find(item => item.participants[0] && item.participants[0].id === id);
    if (activeChannel) {
      window.history.pushState({}, "Thread " + activeChannel.conversation.sid, "/messages/" + activeChannel.conversation.sid)
      setPath(window.location.href);
      (window as any).scrollToThreadBottom();
    }
  }

  const loadCurrentTheadMessages = async function(threadId?: string) {
    
    setBlockingLoadMsg("Loading Messages");

    if (ChatService.staleData || !ChatService.isLoaded) {
      console.log("@@@@@ STALE DATA, retriwying");
      setTimeout(() => {
        loadCurrentTheadMessages(threadId)
      }, 200)
      return;
    }

    let currentThread = ChatService.loadedConversations.find(item => item.conversation.sid === threadId);
    
    let latestMessages = await currentThread?.conversation.getMessages(1000);
    currentThread!.hasUnread = false;
    currentThread!.messages = latestMessages?.items || [];
    currentThread!.paginate = async () => { if (latestMessages?.hasPrevPage) {
        setBlockingLoadMsg("Loading Messages");
        // @ts-ignore
        let moreMessages = await latestMessages.prevPage().catch(e => console.error);
        setBlockingLoadMsg("")

        for (let i = 0; i < ChatService.loadedConversations.length; i++) {
            const conversation = ChatService.loadedConversations[i];
            if (conversation.conversation.sid === this.activeChannel?.conversation.sid) {
                let newConversations = ChatService.loadedConversations
                // @ts-ignore
                newConversations[i].messages = moreMessages.items.concat(newConversations[i].messages);
                ChatService.loadedConversations = newConversations;
                break;
            }
        }
    }}

    setBlockingLoadMsg("");
    setStatus(Status.Ready);

    let newConversations = ChatService.loadedConversations.filter(item => item.conversation.sid !== threadId)
    if (newConversations && currentThread) {
        newConversations.push(currentThread);
        activeChannel = currentThread
        ChatService.loadedConversations = newConversations;
        setActiveChannelId(threadId);
        (window as any).scrollToThreadBottom();
    }
  }

  const handleNewChannels = async (conversations: Conversation[]) => {
    ChatService.handleNewChannels(conversations)
  }

  // Reloads all channels from Twilio
  const reloadChats = async function() {
      setStatus(Status.Ready)
  }

  const handleSendFile = async (file, newMessageMedia: MessageMedia[]) => {
    if (!file)
        return;

    setBlockingLoadMsg("Uploading File")

    let id = await activeChannel?.conversation.sendMessage({contentType: file.type, media: file, filename: file.name});

    InternalTracker.trackEvent("Sent Chat File", {
      fileName: file.name,
      fileType: file.type,
      threadId: activeChannel?.conversation.sid,
    });

    setBlockingLoadMsg("")
  }

  const loadFile = (media: MessageMedia, loadForPreview?: boolean) => {
    if (!loadForPreview) {
      setBlockingLoadMsg("Preparing File");
    }

    const customBuiltMedia = new Media({
      category: media.category as MediaCategory,
      contentType: media.contentType,
      filename: media.filename,
      sid: media.sid,
      size: media.size
    }, (window as any).twillioClient.services)

    if (customBuiltMedia) {
        customBuiltMedia
            .getContentTemporaryUrl()
            .then((url) => {
              if (loadForPreview) {
                mediaUrls[media.sid] = {
                  state: MediaState.Loaded,
                  url: url
                }
              } else {
                setBlockingLoadMsg("")
                Browser.open({ url: url });
              }
            })
            .catch((e) => console.error);
    }
  }

  // Sends a draft message
  const sendMessage = async function(message: string) {

    if (newMessageMedia.length !== 0) {
      message = JSON.stringify(newMessageMedia.map(item => item as MessageMedia)) + "£££" + message;
      newMessageMedia = [];
    }

    if (message && message.trim()) {
      activeChannel.conversation.sendMessage(message).then(data => {
        ChatAPI.notify(activeChannel.participants[0].id, message);
      }).catch(e => {
        (window as any).toast("Failed to send message, please try later", "error");
        setTimeout(() => {
          window.location.href = "/";
        }, 1500)
      })
    }
    
    InternalTracker.trackEvent("Chat Message Sent", {
      threadId: activeChannel?.conversation.sid,
      threadName: activeChannel?.conversation.friendlyName,
    })

    const el = document.getElementById("new-message-textarea");
    if (el) el.style.height = '54px';
    setExtraTextAreaHeight(0);

    setNewMessageText("");
    setTimeout(()=> {
      setNewMessageText("");
    }, 300);

    (window as any).scrollToThreadBottom();

  }
  
  const onActiveThreadScroll = function() {
    if (!preventThreadImagePagination) {
      preventThreadImagePagination = true;
      setTimeout(() => {
        preventThreadImagePagination = false;
      }, 400)
      let visiblePhotos = document.querySelectorAll(".body-file[data-contenttype*='image']")
      visiblePhotos.forEach(photo => {
        if (Utilities.isElementInViewport(photo)) {
          const photoId = photo.getAttribute("data-sid");
          if (!mediaUrls[photoId]) {
            loadFile({
              sid: photoId,
              contentType: photo.getAttribute("data-contenttype"),
              category: photo.getAttribute("data-category"),
              size: parseInt(photo.getAttribute("data-size")),
              filename: photo.getAttribute("data-filename")
            }, true)
          }
        }
      });
    }
  }

  const onArchiveToggle = async function(movedTo) {
    const res = await Utilities.getContacts();

    if (res) {
      setView("current");
      
      reloadLocalStorageContactStates();

      setActiveChannelId(null)
      setNewMessageText("");
      window.history.pushState({}, "Messages", "/messages");
      setPath(window.location.href);

      (window as any).toast(
        movedTo === "archived" ? "The thread has been archived" :
        movedTo === "inbox" ? "The thread has been moved to the inbox" :
        "Contact readded"
      , "success");
      setLoadingMessage("")
    }
  }

  // Starts chat with a new or existing user
  const startChat = async function(userId: string) {

    setStatus(Status.Loading);

    if (ChatService.staleData || !ChatService.isLoaded) {
      console.log("@@@@@ STALE DATA, retriwying");
      setTimeout(() => {
        startChat(userId)
      }, 200)
      return;
    }

    const existingChannel = ChatService.loadedConversations.find(
      (c) => c.participants[0] && c.participants[0].id === userId
    );

    if (existingChannel) {
      setActiveChannelId(existingChannel.conversation.sid)
      loadThread(userId)
    } else {
      setStatus(Status.Loading);

      InternalTracker.trackEvent("Chat Thread Initiated", {
        threadId: userId,
      })

      if (!(window as any).twillioClient || typeof (window as any).twillioClient.getConversationBySid !== "function") {
        setTimeout(() => {
          startChat(userId)
        }, 500)
        return;
      }

      const channelId = await ChatAPI.initiateChannel(userId);
      const channel = await (window as any).twillioClient.getConversationBySid(channelId);

      handleNewChannels([channel]);
      setStatus(Status.Ready);
      setTimeout(() => {
        loadThread(userId)
      }, 300)
    }

  }

  const currentChannelNoLongerSharing = activeChannel && currentContacts.indexOf(activeChannel.contactId) === -1 && currentContactsAll.indexOf(activeChannel.contactId) === -1
  const currentChannelManuallyArchived = activeChannel && archivedChatUserIds.indexOf(activeChannel.contactId) !== -1
  const currentChannelBlockedByContact = activeChannel && blockedByContactIds.indexOf(activeChannel.contactId) !== -1 && deletedContactIds.indexOf(activeChannel.contactId) === -1
  const archivedNewMesssageThreadCount = ChatService.loadedConversations.filter(c => {
    return ( ( currentContacts.indexOf(c.contactId) === -1 && currentContactsAll.indexOf(c.contactId) === -1 ) || archivedChatUserIds.indexOf(c.contactId) !== -1) && c.hasUnread
  }).length

  // if (!path.includes("messages") && !window.location.href.includes("messages")) {
  //   return null;
  // }

  const activeChannelContact = activeChannel ? ChatService.contacts.find(contact => contact.userId === activeChannel.contactId) : null;
  
  return (
    <IonPage data-page="chat" ref={contentRef}>
      <IonHeader mode="md">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>{view === "current" ? "Current Chats" : "Archived Chats"}</IonTitle>
          <IonButtons slot="end">
            { (activeChannelId) &&
              <IonButton onClick={() => {
                setThreadOptionsOpen(true);
              }}>
                <IonIcon
                  style={{
                    marginRight: 4
                  }}
                  icon={ellipsisVertical}
                />
              </IonButton>
            }
          </IonButtons>
          <div className="nonblocking-loading-wrapper" style={(ChatService.staleData) ? { display: 'flex' } : { display: 'none' }}>
            <CircularProgress />
            {nonBlockingLoading}
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent className="clearfix" id="chats-wrapper" data-thread-open={activeChannelId ? "true" : "false"}>
        { (status !== 0 && ChatService.isLoaded && (!activeChannelId || version === "desktop") && ChatService.loadedConversations ) &&
          <div className="chat-list" style={{ height: '100%' }}>
            <IonSearchbar 
              placeholder="Search by Name" 
              debounce={600} 
              onKeyUp={e => {
                InternalTracker.trackEvent("Chat Search", {
                  query: (e.target as HTMLInputElement).value.toLowerCase(),
                })
                setSearchText((e.target as HTMLInputElement).value.toLowerCase())
              }}
            ></IonSearchbar>
            { (view === "current") &&
              <div
                className='category'
                onClick={() => {
                  InternalTracker.trackEvent("Chat View Changed to Archived");
                  setView("archived");
                }}
              >
                <div>
                  <IonIcon icon={archive} />
                  <span>Archived Chats</span>
                </div>
                { (archivedNewMesssageThreadCount !== 0) &&
                  <span className='badge'>
                    {archivedNewMesssageThreadCount}
                  </span>
                }
                <IonIcon icon={chevronForwardSharp} />
              </div>
            }
            { (view === "archived") &&
              <div
                className='category'
                onClick={() => {
                  InternalTracker.trackEvent("Chat View Changed to Current");
                  setView("current");
                }}
              >
                <div>
                  <IonIcon icon={chevronBackOutline} />
                  <span>Current Chats</span>
                </div>
              </div>
            }
            { (!listRefresher) &&
            <React.Fragment>
            { ChatService.loadedConversations.sort(Utilities.dynamicSort("diffBetweenLastAndNow")).map((c, cI) => {
              if (!c.participants.length)
                return null;

              const contact = c.participants[0]
              const contactExtended = ChatService.contacts.find(item => item.userId === contact.id);
              console.log(contactExtended);

              if (contact.name?.toLocaleLowerCase().indexOf(searchText) === -1)
                return null;

              const noLongerSharing = 
                currentContacts.indexOf(contact.id) === -1 &&
                currentContactsAll.indexOf(contact.id) === -1 &&
                currentInDirectContacts.indexOf(contact.id) === -1;
              const manuallyArchived = archivedChatUserIds.indexOf(contact.id) !== -1
              const blocked = blockedByContactIds.indexOf(contact.id) !== -1

              // On archived view only show non-current contact threads
              if (view === "archived" && (!noLongerSharing && !manuallyArchived && !blocked)) {
                return null;
              }

              // On default view only show current contact threads
              if (view === "current" && (noLongerSharing || manuallyArchived || blocked)) {
                return null;
              }

              // No longer sharing with you
              // if (this.state.currentContacts.indexOf(contact.id) === -1 && !this.state.showEveryone)
              //   return null;
                
              const imageUrl = UserAPI.getProfilePicture(contact.id);

              const lastMessage = ChatService.lastMessages[c.conversation.sid];

              const lastMessageBody = lastMessage ? ( (lastMessage?.body && lastMessage?.body.indexOf("£££") !== -1) ? lastMessage?.body.split("£££")[1] : (lastMessage?.body && lastMessage?.body.indexOf("$$$") !== -1) ? lastMessage?.body.split("$$$")[1] : lastMessage.body ) : null;
              
              return (
                <ContactListItem
                  key={c.conversation.sid}
                  list="CHAT"
                  onEdit={() => { loadThread(contact.id) }}
                  highlight={c.hasUnread}
                  highlightOnline={ChatService.onlineStatuses[contact.id]}
                  contact={{
                    profilePictureUrl: imageUrl,
                    name: contact.name === "Unknown User" ? (ChatService.twillioFrindlyNames[contact.id] || "Unknown User") : contact.name,
                    email: Utilities.formatDate(c.dateUpdated, "d mms (YYYY)") + " " + Utilities.formatDate(c.dateUpdated, "HH:MM") + ": " + (lastMessageBody ? (lastMessageBody || "-") : "No messages"),
                    id: contact.id,
                    reported: contactExtended?.reported,
                  } as Contact }
                  swipeText={"Archive"}
                  onSwipe={() => {
                    ChatAPI
                      .archiveThread(contact.id)
                      .then(data => { onArchiveToggle("archived"); })
                      .catch(data => { setLoadingMessage(""); (window as any).toast("Failed to archive thread", "error"); })
                  }}
                />
              )
            }) }
            { ChatService.unInitedThreads.sort(Utilities.dynamicSort("diffBetweenLastAndNow")).map(contact => {
              if ((contact.fullName.toLocaleLowerCase().indexOf(searchText) === -1) || view === "archived")
                return null;

              const imageUrl = UserAPI.getProfilePicture(contact.userId);
              const contactExtended = ChatService.contacts.find(item => item.userId === contact.userId);

              return (
                <ContactListItem
                  key={contact.userId}
                  list="CHAT"
                  onEdit={() => {
                    startChat(contact.userId);
                    UserAPI.markChatUsed();
                  }}
                  contact={{
                    profilePictureUrl: imageUrl,
                    name: contact.fullName,
                    email: "Never messaged - Tap to start a chat",
                    id: contact.userId,
                    reported: contactExtended?.reported,
                  } as Contact }
                />
              )
              
            }) }               
            </React.Fragment>
            }
            { (status !== 0 && (!activeChannelId || version === "desktop") && channels && channels.length === 0 ) &&
              <div style={{ height: '100%' }} className="no-events">
                {/* <div style={{ paddingBottom: 60 }}>
                  <h3>Send your first message to discuss the details of a job</h3>
                  <DottedArrow />
                </div> */}
              </div>
            }
          </div>
        }
        { (status !== 0 && ChatService.isLoaded && view === "current" && (!activeChannelId || version === "desktop") && ChatService.loadedConversations && canAddThread ) &&
          <div className="bottom-fab-options">
            <button onClick={() => { setContactSelectorModal(true) }} className="add-btn">
              <IonIcon icon={addCircle} />
              New Message
            </button>
          </div>
        }
        { (status !== 0 && activeChannelId && activeChannel) &&
          <div className="active-thread" style={{ height: "100%" }} onScroll={() => {
            onActiveThreadScroll();
          }}>
            <div className="chat-head">
              { (version !== "desktop") &&
                <IonIcon id="message-back" icon={chevronBack} onClick={() => {
                  // setActiveChannel(null);
                  setActiveChannelId(null)
                  setNewMessageText("");
                  window.history.pushState({}, "Messages", "/messages");
                  setPath(window.location.href);
                }} />
              }
              <div className="img" data-user-id={activeChannelContact ? activeChannel.contactId : ""} data-report-blurred={activeChannelContact ? activeChannelContact.reporeted : false}>
                <img src={UserAPI.getProfilePicture(activeChannel.participants[0].id)} />
              </div>
              <div className="chat-about">
                <div className="chat-with">{
                  activeChannel.participants[0].name === "Unknown User" ?
                    Utilities.getContactsName(activeChannel.participants[0].id) :
                    activeChannel.participants[0].name
                }</div>
                <div className="chat-status">{ChatService.onlineStatuses && ChatService.onlineStatuses[activeChannel.participants[0].id] ? "Online" : "Offline"}</div>
              </div>
            </div>
            <div className="messages-wrapper" style={{ height: `calc(100% - 128px - ${extraTextAreaHeight}px)` }} ref={messageWrapperRef}>
              <div className="messages">
                  { activeChannel.messages.map(m => {
                    const isRead = false; // m.author === ownTwilioIdentity && activeChannel.member && activeChannel.member.lastConsumedMessageIndex >= m.index;
                    let medias = m.body && m.body.indexOf("£££") !== -1 ? JSON.parse(m.body.split("£££")[0]) as MessageMedia[] : [];
                    let message = (m.body && m.body.indexOf("£££") !== -1) ? m.body.split("£££")[1] : (m.body && m.body.indexOf("$$$") !== -1) ? m.body.split("$$$")[1] : m.body;

                    if (m.attachedMedia[0]?.filename) {
                      return null;
                    }
                    
                    return (
                      <div key={m.index} className={"bubble " + ( m.author === ChatService.ownTwilioIdentity ? "me" : "you" )}>

                        { (medias && medias.length !== 0) &&
                          <div className='file-list clearfix'>
                            { medias.map(media => {
                              return (
                                <div
                                  className='body-file'
                                  data-sid={media.sid}
                                  data-contenttype={media.contentType}
                                  data-category={media.category}
                                  data-size={media.size}
                                  data-filename={media.filename}
                                  onClick={() => {
                                    loadFile(media)
                                  }}
                                >
                                  { (mediaUrls[media.sid] && mediaUrls[media.sid].url) &&
                                    <img src={mediaUrls[media.sid].url} />
                                  }
                                  <div>
                                    <IonIcon icon={attachOutline} />
                                    <span>{media.filename || "Unnamed File"}</span>
                                  </div>
                                </div>
                              )
                            }) }
                          </div>
                        }

                        { (message) &&
                          <span className="body">{message}</span>
                        }
                        <span> <IonIcon style={ isRead ? { color: "#50D890" } : {} } icon={checkmarkCircle} /> {Utilities.formatDate(m.dateUpdated, "HH:MM d mms (YYYY)")}</span>
                        {/* <span> {activeChannel.member.lastConsumedMessageIndex} >= { m.index} </span> */}
                      </div>
                    )
                  }) }
              </div>
            </div>
            { ((currentChannelNoLongerSharing || currentChannelBlockedByContact) && false) ? // TODO remove
              <div className='new-message-blocked'>
                { currentChannelBlockedByContact ? 
                  <div>
                    <p>This user has blocked you</p>
                  </div> :
                  <div onClick={() => {
                    setLoadingMessage("Resending Invite")
                    ContactAPI
                      .invite(null, null, null, null, null, null, null, null, activeChannel.contactId)
                      .then(data => {
                        onArchiveToggle("invite")
                      }).catch(error => {
                        setLoadingMessage("");
                        (window as any).toast("Failed to send invite", "error");
                      })
                  }}>
                    <p>No longer a contact</p>
                    <button>Reinvite</button>
                  </div>
                }
              </div>
              :
              <div className="new-message">
                { (newMessageMedia && newMessageMedia.length !== 0) &&
                  <div className='file-list clearfix'>
                    { newMessageMedia.map((media, mediaI) => {
                      return (
                        <div
                          className='body-file'
                          data-sid={media.sid}
                          data-contenttype={media.contentType}
                          data-category={media.category}
                          data-size={media.size}
                          data-filename={media.filename}
                          onClick={() => {
                            // loadFile(media)
                          }}
                        >
                          <IonIcon 
                            className='remove-file-btn'
                            icon={closeCircle}
                            onClick={() => {
                              newMessageMedia.splice(mediaI, 1);
                            }}
                          />
                          { (mediaUrls[media.sid] && mediaUrls[media.sid].url) &&
                            <img src={mediaUrls[media.sid].url} />
                          }
                          <div>
                            <IonIcon icon={attachOutline} />
                            <span>{media.filename || "Unnamed File"}</span>
                          </div>
                        </div>
                      )
                    }) }
                  </div>
                }
                <div className='attachments'>
                  <EmojiButton
                      onSelected={(emoji) =>
                        setNewMessageText((newMessageText || "") + emoji)
                      }
                  />
                  <IonIcon 
                    icon={attach}
                    onClick={() => {
                      let inputEl = document.getElementById("chat-file-input");
                      if (inputEl) {
                          inputEl.click();
                      }
                    }}
                  />
                </div>
                <input 
                  id="chat-file-input" 
                  type="file" 
                  style={{
                    display: 'none'
                  }}
                  onChange={(e) => {
                    handleSendFile(e.target.files ? e.target.files[0] : null, newMessageMedia);
                  }}
                ></input>
                <textarea
                    placeholder="Type Here"
                    value={newMessageText}
                    onChange={(ev) => { setNewMessageText(ev.target.value) }}
                    id="new-message-textarea"
                    onKeyDown={(e) => {
                      if (e.keyCode == 13)
                        sendMessage(newMessageText)
                      }
                    }
                    onFocus={() => {
                      (window as any).scrollToThreadBottom();
                    }}
                    onBlur={() => {
                      (window as any).scrollToThreadBottom();
                    }}
                    onKeyUp={(e) => {
                      if (newMessageText.length === 0) {
                        // @ts-ignore
                        e.target.style.height = '54px';
                        setExtraTextAreaHeight(0);
                      } else {
                        // @ts-ignore
                        e.target.style.height = e.target.scrollHeight + 'px';
                        // @ts-ignore
                        setExtraTextAreaHeight(e.target.scrollHeight - 58 < 0 ? 0 : e.target.scrollHeight - 58);
                      }
                    }}
                />
                <IonIcon onClick={() => { sendMessage(newMessageText) }} className="send-btn" icon={send} />
              </div>
            }
          </div>
        }
        { (!test) && (status === 0 || blockingLoadMsg !== "" || !ChatService.isLoaded) &&
          <IonLoading
            isOpen={status === 0 || blockingLoadMsg !== "" || !ChatService.isLoaded}
            onDidDismiss={() => setStatus(1)}
            message={blockingLoadMsg || (status === 0 ? "Loading messages..." : "Loading...")}
            duration={12000}
          />
        }
        { (!test) && loadingMessage !== "" &&
          <IonLoading
            isOpen={loadingMessage !== ""}
            onDidDismiss={() => setLoadingMessage("")}
            message={loadingMessage}
            duration={12000}
          />
        }
        { (!test) &&
          <ContactSelectorModal
            presentingElement={contentRef}
            isOpen={contactsSelectorModal}
            threads={ChatService.loadedConversations}
            onClose={(userId) => {
              if (userId) {
                startChat(userId);
                UserAPI.markChatUsed();
              }
              setContactSelectorModal(false);
            }}
          />
        }
        { (ChatService.isLoaded) &&
          <OnboardingGuide section="chats" hide={activeChannelId !== null} haveMessages={ChatService.loadedConversations && ChatService.loadedConversations.length !== 0} />  
        }
        <IonActionSheet
          isOpen={threadOptionsOpen}
          onDidDismiss={() => setThreadOptionsOpen(false)}
          header={"Options"}
          buttons={[
            (!currentChannelNoLongerSharing && !currentChannelBlockedByContact) ? {
              text: currentChannelManuallyArchived ? "Unarchive" : "Archive",
              handler: () => {
                if (currentChannelManuallyArchived) {
                  setLoadingMessage("Moving to Inbox...");
                  InternalTracker.trackEvent("Chat Thread Unarchived", {
                    threadId: activeChannel.conversation.sid,
                  })
                  ChatAPI
                    .unarchiveThread(activeChannel.contactId)
                    .then(data => {  onArchiveToggle("inbox"); })
                    .catch(data => { setLoadingMessage(""); (window as any).toast("Failed to move thread to inbox", "error"); })
                } else {
                  setLoadingMessage("Archiving...");
                  InternalTracker.trackEvent("Chat Thread Archived", {
                    threadId: activeChannel.conversation.sid,
                  })
                  ChatAPI
                    .archiveThread(activeChannel.contactId)
                    .then(data => {  onArchiveToggle("archived"); })
                    .catch(data => { setLoadingMessage(""); (window as any).toast("Failed to archive thread", "error"); })
                }
              }
            } : null,
            {
              text: "Report Chat Thread",
              handler: () => {
                InternalTracker.trackEvent("Chat Thread Reported");
                setReportConfirmContactId(activeChannel.contactId)
              }
            },
            {
              text: 'Close',
              handler: () => {}
            }
          ].filter(item => item)}
        />


      </IonContent>
      { (reportConfirmContactId !== null) &&
        <ReportModal
          type={ReportTypeId.Chat}
          id={reportConfirmContactId}
          isUserId={true}
          open={true}
          onClose={() => {
            setReportConfirmContactId(null)
          }}
        />
      }
    </IonPage>
  );
};

export default Messages;
