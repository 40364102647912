class InternalTracker {
    static trackEvent(event: string, params?: object) {
        if (
            window.location.href.indexOf("testing=true") === -1 &&
            !localStorage.getItem("admin_access_token") &&
            !localStorage.getItem("disableTracking")
        ) {
            if ((window as any).openreplayTracker || (window as any).posthogTracker) {
                if ((window as any).openreplayTracker) {
                    (window as any).openreplayTracker.event(event, params);
                } else {
                    (window as any).posthogTracker.capture(event, params);
                }
            } else {
                // console.log("OpenReplay not loaded yet")
                setTimeout(() => {
                    this.trackEvent(event, params);
                }, 200)
            }
        }
    };
}

export default InternalTracker;
