import { IonTextarea, IonAlert, IonBackButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonLoading, IonPage, IonSelect, IonSelectOption, IonTitle, IonToast, IonToggle, IonToolbar } from '@ionic/react';
import { add, alertCircle, arrowUp, caretDownCircle, checkmark, checkmarkCircleOutline, chevronForward, close, closeCircle, infinite, keypad, search, trash, trashBin, trashOutline, warning, warningOutline } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import SectorsAPI from '../apis/Sectors';
import UserAPI from '../apis/User';
import Configuration from '../Configuration';
// import '../styles/Components/Settings/ProfileSettings.scss';
// import '../styles/TabHeader.scss';
import { Attribute, AttributeBreakdown, Sector, User, UserDTO } from '../types/User';
import { UserEmail } from '../types/UserEmail';
import Utilities from '../Utilities';
import InternalTracker from '../InternalTracker';
// import '../styles/Components/TagManager.scss';
import { Plugins } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import CircularProgress from '@material-ui/core/CircularProgress';
import CountTo from 'react-count-to';
import HierarchyExampleImg from '../images/hierarchy-example.png';
import LongArrow from '../icons/LongArrow';

const CONFIG = Configuration[localStorage.getItem("env") || "prod"];

interface Props {
  onboarding? : boolean,
  BOTTOM_OPTIONS_STYLE? : React.CSSProperties,
  onAttributesChange: (attributes: Attribute[]) => void,
  onSearchQueryChange?: (query: string) => void,
  onSectorSelectorModelVisibilityChange?: (visible: boolean) => void,
  keyboardOpen?: boolean,
  showNotchFiller?: boolean,
  onModalVisibilityChange?: (visible: boolean) => void
}

interface AttributesGrouppedBySectorSubsectorItem {
    title: string,
    hash: string,
    skills: Attribute[]
}

const SkillManagerEnhanced: React.FC<Props> = ({ onModalVisibilityChange, onboarding, BOTTOM_OPTIONS_STYLE, onAttributesChange, keyboardOpen, showNotchFiller, onSearchQueryChange, onSectorSelectorModelVisibilityChange }) => {
  
    const [loading, setLoading] = useState <boolean> (false) // Full screen loading
    const [attributes, setAttributes] = useState <Attribute[]> ([]) // User Attributes
    const [searchQuery, setSearchQuery] = useState <string> ("") // Search Query
    const [firstSkillSelector, setFirstSkillSelector] = useState <boolean> (false) // First Skill Selector
    const [suggestions, setSuggestions] = useState <AttributeBreakdown[]> ([]) // Suggestions
    const [searchDebounceTimeout, setSearchDebounceTimeout] = useState <any> (null) // Search Debounce Timeout
    const [sectorSearchDebounceTimeout, setSectorSearchDebounceTimeout] = useState <any> (null) // Sector Search Debounce Timeout
    const [subsectorSearchDebounceTimeout, setSubsectorSearchDebounceTimeout] = useState <any> (null) // Subsector Search Debounce Timeout
    const [prioritizeSectorIdForsubSectorSearch, setPrioritizeSectorIdForsubSectorSearch] = useState <null | number> (null) // Prioritize Sector Id For subSector Search
    const [sectorSubSectorForSkillLoading, setSectorSubSectorForSkillLoading] = useState <boolean> (false) // Sector SubSector For Skill Loading
    const [selectedAttribute, setSelectedAttribute] = useState <AttributeBreakdown> (null) // Selected Attribute
    const [manualSectorEntry, setManualSectorEntry] = useState <boolean> (false) // Manual Sector Entry
    const [manualSectorName, setManualSectorName] = useState <string> ("") // Manual Sector Name
    const [manualSubsectorName, setManualSubsectorName] = useState <string> ("") // Manual Subsector Name
    const [quickSkillAddToSectorSubSectorHash, setQuickSkillAddToSectorSubSectorHash] = useState<string> ("") // Quick Skill Add To Sector SubSector Hash
    const [sectorSuggestions, setSectorSuggestions] = useState <Sector[]> ([]) // Sector Suggestions
    const [subSectorSuggestions, setSubSectorSuggestions] = useState <Sector[]> ([]) // Sub Sector Suggestions
    const [topSkills, setTopSkills] = useState <AttributeBreakdown[]> ([]) // Top Skills
    const [timeoutOtherSectorOptions, setTimeoutOtherSectorOptions] = useState <boolean> (false) // Set Timeout Other Sector Options
    const [minSkillsQueryWarning, setMinSkillsQueryWarning] = useState <boolean> (false) // Set Min Skills Query Warning
    // const [topSkillRows, setTopSkillRows] = useState <AttributeBreakdown[][]> ([]) // 3 rows of skills
    // const [topSkillScrollerWidth, setTopSkillScrollerWidth] = useState <number> (0) // Top Skill Scroller Width
    const contentRef = useRef(null);

    useEffect(() => {
        const user = localStorage.getItem("user");
        if (user) {
            const userObj: User = JSON.parse(user);
            setAttributes(userObj.workerAttributes || []);
            setFirstSkillSelector(!userObj.workerAttributes || userObj.workerAttributes.length === 0);
        }
        let topSkills = localStorage.getItem("topSkills");
        if (topSkills) {
            setTopSkills(JSON.parse(topSkills).slice(0, 20));
        }
    }, [])

    useEffect(() => {
        if (onModalVisibilityChange) {
            onModalVisibilityChange(selectedAttribute !== null);
        }
        if (selectedAttribute && selectedAttribute.entities.length !== 0) {
            setTimeoutOtherSectorOptions(true);
        }
    }, [selectedAttribute])

    useEffect(() => {
        if (timeoutOtherSectorOptions) {
            setTimeout(() => {
                setTimeoutOtherSectorOptions(false);
            }, 2500)
        }
    }, [timeoutOtherSectorOptions]);

    useEffect(() => {
        if (!manualSectorEntry) {
            setManualSubsectorName("");
            setPrioritizeSectorIdForsubSectorSearch(null);
            setManualSectorName("");
        }
    }, [manualSectorEntry])

    useEffect(() => {
        if (onSectorSelectorModelVisibilityChange) {
            onSectorSelectorModelVisibilityChange(selectedAttribute !== null);
        }
    }, [selectedAttribute])

    useEffect(() => {
        setSuggestions([]);
        setSubSectorSuggestions([]);
        setSectorSuggestions([]);
        if (onSearchQueryChange)
            onSearchQueryChange(searchQuery);
    }, [searchQuery])

    useEffect(() => {
        // resaing to local storage
        Utilities.upsertLocalUser({
            workerAttributes: attributes
        } as UserDTO)
        setFirstSkillSelector(!attributes || attributes.length === 0);
        onAttributesChange(attributes);
    }, [attributes])

    const searchSectors = async (query: string) => {
        if ((window as any).preventSectorSearch) {
            return;
        }
        const results = await SectorsAPI.getSector(query).catch(e => {
            console.error(e);
        })
        // @ts-ignore
        if (results && results.data) { setSectorSuggestions(results.data); }
    }

    const searchSubsectors = async (query: string, prioritizeSectorId: number) => {
        if ((window as any).preventSubSectorSearch) {
            return;
        }
        const results = await SectorsAPI.getSubsector(query, 0, prioritizeSectorId).catch(e => {
            console.error(e);
        })
        console.log(results);
        // @ts-ignore
        setSubSectorSuggestions(results && results.data ? results.data : []);
    }

    const searchSkills = async (query: string, sectorId?: number, subSectorId?: number) => {
        setMinSkillsQueryWarning(query.length < 2);
        if (query && query.length > 1) {
            setLoading(true);
            const response = await SectorsAPI.searchSkills(query, 0, 0, sectorId, subSectorId).catch(e => {
                console.error(e);
            })
            if (response) {
                setSuggestions(response);
            }
        } else {
            setSuggestions([]);
        }
        setLoading(false);
        // @ts-ignore
        clearTimeout(searchDebounceTimeout);
        setSearchDebounceTimeout(null);
    }

    const searchInput = <div 
        className='search-input-wrapper' 
        data-inline={quickSkillAddToSectorSubSectorHash ? "true" : "false"}
    >
        { quickSkillAddToSectorSubSectorHash ?
            <IonIcon className='close-icon' icon={closeCircle} onClick={() => {
                setQuickSkillAddToSectorSubSectorHash("");
                setSearchQuery("");
                setSectorSuggestions([]);
                setSubSectorSuggestions([]);
                setSuggestions([]);
            }} />
            :
            <IonIcon className='search-icon' icon={search} onClick={() => {
                setFirstSkillSelector(true);
            }} />
        }
        <IonInput
            id="skill-search-input-wrapper"
            placeholder="Add your Skills"
            value={searchQuery} 
            onIonInput={(e) => {
                const newValue: string = Utilities.capitalizeFirstLetter((e.target as HTMLIonInputElement).value.toString() || "");
                console.log("Change here " + newValue);
                setSearchQuery(newValue);
                // @ts-ignore
                clearTimeout(searchDebounceTimeout);
                // @ts-ignore
                setSearchDebounceTimeout(setTimeout(() => {
                    const sectorId = quickSkillAddToSectorSubSectorHash ? parseInt(quickSkillAddToSectorSubSectorHash.split("-")[0]) : null;
                    const subSectorId = quickSkillAddToSectorSubSectorHash.split("-")[1] ? parseInt(quickSkillAddToSectorSubSectorHash.split("-")[1]) : null;
                    searchSkills(newValue.toLowerCase(), sectorId, subSectorId);
                }, 500))
            }}
        /> 
    </div>

  let attributesGrouppedBySectorSubSector: AttributesGrouppedBySectorSubsectorItem[] = [];

  if (attributes) {
    for (let i = 0; i < attributes.length; i++) {
        const attribute = attributes[i];
        const sectorHash = attribute.sectorId + "-" + attribute.subSectorId;
        if (!attributesGrouppedBySectorSubSector.find(g => g.hash === sectorHash)) {
            attributesGrouppedBySectorSubSector.push({
                title: sectorHash === "0-0" ? "General Skills" : sectorHash.endsWith("-0") ? attribute.sectorName : (attribute.sectorName + " > " + attribute.subSectorName),
                skills: [],
                hash: sectorHash
            })
        }
        const index = attributesGrouppedBySectorSubSector.findIndex(g => g.hash === sectorHash);
        attributesGrouppedBySectorSubSector[index].skills.push(attribute);
    }
    attributesGrouppedBySectorSubSector.push({
        title: "Add Skills for other Sectors",
        skills: [],
        hash: "-"
    })
  }

  console.log(attributes, attributesGrouppedBySectorSubSector)

  return (
    <div 
        className='skill-manager-enhanced' 
        data-onboarding={onboarding} 
        data-first-skill-selector={firstSkillSelector ? "true" : "false"}
        data-keyboard-open={keyboardOpen ? "true" : "false"}
    >
        { (firstSkillSelector) ?
            <div className='center center-initial'>
                { (onboarding) &&
                    <div className='guide-wrapper'>
                        <div className='arrow-wrapper'>
                            <LongArrow />
                            <span>Example Hierarchy</span>
                        </div>
                        <img src={HierarchyExampleImg} />
                    </div>
                }
                {/* { (!searchQuery && !keyboardOpen && topSkills && topSkills.length > 0) &&
                    <div className='top-skills-wrapper'>
                        { topSkills.map((skill, index) => {
                            return (
                                <div 
                                    className='skill-item'
                                    onClick={() => {
                                        const newSkill = JSON.parse(JSON.stringify(skill));
                                        newSkill.entities = (newSkill.entities || []).filter(entity => entity.sectorId !== 0 || entity.subSectorId !== 0);
                                        setSelectedAttribute(newSkill);
                                    }}
                                >
                                    <span>{skill.skillName}</span>
                                </div>
                            )
                        })}
                    </div>
                } */}
                { (firstSkillSelector && (loading || searchQuery)) &&
                    <div className='skill-list-wrapper'>
                        { (loading) ?
                            <span>
                                <CircularProgress />
                            </span>
                            :
                            <React.Fragment>
                                { searchDebounceTimeout === null && searchQuery.length > 1 && !suggestions.find(suggestion => suggestion.skillName.toLowerCase() === searchQuery) &&
                                    <div className='skill-list new'>
                                        <div className='skill-item new' onClick={() => {
                                            setSelectedAttribute({
                                                skillName: searchQuery,
                                                entities: []
                                            });
                                        }}>
                                            <div>
                                                <span>Not Found?</span>
                                                <span>Tap to Add</span>
                                            </div>
                                            <div>
                                                <span>"{searchQuery}"</span>
                                            </div>
                                        </div>
                                    </div>
                                }
                                { (suggestions.length) ?
                                    <div className='suggestions-help-text'>
                                        Suggestions, Tap any to Add.
                                    </div>
                                    : null
                                }
                                { (minSkillsQueryWarning) &&
                                    <div className='min-query-chars-warning'>
                                        <IonIcon icon={warning} />
                                        <span>
                                            Please enter at least 2 letters
                                        </span>
                                    </div>
                                }
                                <div className='skill-list'>
                                    { suggestions.sort((a, b) => {
                                        if (a.skillName.toLowerCase() === searchQuery.toLowerCase()) {
                                            return -1;
                                        }
                                        if (b.skillName.toLowerCase() === searchQuery.toLowerCase()) {
                                            return 1;
                                        }
                                        return 0;
                                    }).map((suggestion, index) => {
                                        return <div 
                                            key={index} 
                                            className='skill-item'
                                            onClick={() => {
                                                setSearchQuery("");
                                                const newSkill = JSON.parse(JSON.stringify(suggestion));
                                                newSkill.entities = (newSkill.entities || []).filter(entity => entity.sectorId !== 0 || entity.subSectorId !== 0);
                                                setSelectedAttribute(newSkill);
                                            }}
                                        >
                                            <span>{suggestion.skillName}</span>
                                        </div>
                                    })}
                                </div>
                            </React.Fragment>
                        }
                    </div>
                }
            </div>
            :
            <div className='center center-list'>
                { (sectorSubSectorForSkillLoading) &&
                    <div className='loading-backdrop'>
                        <CircularProgress />
                    </div>
                }
                { attributesGrouppedBySectorSubSector.map(group => {
                    return (
                        <div className='sector-subsector-list-wrapper'>
                            <h2>
                                {group.title}
                                {group.title === "General Skills" &&
                                    <div className='warning-banner'>
                                        <IonIcon icon={alertCircle} />
                                        <p>
                                            <span>Not categorizing skills into sectors/subsectors will make it harder for prospective contacts to find you</span>
                                        </p>
                                    </div>
                                }
                                <label>
                                    Add each skill one at a time
                                </label>
                            </h2>
                            <div className='skills clearfix'>
                                { group.skills.map((skill, index) => {
                                    return (
                                        <div className='skill'>
                                            <span>{skill.skillName}</span>
                                            <IonIcon icon={close} onClick={() => {
                                                setAttributes(attributes.filter(attribute => attribute.skillId !== skill.skillId));
                                            }} />
                                        </div>
                                    )
                                }) }
                                { (quickSkillAddToSectorSubSectorHash === group.hash) ?
                                    searchInput
                                    :
                                    <div className='skill skill-new' onClick={() => {
                                        setQuickSkillAddToSectorSubSectorHash(group.hash);
                                        setTimeout(() => {
                                            const input = document.querySelector(`#skill-search-input-wrapper`) as HTMLIonSearchbarElement
                                            if (input) {
                                                input.setFocus();
                                            }
                                        }, 200)
                                    }}>
                                        <IonIcon icon={add} />
                                        <span>
                                            Add Skill
                                        </span>
                                    </div>
                                }
                                { (quickSkillAddToSectorSubSectorHash === group.hash && searchQuery) ?
                                    <label className='guide'>Select one of the suggestions:</label>
                                : null }
                                { (quickSkillAddToSectorSubSectorHash === group.hash) &&
                                    <div className='skill-list'>
                                        { (searchDebounceTimeout !== null || loading) &&
                                            <div className='inline-search-indicator'>
                                                <CircularProgress />
                                            </div>
                                        }
                                        {suggestions.sort((a, b) => {
                                            if (a.skillName.toLowerCase() === searchQuery.toLowerCase()) {
                                                return -1;
                                            }
                                            if (b.skillName.toLowerCase() === searchQuery.toLowerCase()) {
                                                return 1;
                                            }
                                            return 0;
                                        }).filter(suggestion => {
                                            // don't show suggetions that are already added into this section
                                            return !group.skills.find(skill => skill.skillName.toLowerCase() === suggestion.skillName.toLowerCase());
                                        }).map((suggestion, index) => {
                                            return <div 
                                                key={index} 
                                                className='skill-item'
                                                onClick={ async () => {
                                                    console.log(suggestion)
                                                    if (quickSkillAddToSectorSubSectorHash === "-") {
                                                        setSelectedAttribute({
                                                            skillName: suggestion.skillName,
                                                            entities: suggestion.entities || []
                                                        });
                                                    } else {
                                                        const sectorId = quickSkillAddToSectorSubSectorHash ? parseInt(quickSkillAddToSectorSubSectorHash.split("-")[0]) : null;
                                                        const subSectorId = quickSkillAddToSectorSubSectorHash.split("-")[1] ? parseInt(quickSkillAddToSectorSubSectorHash.split("-")[1]) : null;
                                                        const attribute = attributes.find(attribute => attribute.sectorId === sectorId && attribute.subSectorId === subSectorId)
                                                        if (attribute) {
                                                            const sectorName = attribute.sectorName || "";
                                                            const subSectorName = attribute.subSectorName || "";
                                                            const skillName = suggestion.skillName;
                                                            setSectorSubSectorForSkillLoading(true);
                                                            const newAttribute = await SectorsAPI.createOrGetSkillAttributes(skillName, sectorName, subSectorName)
                                                            if (newAttribute) {
                                                                setAttributes([
                                                                    ...attributes,
                                                                    newAttribute
                                                                ]);
                                                            }
                                                            setSectorSubSectorForSkillLoading(false);
                                                            setQuickSkillAddToSectorSubSectorHash("");
                                                            setSuggestions([]);
                                                            setSearchQuery("");
                                                        }
                                                    }
                                                }}
                                            >
                                                <span>{suggestion.skillName}</span>
                                            </div>
                                        })}
                                        { (!loading && !searchDebounceTimeout && (suggestions.length === 0 || !suggestions.find(a => a.skillName.toLowerCase() === searchQuery.toLowerCase())) && searchQuery) &&
                                            <div className='skill-item' onClick={ async () => {
                                                if (quickSkillAddToSectorSubSectorHash === "-") {
                                                    setSelectedAttribute({
                                                        skillName: searchQuery,
                                                        entities: []
                                                    });
                                                } else {
                                                    const sectorId = quickSkillAddToSectorSubSectorHash ? parseInt(quickSkillAddToSectorSubSectorHash.split("-")[0]) : null;
                                                    const subSectorId = quickSkillAddToSectorSubSectorHash.split("-")[1] ? parseInt(quickSkillAddToSectorSubSectorHash.split("-")[1]) : 0;
                                                    const attribute = attributes.find(attribute => attribute.sectorId === sectorId && attribute.subSectorId === subSectorId)
                                                    if (attribute) {
                                                        setSectorSubSectorForSkillLoading(true);
                                                        const subSectorName = attribute.subSectorName || "";
                                                        const sectorName = attribute.sectorName || "";
                                                        const newAttribute = await SectorsAPI.createOrGetSkillAttributes(searchQuery, sectorName, subSectorName).catch(e => {
                                                            (window as any).toast("Failed to add skill", "error");
                                                        })
                                                        if (newAttribute) {
                                                            setAttributes([
                                                                ...attributes,
                                                                newAttribute
                                                            ]);
                                                        }
                                                        setSectorSubSectorForSkillLoading(false);
                                                    }
                                                }
                                                setQuickSkillAddToSectorSubSectorHash("");
                                                setSearchQuery("");
                                            }}>
                                                <span>{searchQuery}</span>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    )
                }) }
            </div>
        }

        <div className='bottom-options' style={BOTTOM_OPTIONS_STYLE}>
            {firstSkillSelector ? searchInput : null}
            {/* { (showNotchFiller) &&
                <div className='notch-filler'></div>
            } */}
        </div>
        { (selectedAttribute) &&
            <div className='select-parent-wrapper'>

            </div>
        }
        { (selectedAttribute) &&
            <div 
                data-keyboard-open={keyboardOpen ? "true" : "false"}
                className='sector-selector-wrapper' 
                onClick={() => {
                    // setSelectedAttribute(null);
                }}
            >
                <div className='content'>
                    { (sectorSubSectorForSkillLoading) &&
                        <div className='loading-backdrop'>
                            <CircularProgress />
                        </div>
                    }
                    <h2>
                        {"Select Sector > Subsector for "} 
                        <span>{selectedAttribute.skillName}</span>
                    </h2>
                    { (manualSectorEntry) ?
                        <div className='manual-sector-selector'>
                            <div className='sector-inputs'>
                                <IonInput
                                    placeholder="Sector"
                                    id="sector-search-input-wrapper"
                                    onIonInput={(e) => {
                                        setSectorSuggestions([]);
                                        setSectorSuggestions([]);
                                        setManualSectorName(Utilities.capitalizeFirstLetter(e.detail.value));
                                        // @ts-ignore
                                        clearTimeout(sectorSearchDebounceTimeout);
                                        // @ts-ignore
                                        setSectorSearchDebounceTimeout(setTimeout(() => {
                                            searchSectors(e.detail.value.toLowerCase());
                                        }, 500))
                                    }}
                                    value={manualSectorName}
                                />
                                { (sectorSuggestions && sectorSuggestions.length > 0) &&
                                    <div className='sector-suggestions'>
                                        { sectorSuggestions.map((sector, index) => {
                                            return <div 
                                                key={index} 
                                                className='sector-suggestion'
                                                onClick={() => {
                                                    (window as any).preventSectorSearch = true;
                                                    setTimeout(() => {
                                                        (window as any).preventSectorSearch = false;
                                                    }, 700)
                                                    setManualSectorName(sector.value);
                                                    setSectorSuggestions([]);
                                                    setPrioritizeSectorIdForsubSectorSearch(sector.id);
                                                }}
                                            >
                                                <span className='sector'>
                                                    {sector.value}
                                                </span>
                                            </div>
                                        })}
                                    </div>
                                } 
                                <IonInput 
                                    placeholder="Subsector"
                                    id="subsector-search-input-wrapper"
                                    onIonInput={(e) => {
                                        setSectorSuggestions([]);
                                        setSectorSuggestions([]);
                                        setManualSubsectorName(Utilities.capitalizeFirstLetter(e.detail.value));
                                        // @ts-ignore
                                        clearTimeout(subsectorSearchDebounceTimeout);
                                        // @ts-ignore
                                        setSubsectorSearchDebounceTimeout(setTimeout(() => {
                                            searchSubsectors(e.detail.value.toLowerCase(), prioritizeSectorIdForsubSectorSearch);
                                        }, 500))
                                    }}
                                    value={manualSubsectorName}
                                />
                                { (subSectorSuggestions && subSectorSuggestions.length > 0) &&
                                    <div className='sector-suggestions'>
                                        { subSectorSuggestions.map((subsector, index) => {
                                            return <div 
                                                key={index} 
                                                className='sector-suggestion'
                                                onClick={() => {
                                                    (window as any).preventSubSectorSearch = true;
                                                    setTimeout(() => {
                                                        (window as any).preventSubSectorSearch = false;
                                                    }, 700)
                                                    setManualSubsectorName(subsector.value);
                                                    setSubSectorSuggestions([]);
                                                }}
                                            >
                                                <span className='subsector'>
                                                    {subsector.value}
                                                </span>
                                            </div>
                                        })}
                                    </div>
                                }
                            </div>
                            <div className='sector-selector-actions'>
                                <div className='sector-selector-action' onClick={() => {
                                    setManualSectorEntry(false);
                                }}>
                                    <IonIcon icon={close} />
                                    <span>Cancel Adding Skill</span>
                                </div>
                                <div 
                                    className='sector-selector-action main'
                                    onClick={ async () => {
                                        if (!manualSectorName) {
                                            (window as any).toast("Please enter a sector name", "error");
                                            return;
                                        }

                                        setSectorSubSectorForSkillLoading(true);
                                        const attribute = await SectorsAPI.createOrGetSkillAttributes(selectedAttribute.skillName, manualSectorName, manualSubsectorName).catch(e => {
                                            (window as any).toast("Failed to add skill", "error");
                                        })
                                        if (attribute) {
                                            setAttributes([
                                                ...attributes,
                                                attribute
                                            ]);
                                        }
                                        setManualSectorEntry(false);
                                        setFirstSkillSelector(false);
                                        setSelectedAttribute(null);
                                        setSectorSubSectorForSkillLoading(false);
                                        setSearchQuery("");
                                        setSuggestions([]);
                                    }}
                                >
                                    <IonIcon icon={checkmark} />
                                    <span>Confirm</span>
                                </div>
                            </div>
                        </div>
                        :
                        <React.Fragment>
                            { (selectedAttribute.entities.length !== 0) &&
                                <div className='sector-or'>
                                    <span> {selectedAttribute.entities.length > 1 ? "Is one of these correct?" : "Is this correct?"} </span>
                                </div>
                            }
                            { (selectedAttribute.entities.length !== 0) &&
                                <div className='suggestions suggestions-list'>
                                    { (selectedAttribute.entities.map((entity, index) => {
                                        return <div 
                                            key={index} 
                                            className='suggestion'
                                            onClick={() => {
                                                setAttributes([...attributes, {
                                                    sectorId: entity.sectorId,
                                                    sectorName: entity.sectorName,
                                                    subSectorId: entity.subSectorId,
                                                    subSectorName: entity.subSectorName,
                                                    skillId: entity.skillId,
                                                    skillName: selectedAttribute.skillName
                                                }]);
                                                setFirstSkillSelector(false);
                                                setSelectedAttribute(null);
                                                setSearchQuery("");
                                                setSuggestions([]);
                                            }}
                                        >
                                            <div className='level-indicator-line'></div>
                                            <i className="level-indicator fas fa-long-arrow-alt-right"></i>
                                            <i className={'sector-icon ' + (entity.sectorIcon || 'fa-solid fa-briefcase')}></i>
                                            { (entity.sectorId) ?
                                                <span className='sector'>
                                                    {entity.sectorName}
                                                </span>
                                            : null }
                                            { (entity.sectorId && entity.subSectorId) ?
                                                <IonIcon style={{ margin: '0 4px' }} icon={chevronForward} />
                                            : null }
                                            { (entity.subSectorId) ?
                                                <span className='subsector'>
                                                    {entity.subSectorName}
                                                </span>
                                            : null }
                                            {/* { (!entity.subSectorId) ?
                                                <span className='subsector without'>
                                                    No Subsector
                                                </span>
                                            : null } */}
                                        </div>
                                    }))}
                                </div>
                            }
                            { (selectedAttribute.entities.length !== 0) &&
                                <div className='suggestion-or'>
                                    <span>or</span>
                                </div>
                            }
                            { (timeoutOtherSectorOptions) &&
                                <CountTo className="count-down" from={2.5} to={0} speed={2500} />
                            }
                            <div className='suggestions' data-obscured={timeoutOtherSectorOptions ? "true" : "false"}>
                                <div className='suggestion' onClick={() => {
                                    if (timeoutOtherSectorOptions) {
                                        return;
                                    }
                                    setManualSectorEntry(true);
                                }}>
                                    <IonIcon icon={keypad} />
                                    <span className='sector without-sector'>Enter Sector and Subsector</span>
                                </div>
                                <div className='suggestion' onClick={ async () => {
                                    if (timeoutOtherSectorOptions) {
                                        return;
                                    }
                                    setSectorSubSectorForSkillLoading(true);
                                    const attribute = await SectorsAPI.createOrGetSkillAttributes(selectedAttribute.skillName, "", "").catch(e => {
                                        (window as any).toast("Failed to add skill", "error");
                                    })
                                    if (attribute) {
                                        setAttributes([
                                            ...attributes,
                                            attribute
                                        ]);
                                    }
                                    setManualSectorEntry(false);
                                    setFirstSkillSelector(false);
                                    setSelectedAttribute(null);
                                    setSectorSubSectorForSkillLoading(false);
                                    setSearchQuery("");
                                    setSuggestions([]);
                                }}>
                                    <IonIcon icon={infinite} />
                                    <span className='sector without-sector'>Set as General Skill</span>
                                    {/* <span className='subsector without'>No Sector</span> */}
                                </div>
                                <div className='suggestion' onClick={() => {
                                    if (timeoutOtherSectorOptions) {
                                        return;
                                    }
                                    setSelectedAttribute(null);
                                }}>
                                    <IonIcon icon={trashOutline} />
                                    <span className='sector without-sector'>Cancel</span>
                                </div>
                            </div>
                        </React.Fragment>
                    }
                </div>
            </div>
        }
    </div>
  );
};

export default SkillManagerEnhanced;
